import React, { useEffect, useState } from 'react';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import UserRules from './instituteRules';

interface UserDialog {
    editObj: any,
    mode: string,
    closeModal: Function,
    editInstitute: Function,
}

interface IInstitute {
    name: string,
    address: string,
    phone: string,
    institute_role: string,
    email: string,
    status: string,
}

const initialValues: IInstitute = {
    name: '',
    address: '',
    phone: '',
    institute_role: '',
    email: '',
    status: '',
};

const EditInstitute = (props: UserDialog) => {
    const [institute, setInstitute] = useState(initialValues);
    const [errors, setErrors] = useState(initialValues);


    useEffect(() => {
        if (props.mode === 'edit') {
            props.editObj.name = props.editObj.firstname + " " + props.editObj.lastname
            setInstitute(props.editObj)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inputHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: ''
        })
        setInstitute({
            ...institute,
            [e.target.name]: e.target.value
        });
    }

    const confirmAction = async (e: any) => {
        e.preventDefault();
        let errorMsgs: any = ValidateForm(institute, UserRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await props.editInstitute(institute);
            props.closeModal();
        }
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, UserRules);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg
        });
    }

    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area">
                <form onSubmit={confirmAction}>
                    <div className="form-area my-lg">
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Institute Name</label>
                            <input
                                type="text"
                                name={'name'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Institute Name'}
                                value={institute.name}
                            />

                            {errors.name !== '' ? (
                                <span className="error-text">{errors.name}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Institute Email</label>

                            <input
                                type="email"
                                name={'email'}
                                value={institute.email}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Institute Email'}
                            />

                            {errors.email !== '' ? (
                                <span className="error-text">{errors.email}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Phone Number</label>
                            <input
                                type="number"
                                name={'phone'}
                                onBlur={inputValidation}
                                value={institute.phone}
                                onChange={inputHandler}
                                placeholder={'Enter Institute Phone'}
                            />
                            {errors.phone !== '' ? (
                                <span className="error-text">{errors.phone}</span>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Institute Address</label>

                            <input
                                type="text"
                                name={'address'}
                                value={institute.address}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Institute Address'}
                            />

                            {errors.address !== '' ? (
                                <span className="error-text">{errors.address}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-lg">
                            <label className={'bold text-primary'}>Role</label>
                            <select name="institute_role"
                                onBlur={inputValidation}
                                value={institute.institute_role}
                                onChange={inputHandler}>
                                <option value={'0'}>Cannot Add Exam</option>
                                <option value={'1'}>Can Add Exam</option>
                            </select>

                            {errors.institute_role !== '' ? (
                                <span className="error-text">{errors.institute_role}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-lg">
                            <label className={'bold text-primary'}>Status</label>

                            <select
                                name={"status"}
                                value={institute.status}
                                onBlur={inputValidation}
                                onChange={inputHandler}>
                                <option value='1'>Active</option>
                                <option value='0'>Disabled</option>
                            </select>

                            {errors.status !== '' ? (
                                <span className="error-text">{errors.status}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                    </div>
                    <button className="btn primary full-width" type={'submit'}>Edit Institute</button>
                </form>
            </div>
        </div>
    )
}

export default EditInstitute
