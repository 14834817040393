import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react'

const EmptyBox = ({ message }: any) => {
    return (
        <Box sx={{
            width: '100%',
            height: 180,
            background: '#b2beb5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'

        }}>
            <Typography>{message}</Typography>
        </Box>
    )
}

export default EmptyBox

