import { useHistory } from "react-router-dom";
import { Exam } from '../../../common/model/Exam'
import ExamList from '../../../common/views/examlist/views/ExamList';

const Exams = () => {

    const history = useHistory();


    const viewExam = (obj: Exam) => {
        history.push(`exams/exam/${obj._id}`)
    }

    return (
        <ExamList viewExam={viewExam} />

    )
}

export default Exams;
