import {
  DeleteRequest,
  GetRequest,
  PostRequest,
  PutRequest,
} from '../../../../../plugins/axios';
import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GET_CATEGORY_LIST } from './CategoryTypes';

const categoryUrl = process.env.REACT_APP_API_BASE_URL + 'categories';

export const getCategoriesSuccess = (payload: any) => {
  return {
    type: GET_CATEGORY_LIST,
    payload: payload,
  };
};

export const getAllCategories = () => (dispatch: any) => {
  const user = store.getState().auth.user;
  if (user) {
    GetRequest(`${categoryUrl}/${user._id}`, {}, {})
      .then((res) => {
        dispatch(getCategoriesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Categories',
          })
        );
      });
  }
};

export const addCategory =
  (payload: any = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    if (userID) {
      PostRequest(`${categoryUrl}/create/${userID}`, payload, {})
        .then((res) => {
          dispatch(getAllCategories());
          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Adding Success',
              message: 'Sucessfully Added Category',
            })
          );
        })
        .catch((err) => {
          console.log(err);

          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Adding Error',
              message: err.response?.data?.error || 'Error Adding User',
            })
          );
        });
    }
  };

export const editCategory =
  (payload: any = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    PutRequest(`${categoryUrl}/${payload._id}/${userID}`, payload, {})
      .then((res) => {
        dispatch(getAllCategories());
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Successfully Updated',
            message: 'Sucessfully Updated User',
          })
        );
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Updating Error',
            message: err.response?.data?.error || 'Error Updating User Data',
          })
        );
      });
  };

export const deleteCategory = (_id: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  DeleteRequest(`${categoryUrl}/${_id}/${userID}`, {})
    .then((res) => {
      dispatch(getAllCategories());
      dispatch(
        setToasterState({
          appear: true,
          title: 'success',
          name: 'Successfully Deleted',
          message: 'Sucessfully Deleted User',
        })
      );
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Deletion Error',
          message: err.response?.data?.error || 'Error Deleting User',
        })
      );
    });
};
