// import React, { useState, useEffect } from 'react';
import { useEffect, useState } from 'react';
interface ITimer {
    finishExam: Function
}

const Timer = (props: ITimer) => {
    const [isActive, setIsActive] = useState(false);

    const [time, setTime] = useState({
        minute: 50,
        second: 0
    });

    useEffect(() => {
        let interval: any = null;
        
        if (isActive) {
            interval = setInterval(() => {
                if (time && time.minute >0 && time.second === 0) {
                
                    setTime({
                        ...time,
                        minute: time.minute - 1,
                        second: 59
                    });
                } else if (time && time.minute >= 0 && time.second !== 0) {
                    setTime({
                        ...time,
                        second: time.second - 1
                    });
                } else if (time && time.minute === 0 && time.second === 0) {
                    reset();
                    props.finishExam();
                }
            }, 1000);
        } else if (!isActive && time.second !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, time]);

    function start() {
        setIsActive(true);
    }

    function reset() {
        setTime({ ...time, minute: 0, second: 0 })
        setIsActive(false);
    }

    useEffect(() => {
        start();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="text-light bold">
            Time Remaning :{time.minute < 10 ? `0${time.minute}` : time.minute}:{time.second < 10 ? `0${time.second}` : time.second}
        </div>
    )
}

export default Timer
