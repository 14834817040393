import { Question } from '../../../../common/model/Question';
import ImageHelper from '../../../../common/component/ImageHelper';
import questionType from '../../../../common/enum/questiontype';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PreviewAudioplayer from '../../../../student/views/Exam/components/PreviewAudioplayer';
import PreviewQuestionOptionItem from '../../../../student/views/Exam/components/PreviewQuestionOptionItem';


interface IDisplayQuestion {
    question: Question,
    selectedAnswer: string,
    changePosition: Function,
    playAudio: Function,
    position: number
}

const DisplayQuestionResult = (props: IDisplayQuestion) => {

    return (
        <Grid container spacing={1}>
            <Grid item xs={7}>
                <Box sx={{ border: '1px solid grey', padding: 1, overflow: "auto", height: { xs: "60vw", md: "60vh" } }}>
                    <Stack direction="column">
                        <div className="my-xs">
                            {props.question?.main_heading_image ?
                                <ImageHelper
                                    onClick={() => { }}
                                    defaultListening={false}
                                    style={{ maxWidth: '90%', maxHeight: '20rem', objectFit: 'contain' }}
                                    className=""
                                    image={props.question?.main_heading || ''}
                                /> :
                                <Typography style={{ wordBreak: 'keep-all' }} variant="h6">{props.question?.main_heading}</Typography>
                            }
                        </div>
                        <Typography variant="h6">
                            {`${props.position + 1}. `}  {props.question?.heading && props.question?.heading}
                        </Typography>
                        <div className="my-sm">
                            {props.question?.description_image ?
                                <ImageHelper
                                    onClick={() => { }}
                                    defaultListening={false}
                                    style={{ maxWidth: '80%', maxHeight: '15rem', minWidth: '40%', minHeight: '12rem', objectFit: 'contain' }}
                                    className=""
                                    image={props.question?.description || ''}
                                /> :
                                <Box sx={{ padding: 2, border: props.question.description_boxed ? "1px solid grey" : "" }}>
                                    <Typography style={{ wordBreak: 'keep-all', whiteSpace: 'pre-line' }} variant="subtitle1">
                                        {props.question?.description}
                                    </Typography>
                                </Box>
                            }
                        </div>
                        {
                            props.question?.type === questionType.LISTENING &&
                            <PreviewAudioplayer
                                audioSrc={`${process.env.REACT_APP_API_BASE_URL}${props.question?.listening}`}
                                id={`listening`} />
                        }
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={5}>
                <Stack direction="column">
                    <Box sx={{ border: '1px solid grey', padding: 1, overflow: "auto", height: { xs: "55vw", md: "54vh" } }}>
                        <Stack direction="column" spacing={1}>
                            <PreviewQuestionOptionItem
                                butttonNumber={'1'}
                                question={props.question}
                                selectedAnswer={props.selectedAnswer}
                                value={'1'}
                                paramName={'option1'}
                            />
                            <PreviewQuestionOptionItem
                                butttonNumber={'2'}
                                question={props.question}
                                selectedAnswer={props.selectedAnswer}
                                value={'2'}
                                paramName={'option2'}
                            />
                            <PreviewQuestionOptionItem
                                butttonNumber={'3'}
                                question={props.question}
                                selectedAnswer={props.selectedAnswer}
                                value={'3'}
                                paramName={'option3'}
                            />
                            <PreviewQuestionOptionItem
                                butttonNumber={'4'}
                                question={props.question}
                                selectedAnswer={props.selectedAnswer}
                                value={'4'}
                                paramName={'option4'}
                            />

                        </Stack>

                    </Box>


                    <div className={`flex justify-center mt-xs`}>
                        <div className="flex option-btn pa-sm justify-between items-center flex-1 border-1px" onClick={() => props.changePosition(-1)}>
                            <i className="material-icons">chevron_left</i>
                            Previous
                        </div>
                        <div className="flex option-btn pa-sm justify-between items-center flex-1 border-1px" onClick={() => props.changePosition(1)}>
                            Next
                            <i className="material-icons">chevron_right</i>
                        </div>
                    </div>
                </Stack>

            </Grid>
        </Grid>



    )
}

export default DisplayQuestionResult
