import React, { useState } from 'react';
import * as actions from '../store/CategoryActions';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import AddusersRules from './AddusersRules';
import { useDispatch } from 'react-redux';

const initialValues: any = {
    title: '',
};

interface IAddCategory {
    closeModal: Function,
    mode: string
}

const AddCategory = (props: IAddCategory) => {
    // const dispatch = useDispatch();
    const [errors, setErrors] = useState(initialValues);
    const [category, setCategory] = useState(initialValues);
    const dispatch = useDispatch()


    const handelFormSubmit = async (e: any) => {
        e.preventDefault();
        let errorMsgs: any = ValidateForm(category, AddusersRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(actions.addCategory(category));
            props.closeModal();

        }
    };
    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, AddusersRules);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg,
        });

    };
    const inputHandler = (e: any) => {
        setCategory({
            ...category,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area">
                <form onSubmit={handelFormSubmit}>
                    <div className="form-area my-lg auto-scroll">
                        <div className="form-group my-sm">
                            <label className={'bold text-primary'}>Title</label>
                            <input
                                type="text"
                                name={'title'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Title'}
                            />
                            {errors.title !== '' ? (
                                <span className="error-text">{errors.title}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <button className="btn primary full-width" type={'submit'}>Add Category</button>
                </form>
            </div>
        </div>
    );
};
export default AddCategory;
