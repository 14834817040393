import React from 'react';
import ROUTELIST from "./routerList"
import { Switch } from 'react-router-dom';
import InstituteRouter from './instituteRouter';
import NotFound from '../../auth/NotFound';

const ClientRoute = () => {
    return (
        <>
            <React.Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {
                        ROUTELIST.map((route, key) => (
                            <InstituteRouter path={`/institute${route.path}`}
                                name={route.name}
                                component={route.component}
                                exact={route.exact}
                                key={key} />
                        ))
                    }
                    <InstituteRouter
                        component={NotFound}
                    />
                </Switch>
            </React.Suspense>
        </>
    )
}

export default ClientRoute;