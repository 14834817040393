import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { GetRequest } from '../../../../../plugins/axios';
import Confirmation from '../../../../../common/modal/Confirmation';
import Modal from '../../../../../common/modal';
import ChangePasswordAdmin from '../../UserProfile/components/ChangePasswordAdmin';
import EditUser from '../../usermanagement/components/EditUser';
import * as actions from '../../usermanagement/store/UsersActions';
import { setToasterState } from '../../../../../store/action';
import { Grid } from '@mui/material';
import ExamCard from '../../../../../common/ExamCard';
import { useHistory } from 'react-router';


const Exams = ({ userId }: any) => {

    const dispatch = useDispatch();
    const [exams, setExams] = useState([]);

    const user = useSelector((state: any) => {
        return state.auth.user;
    });
    const [showModal, setShowModal] = useState(false);
    const [editUserObj, setEditUserObj] = useState({ _id: -1 });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');

    const history = useHistory();
    const viewExam = (obj: any) => {
        history.push(`exams/exam/${obj._id}`)
    }

    useEffect(() => {
        user._id && userId && loadExams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, user]);

    const loadExams = async () => {
        const url = process.env.REACT_APP_API_BASE_URL + 'exams'
        GetRequest(`${url}/institute/${userId}/${user._id}`, {}, {}).then(res => {
            setExams(res.data)

        }).catch(err => {
            dispatch(
                setToasterState({
                    appear: true,
                    title: 'error',
                    name: 'Load Error',
                    message: err.response?.data?.error || 'Error loading User',
                })
            );
        })
    }

    const deleteAction = (obj: any) => {
        setShowConfirmation(true);
        setEditUserObj(obj)
    }

    const deleteConfirmation = async () => {
        await dispatch(actions.deleteUser(editUserObj._id));
        setShowConfirmation(false);
    }


    const editUser = async (payload: any) => {
        await dispatch(actions.editUser(payload));
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditUserObj(obj);
        setShowModal(true);

    }

    const changePassword = async (payload: any) => {
        payload = {
            ...payload,
            user: editUserObj._id
        };
        await dispatch(actions.changeUserPassword(payload));
    }

    const changePasswordActions = (obj: any) => {
        setModalMode('changePassword');
        setEditUserObj(obj);
        setShowModal(true);
    }



    return (
        <Box>
            <Grid container spacing={1}>
                {exams.length ? exams.map((e: any) => (
                    <Grid item lg={3} md={4} sm={12}>
                        <ExamCard
                            key={e._id}
                            exam={e}
                            editAction={editAction}
                            viewAction={viewExam}
                            deleteAction={deleteAction}
                        />
                    </Grid>

                )) : (
                    <div>Empty Exam List</div>
                )}
            </Grid>
            {
                modalMode === 'edit' &&
                <Modal show={showModal} title={'Edit User'}
                    closeModal={() => setShowModal(false)}>
                    <EditUser
                        changePassword={changePasswordActions}
                        editUser={editUser}
                        editObj={editUserObj}
                        closeModal={() => setShowModal(false)}
                        mode={modalMode}
                    />
                </Modal>
            }
            {
                modalMode === 'changePassword' &&
                <Modal show={showModal} title={'Change Password'}
                    closeModal={() => setShowModal(false)}>
                    <ChangePasswordAdmin
                        closeModal={() => setShowModal(false)}
                        changePassword={changePassword}
                        user={editUserObj} />
                </Modal>
            }
            <Confirmation title="Delete User"
                content="Are you sure you want to delete this user?"
                show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </Box>
    )
}

export default Exams;
