import { Button, Container, Paper, Stack } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import img from '../../../../assets/default.png'
import * as actions from '../../../../store/action';


const rules = [
    // "Connect to a stable internet so there will be no interuption",
    "If internet disconnects during exam then exam is counted but result will not be saved",
    "You can give a answer or can leave it, It will be marked as not attempted"
]

const ExamStart = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const [exam, setExam] = useState<any>("")
    const user = useSelector((state: any) => state.auth.user);
    const institute = useSelector((state: any) => state.auth.institute);
    const { examId } = useParams<{ examId: string }>();

    useEffect(() => {
        const examText: any = localStorage.getItem("startExam");
        if (examText) {
            console.log(JSON.parse(examText));
            const parsedExam = JSON.parse(examText)
            if (parsedExam._id === examId) {
                setExam(parsedExam)
            } else {
                returnBack()
            }
        } else {
            returnBack()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const returnBack = () => {
        history.goBack();
    };

    const viewExam = async () => {
        if (exam && exam._id) {
            localStorage.setItem('runningExam', exam._id || '')
            const payload = {
                exam: exam._id,
                user: user._id,
                institute: institute?._id
            }
            await dispatch(actions.storeDataToReport(payload));
            await dispatch(actions.updateExamAccess(payload));
            dispatch(actions.setExamRunning(true));
            dispatch(actions.setScreenLandscape(true));
            history.push(`/student/exams/exam/${examId}`)
        }
    }
    return (
        <Container >
            <Stack spacing={2} >

                <Paper elevation={3} variant="outlined">
                    <Box sx={{ padding: 3 }}>
                        <Stack justifyContent="center" alignItems="center" spacing={1} >
                            <div className="title-sm bold text-primary">Korenvasa UBT-TRIAL-EXAM</div>
                            <img src={img} alt="" height={100} />
                            <Stack direction={'row'} justifyContent="center" alignItems="center" spacing={1} >
                                <div className="bold ">Your Exam ID :</div>
                                <div>{user.user_uid}</div>
                            </Stack>
                            <Stack direction={'row'} justifyContent="center" alignItems="center" spacing={1} >
                                <div className="bold ">Name of Student :</div>
                                <div>{user.name}</div>
                            </Stack>
                            <div className="bold">Exam Title : {exam?.title}</div>
                            <Button variant="contained" onClick={viewExam}>Get Started</Button>
                            <Button variant="outlined" onClick={returnBack}>Cancel</Button>
                        </Stack>
                    </Box>
                </Paper>
                <Paper elevation={3} variant="outlined">
                    <Box sx={{ padding: 2 }}>
                        <Stack spacing={1} >
                            <div>****NOTE****</div>
                            {rules.map((rule: string, i: number) =>
                                <li key={i} className="list-decoration">{rule}</li>
                            )}
                        </Stack>
                    </Box>
                </Paper>

            </Stack>
        </Container>

    )
}

export default ExamStart
