import * as actionTypes from './loadingType';
import { LoadingStateInterface } from '../interface/loadingInterface';

const initialState: LoadingStateInterface = {
  isLoading: false,
  isSuccess: false,
};
// eslint-disable-next-line
export default (state = initialState, actions: any) => {
  const { type, mode } = actions;
  switch (type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: true,
        loadingMode: mode,
      };
    case actionTypes.CLEAR_LOADING:
      return initialState;
    case actionTypes.SET_SUCCESS:
      return {
        ...state,
        isSuccess: actions.payload,
      };
    default:
      return state;
  }
};
