import UserProfile from '../../common/views/UserProfile';
import AdminHome from '../views/AdminHome';
import Exams from '../views/Exams';
import ReportList from '../views/Report';
import Institutes from '../views/Institutes';
import Questions from '../views/Questions';
import Results from '../../common/views/Results';
import ReportResults from '../../common/views/UserDetail/components/Results';
import UserManagement from '../../common/views/usermanagement';
import PreviewResult from '../../common/views/PreviewResult';
import ExamAccess from '../views/ExamAccess';
import UserDetail from '../../common/views/UserDetail';
import CategoryList from '../../common/views/CategoryList';
import TransferExam from '../views/TransferExam';
// eslint-disable-next-line
export default [
  {
    path: '',
    name: 'admin',
    exact: true,
    component: AdminHome,
  },
  {
    path: '/users',
    name: 'admin.users',
    exact: true,
    component: UserManagement,
  },
  {
    path: '/profile',
    name: 'admin.users',
    exact: true,
    component: UserProfile,
  },
  {
    path: '/exams',
    name: 'admin.users',
    exact: true,
    component: Exams,
  },

  {
    path: '/exams/exam/:examId',
    name: 'admin.users',
    exact: true,
    component: Questions,
  },
  {
    path: '/exams/exam/:examId/results',
    name: 'admin.exams.results',
    exact: true,
    component: Results,
  },
  {
    path: '/results',
    name: 'admin.exams.results',
    exact: true,
    component: ReportResults,
  },
  {
    path: '/exams/exam/:examId/institutes',
    name: 'admin.exams.institute',
    exact: true,
    component: ExamAccess,
  },
  {
    path: '/exams/exam/:examId/results/:resultId',
    name: 'admin.exams.results.result',
    exact: true,
    component: PreviewResult,
  },
  {
    path: '/results/:resultId',
    name: 'admin.exams.results.result',
    exact: true,
    component: PreviewResult,
  },
  {
    path: '/institutes',
    name: 'admin.institutes',
    exact: true,
    component: Institutes,
  },
  {
    path: '/institutes/:userId',
    name: 'admin.institutes.userId',
    exact: true,
    component: UserDetail,
  },
  {
    path: '/users/:userId',
    name: 'admin.users.userid',
    exact: true,
    component: UserDetail,
  },
  {
    path: '/reports',
    name: 'admin.reports',
    exact: true,
    component: ReportList,
  },
  {
    path: '/categories',
    name: 'admin.categories',
    exact: true,
    component: CategoryList,
  },

  {
    path: '/transferexam',
    name: 'admin.transferexam',
    exact: true,
    component: TransferExam,
  },
];
