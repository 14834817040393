import * as actionTypes from './InstituteTypes';
interface IAdminUsers {
  questions: [];
  exams: [];
  users: [];
  results: [];
  listening: {};
}
const initailState: IAdminUsers = {
  exams: [],
  questions: [],
  users: [],
  results: [],
  listening: {},
};
// eslint-disable-next-line
export default (state: IAdminUsers = initailState, actions: any) => {
  switch (actions?.type) {
    case actionTypes.GET_INSTITUTE_EXAMS:
      return {
        ...state,
        exams: actions.payload,
      };
    case actionTypes.GET_INSTITUTE_QUESTIONS:
      return {
        ...state,
        questions: actions.payload,
      };
    case actionTypes.GET_INSTITUTE_USERS:
      return {
        ...state,
        users: actions.payload,
      };
    case actionTypes.GET_INSTITUTE_LISTENING:
      return {
        ...state,
        listening: actions.payload,
      };
    case actionTypes.GET_INSTITUTE_RESULTS:
      return {
        ...state,
        results: actions.payload,
      };
    default:
      return state;
  }
};
