import * as actionTypes from './ResultsTypes';
interface IQuestions {
  results: [];
  loading: Boolean;
  error: string;
}
const initailState: IQuestions = {
  results: [],
  loading: false,
  error: '',
};
// eslint-disable-next-line
export default (state: IQuestions = initailState, actions: any) => {
  switch (actions?.type) {
    case actionTypes.GET_RESULTS_LIST:
      return {
        ...state,
        results: actions.payload,
      };
    case actionTypes.GET_RESULTS_LOADING:
      return {
        ...state,
        loading: actions.payload,
      };
    case actionTypes.GET_RESULTS_ERROR:
      return {
        ...state,
        error: actions.payload,
      };
    default:
      return state;
  }
};
