export const GET_EXAMS = "GET_EXAMS";
export const GET_EXAMS_ALL = "GET_EXAMS_ALL";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const SET_QUESTION_POSITION = "SET_QUESTION_POSITION";
export const SET_QUESTION_TYPE = "SET_QUESTION_TYPE";
export const SET_ANSWERS = "SET_ANSWERS";
export const SET_STOP_AUDIO = 'SET_STOP_AUDIO';
export const GET_LISTENING_PLAY_ID = 'GET_LISTENING_PLAY_ID';
export const GET_LISTENING_STATUS = "GET_LISTENING_STATUS";
export const GET_RESULTS = "GET_RESULTS";
export const SET_EXAM_RUNNING = "SET_EXAM_RUNNING";
export const GET_REPORT_ID = "GET_REPORT_ID";
export const GET_SCREEN_LANDSCAPE = 'GET_SCREEN_LANDSCAPE';
export const GET_FILTER = 'GET_FILTER';
export const GET_RESULT_ID = 'GET_RESULT_ID';

