import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GetRequest } from '../../../../../plugins/axios';

const resultUrl = process.env.REACT_APP_API_BASE_URL + 'results';
export const getExamResultAdminSuccess = (payload: any) => {
  return {
    type: 'GET_ADMIN_RESULT',
    payload: payload,
  };
};

export const getAdminResultFromResultId =
  (resultId: string) => (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    dispatch(getExamResultAdminSuccess(''));
    if (userID) {
      GetRequest(`${resultUrl}/${resultId}/${userID}`, {}, {})
        .then((res) => {
          dispatch(getExamResultAdminSuccess(res.data));
        })
        .catch((err) => {
          console.log(err);

          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Fetch Error',
              message: 'Error cannot get Result from id',
            })
          );
        });
    } else {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Fetch Error',
          message: 'Cannot get userid',
        })
      );
    }
  };
