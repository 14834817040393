import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_TIMES_LISTENING } from '../../../../../utils/utils';
import * as actions from '../services/ExamActions'

export default function StudentAudioplayer({ audioSrc, id, listeningStatus, listeningStatusAddOne }: any) {
    const [audio, setAudio] = useState('')
    const dispatch = useDispatch()
    const [isPlaying, setIsPlaying] = useState(false)

    const listeningPlayId = useSelector((state: any) => {
        return state.userReducer.listeningPlayId;
    });


    useEffect(() => {
        if (!isPlaying) {
            if (listeningStatus && listeningStatus.status[id] < DEFAULT_TIMES_LISTENING) {
                if (listeningPlayId && listeningPlayId !== id) {
                    setAudio('')
                } else {
                    setAudio(audioSrc)
                }
            } else {
                setAudio('')
            }
        } else {
            //if listening play id is set to black stop audio or this is run on switing qurestion
            if (!listeningPlayId) {
                setAudio('')
                setIsPlaying(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listeningPlayId, listeningStatus, audioSrc])

    useEffect(() => {
        if (listeningStatus && listeningStatus.question) {

            const audioEl: any = document.getElementById(id);

            audioEl.addEventListener("pause", () => {
                if (audioEl.paused && !audioEl.ended) {
                    audioEl.play()
                }
            }, true)
            audioEl.addEventListener("ended", () => {
                // setAudio('')
                setIsPlaying(false)
                dispatch(actions.setListeningPlayId(''))

            }, true)

            audioEl.addEventListener("play", () => {
                //set play and its id
                setIsPlaying(true)
                dispatch(actions.setListeningPlayId(id))
                if (listeningStatus.question) {
                    listeningStatusAddOne(listeningStatus.question, id)
                }
            }, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listeningStatus])


    return (
        <Box sx={{ width: '40px', height: '40px', overflow: 'hidden', padding: 0, borderRadius: '50%', margin: "0 auto" }}>
            <audio id={id}
                preload={'auto'}
                controlsList="nodownload"
                style={{ margin: '-5px' }}
                controls={true}
                src={audio} />
        </Box>
    )
}
