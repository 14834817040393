// eslint-disable-next-line
export default {
  password: {
    required: true,
    minValue:8,
    label: 'Current Password',
  },
  newPassword: {
    required: true,
    minValue:8,
    label: 'New Password',
  },
  image_file: {
    file: true,
    label: 'Profile image',
    maxSize: 716800,
    types: ['image/png', 'image/jpeg']
  }
};
