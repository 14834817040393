import { Button, Divider, IconButton, List, ListItem, ListItemText, Paper, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Answer } from '../../../../common/model/Answer'


interface IFinishExam {
    closeModal: Function,
    viewDetails: Function,
    viewResult: Function,
    answers: Answer[]
}

const FinishExam = (props: IFinishExam) => {

    const user = useSelector((state: any) => state.auth.user);
    const resultId = useSelector((state: any) => state.userReducer.resultId);

    const [result, setResult] = useState({
        total: 0,
        attempted: 0,
        correct: 0,
        percentage: 0
    })

    useEffect(() => {
        let attempted = 0;
        let correct = 0;
        props.answers.forEach(a => {
            a.answer !== '0' && attempted++;
            a.correct && correct++
        })

        setResult({
            ...result,
            total: props.answers.length,
            attempted: attempted,
            correct: correct,
            percentage: (correct / props.answers.length) * 100
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answers])

    return (
        <>
            <Box>
                <Stack spacing={1} alignItems="stretch" justifyContent="center">
                    <h2>Congratulation, Exam Ended</h2>
                    <Stack direction="column"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center">

                        <h3>Name: {user.name}</h3>
                        <h3>ExamId: {user.user_uid}</h3>
                    </Stack>
                    <Paper variant="outlined">


                        <List>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                        {result.total}
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={'Total Questions'}
                                />
                            </ListItem>
                        </List>
                        <Divider />
                        {/* //attempt */}
                        <List>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                        {result.attempted}
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={'Total Attempt'}
                                />
                            </ListItem>
                        </List>
                        <Divider />
                        {/* //correct */}
                        <List>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                        {result.correct}
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={'Total Correct'}
                                />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                        {`${result.percentage.toFixed(2)}`}%
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={'Your Percentage'}
                                />
                            </ListItem>
                        </List>
                        <Divider />
                    </Paper>


                    <Stack direction="row" spacing={2} justifyContent="center"
                        alignItems="center">
                        {resultId && <Button onClick={() => props.viewResult(resultId)} variant="contained">
                            View Result
                        </Button>}
                        <Button onClick={() => props.viewDetails()} variant="outlined">
                            Results List
                        </Button>
                        <Button onClick={() => props.closeModal()} variant="contained">
                            Back To Main Page
                        </Button>
                    </Stack>
                </Stack>

            </Box>



        </>
    )
}

export default FinishExam
