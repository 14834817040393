import { Stack } from '@mui/material'
import React from 'react'
import RadioButton from '../../../../../common/Radio'
import ToggleSwitch from '../../../../../common/ToggleSwitch'
import optionType from '../../../enum/optionType'
import questionType from '../../../enum/questiontype'


const AddQuestionForm = ({
    question,
    errors,
    inputValidation,
    inputHandler,
    toggleImage,
    acceptedQnoList,
    inputImageHandler }: any) => {
    return (
        <div>
            <div className="form-group my-md">
                <label className={'bold text-primary'}>Question Number</label>
                <select name="question_number"
                    value={question.question_number}
                    onBlur={inputValidation}
                    onChange={inputHandler}>
                    <option value=""></option>
                    {acceptedQnoList.map((qno: number) => <option value={`${qno}`}>{qno}</option>)}
                </select>
                {errors.question_number ? (
                    <span className="error-text">{errors.question_number}</span>
                ) : (
                    ''
                )}
            </div>
            <div className="form-group my-sm ">
                <div className="flex items-center">
                    <label className="pr-sm">Main Heading</label>
                    <ToggleSwitch
                        checked={question.main_heading_image || false}
                        name="main_heading_image"
                        onChange={toggleImage}
                    />
                </div>
                {question.main_heading_image ? (
                    <input
                        type="file"
                        accept={'image/x-png,image/jpg,image/jpeg'}
                        name={'main_heading'}
                        onBlur={inputValidation}
                        onChange={inputImageHandler}
                    />
                ) : (
                    <input
                        type="text"
                        name={'main_heading'}
                        onBlur={inputValidation}
                        value={question.main_heading}
                        onChange={inputHandler}
                    />
                )}

                {errors.main_heading !== '' ? (
                    <span className="error-text">{errors.main_heading}</span>
                ) : (
                    ''
                )}
            </div>
            <div className="form-group my-md">
                <label>Question Heading</label>
                <input
                    type="text"
                    name={'heading'}
                    onBlur={inputValidation}
                    value={question.heading}
                    onChange={inputHandler}
                />
                {errors.heading !== '' ? (
                    <span className="error-text">{errors.heading}</span>
                ) : (
                    ''
                )}
            </div>
            <div className="form-group my-md">
                <div className="flex items-center">
                    <label className="pr-sm">Description</label>
                    <ToggleSwitch
                        checked={question.description_image || false}
                        name="description_image"
                        onChange={toggleImage}
                    />
                </div>
                <div className="flex items-center">
                    <label className="pr-sm">Description Boxed</label>
                    <ToggleSwitch
                        checked={question.description_boxed || false}
                        name="description_boxed"
                        onChange={toggleImage}
                    />
                </div>
                {question.description_image ? (
                    <input
                        type="file"
                        accept={'image/x-png,image/jpg,image/jpeg'}
                        name={'description'}
                        onBlur={inputValidation}
                        onChange={inputImageHandler}
                    />
                ) : (
                    <>
                        <textarea
                            name={'description'}
                            rows={4}
                            onChange={inputHandler}
                            value={question.description} />

                    </>
                )}
                {errors.description !== '' ? (
                    <span className="error-text">{errors.description}</span>
                ) : (
                    ''
                )}
            </div>

            <div className="form-group mb-sm">
                <label className={'bold text-primary'}>Option Type</label>
                <select name="option_type"
                    value={question.option_type}
                    onBlur={inputValidation}
                    onChange={inputHandler}>
                    <option value={optionType.TEXT}>Text</option>
                    <option value={optionType.IMAGE}>Image</option>
                    {question.type === questionType.LISTENING && (
                        <>
                            <option value={optionType.LISTENING}>Listening</option>
                            <option value={optionType.LISTENING_AND_TEXT}>Listening And Text</option>
                            <option value={optionType.SAME_LISTENING}>Same Listening</option>
                        </>
                    )}
                </select>

                {errors.option_type !== '' ? (
                    <span className="error-text">{errors.option_type}</span>
                ) : (
                    ''
                )}
            </div>

            <div className="form-group">
                {errors.answer !== '' ? (
                    <span className="error-text">{errors.answer}</span>
                ) : (
                    ''
                )}
            </div>

            <div className="form-group my-md">
                <label>Option 1</label>
                <div className="flex align-center">
                    <RadioButton
                        onImageClick={() => { }}
                        optionType={''}
                        checked={question.answer === '1'}
                        butttonNumber={'1'}
                        name={'answer'}
                        onChange={inputHandler}
                        onBlur={inputValidation}
                        text={''}
                        value={'1'} />
                    <Stack direction="column" spacing={1}>
                        {(question.option_type === optionType.IMAGE || question.option_type === optionType.LISTENING || question.option_type === optionType.LISTENING_AND_TEXT) && (
                            <input
                                className="mx-sm qwe-options"
                                type="file"
                                accept={question.option_type === optionType.IMAGE ? 'image/x-png,image/jpg,image/jpeg' : '.mp3,audio/*'}
                                name={question.option_type === optionType.IMAGE ? 'option1' : 'option1_listening'}
                                onBlur={inputValidation}
                                onChange={inputImageHandler}
                            />
                        )}
                        {(question.option_type === optionType.TEXT || question.option_type === optionType.LISTENING_AND_TEXT) && (
                            <input
                                className="mx-sm qwe-options"
                                type="text"
                                name={'option1'}
                                onBlur={inputValidation}
                                value={question.option1}
                                onChange={inputHandler}
                            />
                        )}
                    </Stack>
                </div>
                {errors.option1 !== '' ? (
                    <span className="error-text">{errors.option1}</span>
                ) : (
                    ''
                )}
            </div>

            <div className="form-group my-md">
                <label>Option 2</label>
                <div className="flex align-center">
                    <RadioButton
                        onImageClick={() => { }}
                        optionType={''}
                        checked={question.answer === '2'}
                        butttonNumber={'2'}
                        name={'answer'}
                        onChange={inputHandler}
                        onBlur={inputValidation}
                        text={''}
                        value={'2'} />
                    <Stack direction="column" spacing={1}>
                        {(question.option_type === optionType.IMAGE || question.option_type === optionType.LISTENING || question.option_type === optionType.LISTENING_AND_TEXT) && (
                            <input
                                className="mx-sm qwe-options"
                                type="file"
                                accept={question.option_type === optionType.IMAGE ? 'image/x-png,image/jpg,image/jpeg' : '.mp3,audio/*'}
                                name={question.option_type === optionType.IMAGE ? 'option2' : 'option2_listening'}
                                onBlur={inputValidation}
                                onChange={inputImageHandler}
                            />
                        )}
                        {(question.option_type === optionType.TEXT || question.option_type === optionType.LISTENING_AND_TEXT) && (
                            <input
                                type="text"
                                name={'option2'}
                                onBlur={inputValidation}
                                className="mx-sm qwe-options"
                                value={question.option2}
                                onChange={inputHandler}
                            />
                        )}
                    </Stack>
                </div>
                {errors.option2 !== '' ? (
                    <span className="error-text">{errors.option2}</span>
                ) : (
                    ''
                )}
            </div>

            <div className="form-group my-md">
                <label>Option 3</label>
                <div className="flex align-center">
                    <RadioButton
                        onImageClick={() => { }}
                        optionType={''}
                        checked={question.answer === '3'}
                        butttonNumber={'3'}
                        name={'answer'}
                        onChange={inputHandler}
                        onBlur={inputValidation}
                        text={''}
                        value={'3'} />
                    <Stack direction="column" spacing={1}>
                        {(question.option_type === optionType.IMAGE || question.option_type === optionType.LISTENING || question.option_type === optionType.LISTENING_AND_TEXT) && (
                            <input
                                type="file"
                                accept={question.option_type === optionType.IMAGE ? 'image/x-png,image/jpg,image/jpeg' : '.mp3,audio/*'}
                                name={question.option_type === optionType.IMAGE ? 'option3' : 'option3_listening'}
                                onBlur={inputValidation}
                                onChange={inputImageHandler}
                                className="mx-sm qwe-options"
                            />
                        )}
                        {(question.option_type === optionType.TEXT || question.option_type === optionType.LISTENING_AND_TEXT) && (
                            <input
                                type="text"
                                name={'option3'}
                                onBlur={inputValidation}
                                value={question.option3}
                                onChange={inputHandler}
                                className="mx-sm qwe-options"
                            />
                        )}
                    </Stack>
                </div>
                {errors.option3 !== '' ? (
                    <span className="error-text">{errors.option3}</span>
                ) : (
                    ''
                )}
            </div>

            <div className="form-group my-md">
                <label>Option 4</label>
                <div className="flex align-center">
                    <RadioButton
                        onImageClick={() => { }}
                        optionType={''}
                        checked={question.answer === '4'}
                        butttonNumber={'4'}
                        name={'answer'}
                        onChange={inputHandler}
                        onBlur={inputValidation}
                        text={''}
                        value={'4'} />
                    <Stack direction="column" spacing={1}>
                        {(question.option_type === optionType.IMAGE || question.option_type === optionType.LISTENING || question.option_type === optionType.LISTENING_AND_TEXT) && (
                            <input
                                className="mx-sm qwe-options"
                                type="file"
                                accept={question.option_type === optionType.IMAGE ? 'image/x-png,image/jpg,image/jpeg' : '.mp3,audio/*'}
                                name={question.option_type === optionType.IMAGE ? 'option4' : 'option4_listening'}
                                onBlur={inputValidation}
                                onChange={inputImageHandler}
                            />
                        )}
                        {(question.option_type === optionType.TEXT || question.option_type === optionType.LISTENING_AND_TEXT) && (
                            <input
                                type="text"
                                name={'option4'}
                                className="mx-sm qwe-options"
                                onBlur={inputValidation}
                                value={question.option4}
                                onChange={inputHandler}
                            />
                        )}
                    </Stack>

                </div>

                {errors.option4 !== '' ? (
                    <span className="error-text">{errors.option4}</span>
                ) : (
                    ''
                )}
            </div>

            {question.type === questionType.LISTENING &&
                <div className="form-group my-md">
                    <label>listening(max size = 1MB)</label>
                    <input
                        type="file"
                        name={'listening'}
                        onBlur={inputValidation}
                        onChange={inputImageHandler}
                        accept={'.mp3,audio/*'}
                    />
                    {errors.listening !== '' ?
                        <span className="error-text">{errors.listening}</span> : ''}
                </div>}
        </div>
    )
}

export default AddQuestionForm;
