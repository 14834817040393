import { Button } from '@mui/material';
import React, { useState } from 'react';
import img from '../../../../../assets/default.png';
import Modal from '../../../../../common/modal';
import MyCredentialInfo from './MyCredentialInfo';
import ProfilePictureForm from './ProfilePictureForm';

const ProfilePicture = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const user = props.user;

    return (
        <div className="profile-picture-card flex justify-center items-center column">
            <img className="ma-lg" src={user?.image ? `${process.env.REACT_APP_API_BASE_URL}${user.image}` : img} alt="" />
            <span className="link mb-lg" onClick={() => setShowModal(true)}>Change Image</span>
            <Button variant='contained' onClick={() => setShowDetailModal(true)}>View My Details</Button>
            <Modal show={showModal} title={'Change Image'}
                closeModal={() => setShowModal(false)}>
                <ProfilePictureForm
                    user={user}
                    closeModal={() => setShowModal(false)}
                />
            </Modal>

            <Modal show={showDetailModal} title={'My Credential Info'}
                closeModal={() => setShowDetailModal(false)}>
                <MyCredentialInfo />
            </Modal>

        </div>
    )
}

export default ProfilePicture
