import { setSuccess } from '../../../../../common/loading/services/loadingAction';
import { PostRequest } from '../../../../../plugins/axios';
import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';

const examAccessUrl = process.env.REACT_APP_API_BASE_URL + 'examaccess';
export const addOrUpdateUserAccess = (data: string) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  dispatch(setSuccess(false));
  PostRequest(`${examAccessUrl}/create/${userID}`, data, {})
    .then((res) => {
      dispatch(setSuccess(true));
      dispatch(
        setToasterState({
          appear: true,
          title: 'success',
          name: 'Success',
          message: 'Successfully Added User Access',
        })
      );
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Error Adding',
          message: err.response?.data?.error || 'Error Adding User Access',
        })
      );
    });
};
