import React from 'react';
import InstituteRoute from "./router";
import { useLocation } from "react-router";
import NavClient from '../common/containers/Nav';
import ClientSidebar from '../common/containers/Sidebar';
import { Box } from '@mui/system';
import { drawerWidth } from '../../utils/customHooks/constant';
import { Toolbar } from '@mui/material';


const InstituteLayout: React.FC = () => {
    const location = useLocation();

    const noSideBar = () => {
        let loc = location.pathname.split('/').filter(v => v !== "");
        const isUserHome = loc.length === 1 && loc[0] === 'User';
        const isMenu = loc[2] === 'menu';
        return isUserHome || isMenu;
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // sx = {{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }

    return (
        <Box sx={{
            display: 'flex',
        }}>
            <NavClient drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} noSideBar={noSideBar} />
            {!!!noSideBar() && <ClientSidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <InstituteRoute />
            </Box>
        </Box>
    );
};

export default InstituteLayout;
