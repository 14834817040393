import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/action";
import { Prompt, useParams } from "react-router";
import { Question } from "../../../common/model/Question";
import questionType from "../../../common/enum/questiontype";
import "./exam.scss";
import { Answer } from "../../../common/model/Answer";
import Modal from "../../../../common/modal";
import FinishExam from "./components/FinishExam";
import { useHistory } from "react-router-dom";
import ExamHeading from "./components/ExamHeading";
import DisplayQuestion from "./components/DisplayQuestion";
import Confirmation from "../../../../common/modal/Confirmation";
import optionType from '../../../common/enum/optionType';
import { Container, Paper, Stack } from '@mui/material';
import DisplayAllMode from './components/DisplayAllMode';
import { setFilter } from './services/ExamActions';
import { Box } from '@mui/system';
import screenfull from 'screenfull';


const Exams = () => {
  const questionsList: Question[] = useSelector((state: any) => {
    return state.userReducer.questions;
  });
  const answersList: Answer[] = useSelector((state: any) => {
    return state.userReducer.answers;
  });
  const questionPosition = useSelector((state: any) => {
    return state.userReducer.currentQuestion;
  });
  const listeningStatus = useSelector((state: any) => {
    return state.userReducer.listening_status;
  });

  const examRunning: boolean = useSelector((state: any) => {
    return state.userReducer.examRunning;
  });

  const { examId } = useParams<{ examId: string }>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answer, setAnswer] = useState<Answer[]>([]);
  const user = useSelector((state: any) => state.auth.user);

  const [position, setPosition] = useState(0);
  const [examMode, setExamMode] = useState("all");
  const [modalMode, setModalMode] = useState("finishExam");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const listeningPlayId = useSelector((state: any) => state.userReducer.listeningPlayId);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("runningExam") === examId && examRunning) {
      dispatch(actions.setQuestionType(questionType.READING));
      user._id && loadQuestions();
      activeFullScreen()
    } else {
      returnBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const returnBack = () => {
    removeLocalStorageValue();
    history.push("/student/exams");
  };

  const removeLocalStorageValue = () => {
    localStorage.removeItem("runningExam");
    localStorage.removeItem("startExam");
  };

  useEffect(() => {
    removeCheckedFromRadio();
    questionPosition >= 0 && setPosition(questionPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionPosition]);

  useEffect(() => {
    dispatch(actions.setQuestionPosition(position));
    setQuestions(questionsList);

    let answers: any = [];
    questionsList.forEach((q) => {
      answers = [
        ...answers,
        { question: q._id, answer: "0", type: q.type, correct: 0, questionNumber: q.question_number },
      ];
    });
    dispatch(actions.setAnswers(answers));
    let a: any = [];
    questionsList
      .filter((q) => q.type === questionType.LISTENING)
      .forEach((q) => {
        let status = {}
        if (q.type === questionType.LISTENING) {
          status = { ...status, listening: 0 }
        }
        if (q.option_type === optionType.LISTENING || q.option_type === optionType.LISTENING_AND_TEXT) {
          status = { ...status, loption1: 0, loption2: 0, loption3: 0, loption4: 0 }
        } else if (q.option_type === optionType.SAME_LISTENING) {
          status = {
            ...status,
            loption1: optionType.SAME_LISTENING,
            loption2: optionType.SAME_LISTENING,
            loption3: optionType.SAME_LISTENING,
            loption4: optionType.SAME_LISTENING
          }
        }
        a = [...a, { question: q._id, status }];
      });
    setListeningStatus(a);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsList]);

  useEffect(() => {
    setAnswer(answersList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersList]);

  useEffect(() => {
    if (examRunning) {
      window.onbeforeunload = () => true;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [examRunning]);

  const activeFullScreen = () => {
    // var doc = window.document;
    // var docEl = doc.documentElement;
    // var requestFullScreen = docEl.requestFullscreen
    // requestFullScreen.call(docEl);
    if (screenfull.isEnabled) {
      screenfull.request();
    }
  }

  const setListeningStatus = async (payload: any) => {
    await dispatch(actions.setListeningStatus(payload));
  };

  const listeningStatusAddOne = (question: string, id: string) => {

    const newListeningStatus = listeningStatus.map((l: any) =>
      l.question === question ?
        { ...l, status: { ...l.status, [id]: l.status[id] + 1 } } : l
    );
    setListeningStatus(newListeningStatus);
  };

  const loadQuestions = async () => {
    examId && (await dispatch(actions.getQuestions(examId)));
  };

  const removeCheckedFromRadio = () => {
    const radios = document.querySelectorAll("#answer");
    radios &&
      radios.forEach((r: any) => {
        r.checked = false;
      });
  };
  const switchToSingleMode = async (position: number) => {
    setExamMode("single");
    changePosition(position);
  };

  const findPositionFromQuestionId = async (questionId: String) => {
    const position = questionsList.findIndex(question => question._id === questionId);
    switchToSingleMode(position);
  };


  const switchToAllMode = async () => {
    dispatch(setFilter('all'))
    dispatch(actions.setStopAudio(true))
    setExamMode("all");
    // await dispatch(actions.setListeningPlaying(false))
  };

  const changePosition = async (num: number) => {

    await dispatch(actions.setListeningPlayId(''));
    if (num >= 0 && num < questions.length) {
      setPosition(num);
      await dispatch(actions.setQuestionPosition(num));
    }
  };

  const changePositionByOne = async (num: number, allow: boolean = false) => {

    await dispatch(actions.setListeningPlayId(''));
    dispatch(actions.setStopAudio(true))
    if (position + num !== -1 &&
      position + num <= questions.length - 1) {
      setPosition(position + num);
      await dispatch(actions.setQuestionPosition(position + num))
    } else {
      switchToAllMode()
    }
  };

  const uploadAnswer = async () => {
    let score = 0;
    answer.forEach(a => {
      if (a.correct) {
        score++;
      }
    })
    const uploadPayload = {
      answers: answer,
      score
    };
    await dispatch(actions.uploadAnswer(examId, uploadPayload));
  };
  const answerChange = async (e: any) => {
    const newAnswers = await answersList.map((a, i) =>
      a.question === questions[position]._id
        ? {
          ...a,
          answer: e.target.value,
          correct: e.target.value === questions[position].answer,
        }
        : a
    );
    dispatch(actions.setAnswers(newAnswers));
  };

  const filterQuestions = (type: String) => {
    if (listeningPlayId === '') {
      dispatch(setFilter(type));
      setExamMode("all");
    }

  }

  const finishExamTodo = () => {
    removeLocalStorageValue();
    setShowConfirmation(false);
    uploadAnswer();
    dispatch(actions.setExamRunning(false));
    setModalMode("finishExam");
    setShowModal(true);
  };

  const submitExamDialog = () => {
    setModalMode("endExam");
    setShowConfirmation(true);
  };
  const closeModal = () => {
    setShowModal(false);
    history.push("/student/exams");
  };
  const closeFinishModel = () => {
    dispatch(actions.setScreenLandscape(false));
    closeModal()
  };
  const viewResultList = () => {
    dispatch(actions.setScreenLandscape(false));
    setShowModal(false);
    history.push("/student/results");
  };

  const viewResult = (resultId: string) => {
    dispatch(actions.setScreenLandscape(false));
    setShowModal(false);
    history.push(`/student/results/${resultId}`);
  };

  return (
    <Container maxWidth="lg">
      <Paper >
        <Box sx={{ p: { xs: 0, md: 2 } }}>
          <Stack direction="column"
            justifyContent="center"
            alignItems="stretch">
            <ExamHeading filterQuestions={filterQuestions} examMode={examMode} finishExamTodo={finishExamTodo} totalClick={switchToAllMode} answers={answer} />

            {examMode === "single" && questions.length > 0 && (
              <DisplayQuestion
                answer={answer[position]}
                question={questions[position]}
                answerChange={answerChange}
                switchToAllMode={switchToAllMode}
                changePosition={changePositionByOne}
                listeningStatusAddOne={listeningStatusAddOne}
                position={position}
              />
            )}

            {examMode === "all" &&
              <DisplayAllMode
                setListeningStatus={setListeningStatus}
                findPositionFromQuestionId={findPositionFromQuestionId}
                submitExamDialog={submitExamDialog}
                questionsList={questionsList}
              />
            }
          </Stack>
        </Box>
      </Paper>

      {modalMode === "finishExam" && (
        <Modal
          show={showModal}
          title={"Exam Ended - Exam Summary"}
          closeModal={closeModal}
          fullScreen={true}
        >
          <FinishExam
            viewDetails={viewResultList}
            viewResult={viewResult}
            closeModal={closeFinishModel}
            answers={answersList}
          />
        </Modal>
      )}

      {modalMode === "endExam" && (
        <Confirmation
          title="Confirm"
          content="Time is still remaining , Are you sure want to submit and end exam?"
          show={showConfirmation}
          confirm={finishExamTodo}
          closeModal={() => setShowConfirmation(false)}
        />
      )}
      <Prompt
        when={examRunning}
        message="Exam is Running, it will affect the unsaved changes, Are you sure want to quit?"
      />
    </Container>
  );
};

export default Exams;
