import UserProfile from '../../common/views/UserProfile';
import InstituteHome from '../views/InstituteHome';
import Exams from '../views/Exams';
import Questions from '../views/Questions';
import UserManagement from '../../common/views/usermanagement';
import Results from '../../common/views/Results';
import PreviewResult from '../../common/views/PreviewResult';
import UserDetail from '../../common/views/UserDetail';
import ReportResult from '../../common/views/UserDetail/components/Results';
// eslint-disable-next-line
export default [
  {
    path: '',
    name: 'institute',
    exact: true,
    component: InstituteHome,
  },
  {
    path: '/users',
    name: 'institute.users',
    exact: true,
    component: UserManagement,
  },
  {
    path: '/profile',
    name: 'institute.users',
    exact: true,
    component: UserProfile,
  },
  {
    path: '/exams',
    name: 'institute.users',
    exact: true,
    component: Exams,
  },
  {
    path: '/results',
    name: 'institute.results',
    exact: true,
    component: ReportResult,
  },
  {
    path: '/results/:resultId',
    name: 'admin.exams.results.result',
    exact: true,
    component: PreviewResult,
  },

  {
    path: '/exams/exam/:examId',
    name: 'institute.users',
    exact: true,
    component: Questions,
  },
  {
    path: '/exams/exam/:examId/results',
    name: 'institute.users',
    exact: true,
    component: Results,
  },
  {
    path: '/exams/exam/:examId/results/:resultId',
    name: 'institute.users',
    exact: true,
    component: PreviewResult,
  },
  {
    path: '/users/:userId',
    name: 'admin.users.userid',
    exact: true,
    component: UserDetail,
  },
];
