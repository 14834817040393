import { Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RadioButton from '../../../../../common/Radio'
import { isJsonString } from '../../../../../utils/utils'
import optionType from '../../../../common/enum/optionType'

export default function QuestionOptionItem({ listeningStatus, question, listeningStatusAddOne, answer, answerChange, value, butttonNumber, paramName }: any) {
    const [radioText, setRadioText] = useState('')
    const [audioSrc, setAudioSrc] = useState('')
    useEffect(() => {
        if (question?.option_type === optionType.LISTENING || question?.option_type === optionType.SAME_LISTENING) {
            setAudioSrc(`${process.env.REACT_APP_API_BASE_URL}${question[paramName]}`)
        } else if (question.option_type === optionType.LISTENING_AND_TEXT) {
            const parsedData = isJsonString(question[paramName])
            setAudioSrc(`${process.env.REACT_APP_API_BASE_URL}${parsedData.listening}`)
            setRadioText(parsedData.text)
        } else {
            setRadioText(question[paramName])
        }
        // eslint-disable-next-line
    }, [question])

    // useEffect(() => {
    //     console.log({ radioText, audioSrc, optionType: question.option_type });

    // }, [radioText, audioSrc])
    return (
        <>
            <RadioButton
                onImageClick={() => { }}
                optionType={question.option_type || ''}
                onBlur={() => { }}
                question={question}
                listeningStatus={listeningStatus}
                butttonNumber={butttonNumber}
                paramName={paramName}
                audioSrc={audioSrc}
                listeningStatusAddOne={listeningStatusAddOne}
                checked={answer?.answer === value}
                name={"answer"}
                value={value}
                onChange={(e: any) => answerChange(e)}
                text={radioText}
            />
            <Divider style={{ marginTop: 5 }} />
        </>
    )
}
