import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import questionType from '../../../../common/enum/questiontype';
import { Answer } from '../../../../common/model/Answer';
import EmptyBox from '../../../../student/views/Exam/components/EmptyBox';

interface IQuestionNumberLists {
    changeMode: Function;
    setListeningStatus: Function;
    answers: []
}

const ResultNumberLists = (props: IQuestionNumberLists) => {

    const [listenings, setListening] = useState<Answer[]>([])
    const [readings, setReadings] = useState<Answer[]>([])

    useEffect(() => {
        if (props.answers) {
            setListening(props.answers.filter((a: any) => a.question.type === questionType.LISTENING))
            setReadings(props.answers.filter((a: any) => a.question.type === questionType.READING))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answers])

    useEffect(() => {
        console.log({ listenings, readings });

    }, [listenings, readings])


    return (
        <>
            <Stack direction={'row'}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Box sx={{ p: 2, border: '1px solid grey' }}>
                            <div className="flex justify-center align-items mb-sm text-primary bold">
                                Reading Questions
                            </div>
                            <Grid container spacing={1}>
                                {readings.length > 0 ? readings.map((answer: any, key) => (
                                    <Grid item xs={2.4}>
                                        <div onClick={() => props.changeMode(key)} 
                                        className={`pointer pa-sm flex justify-center align-items bold border-1px ${answer.answer !== '0' ? answer.answer === answer.question.answer ? 'bg-success' : 'bg-danger' : ''}`}>
                                            {answer.question.question_number}
                                        </div>
                                    </Grid>

                                )) :
                                    <EmptyBox message={'No Reading Questions'} />
                                }
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box sx={{ p: 2, border: '1px solid grey' }}>
                            <div className="flex justify-center align-items mb-sm text-primary bold">
                                Listening Questions
                            </div>
                            <Grid container spacing={1}>
                                {listenings.length > 0 ? listenings.map((answer: any, key) => (
                                    <Grid item xs={2.4}>
                                        <div onClick={() => props.changeMode(readings.length + key)} 
                                        className={`pointer pa-sm flex justify-center align-items bold border-1px ${answer.answer !== '0' ? answer.answer === answer.question.answer ? 'bg-success' : 'bg-danger' : ''} `}>
                                            {answer.question.question_number}
                                        </div>
                                    </Grid>
                                )) :
                                    <EmptyBox message={'No Listening Questions'} />}
                            </Grid>
                        </Box>
                    </Grid>

                </Grid>


            </Stack>
        </>
    );
};

export default ResultNumberLists;
