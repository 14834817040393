import * as actionType from './loadingType';

export const setSuccess = (success?: boolean) => {
  return {
    type: actionType.SET_SUCCESS,
    payload: success,
  };
};
export const setLoading = (mode?: string) => {
  return {
    type: actionType.SET_LOADING,
    mode: mode,
  };
};

export const clearLoading = () => {
  return {
    type: actionType.CLEAR_LOADING,
  };
};
