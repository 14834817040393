import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './store/ResultsActions';
import { useHistory, useParams } from "react-router-dom";


const Results = ({ userId }: any) => {
    const resultList = useSelector((state: any) => {
        return state.results.results;
    });


    const history = useHistory()
    const { examId } = useParams<{ examId: string }>();

    const dispatch = useDispatch();
    const [results, setResults] = useState([]);

    useEffect(() => {
        dispatch(actions.getResultSuccess([]))
        examId && loadResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examId]);

    useEffect(() => {
        console.log(resultList);

        setResults(resultList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultList])

    const loadResults = async () => {
        await dispatch(actions.getResultsFromExam(examId));
    }

    

    const viewDetailsResult = async (res: any) => {
        history.push(`results/${res._id}`)
    }
    const goBack = async () => {
        history.goBack();
    }

    const columns = [
        {
            name: 'title',
            align: 'left',
            label: 'Title',
            field: 'exam.title',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }
        , {
            name: 'User Name',
            align: 'center',
            label: 'UserName',
            field: 'user.email',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
        , {
            name: 'Score',
            align: 'center',
            label: 'Score',
            field: 'score',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
        , {
            name: 'Exam Date',
            align: 'center',
            label: 'Date',
            field: 'createdAt',
            flexVal: 1,
            sortable: true,
            date: true,
            boxed: true
        }
    ]

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            {examId && <div>
                <button className="btn primary" onClick={goBack}>Back</button>
            </div>}
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">List of Results</div>
            </header>

            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={results}
                    paginate={50}
                    viewAction={viewDetailsResult}
                />
            </div>
            {/* {
                modalMode === 'add' ?
                    <Modal show={showModal} title={'Add Exam'}
                        closeModal={() => setShowModal(false)}>
                        <AddExam closeModal={() => setShowModal(false)} mode={modalMode} />
                    </Modal> :
                    <Modal show={showModal} title={'Edit User'}
                        closeModal={() => setShowModal(false)}>
                        <EditExam
                            editObj={editExamObj}
                            closeModal={() => setShowModal(false)}
                            editExam={editExam}
                            mode={modalMode}
                        />
                    </Modal>
            } */}
        </section>
    )
}

export default Results;
