import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import ProfileDefaultImg from '../../../../assets/default.png';
import { useSelector } from 'react-redux';
import { UserType } from '../../../../common/enum/UserType.enum';

import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import logo from '../../../../assets/korenvasalogo.png';


const ExamNav = () => {
    const history = useHistory();
    const [role, setRole] = useState('');
    const [titleName, setTitleName] = useState('')

    const examRunning: boolean = useSelector((state: any) => {
        return state.userReducer.examRunning;
    });

    const goToHome = () => {
        !examRunning && history.push('/')
    };

    const defaultTitle = 'EPS-TOPIK UBT TRAIL EXAM'
    const user: any = useSelector((state: any) => state.auth.user);
    const institute: any = useSelector((state: any) => state.auth.institute);


    useEffect(() => {
        if (user && user.role !== undefined) {
            if (user.role === UserType.USER) {
                setRole('Student');
                setTitleName(institute?.name || defaultTitle)
            } else if (user.role === UserType.INSTITUTE) {
                setTitleName(user.name || defaultTitle)
                setRole('Institute');
            } else if (user.role === UserType.ADMIN) {
                setTitleName(defaultTitle)
                setRole('Admin');
            }
        }
    }, [user, institute])
    return (
        <nav className={'px-sm top-nav  bg-primary'}>
            <div className='flex items-center justify-between'>

                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={goToHome}
                >
                    <Avatar
                        alt={'logo'}
                        src={logo}
                    />
                </IconButton>

                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }} color="white">
                    {titleName}
                </Typography>
                <Stack direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center">
                    <Stack direction="column" alignItems="flex-end">
                        <Typography noWrap component="div" sx={{ fontWeight: 'bold' }} color="white">
                            {user.name}
                        </Typography>
                        <Typography noWrap component="div" color="white">
                            {role}
                        </Typography>
                    </Stack>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <Avatar
                            alt={user?.name}
                            src={user?.image ? `${process.env.REACT_APP_API_BASE_URL}${user.image}` : ProfileDefaultImg}
                        />
                    </IconButton>

                </Stack>
            </div>
        </nav >
    );
}

export default ExamNav;
