import React, { useState } from 'react';
import * as actions from '../store/UsersActions';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import AddusersRules from './AddusersRules';
import { useDispatch } from 'react-redux';
interface ISignup {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    confirmPassword: string;
    phone: string;
}
const initialValues: ISignup = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
};

interface IAddUser {
    closeModal: Function,
    mode: string
}

const AddUsers = (props: IAddUser) => {
    // const dispatch = useDispatch();
    const [errors, setErrors] = useState(initialValues);
    const [user, setUser] = useState(initialValues);
    const dispatch = useDispatch()


    const handelFormSubmit = async (e: any) => {
        e.preventDefault();
        let errorMsgs: any = ValidateForm(user, AddusersRules);
        if (user.password !== user.confirmPassword) {
            setErrors({
                ...errors,
                [e.target.name]: errorMsgs,
                confirmPassword: 'Confirm Password doesnot match'
            });
        } else {
            setErrors({ ...errorMsgs });
        }
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(actions.addUser(user));
            props.closeModal();

        }
    };
    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, AddusersRules);

        if (user.password !== user.confirmPassword) {
            setErrors({
                ...errors,
                [e.target.name]: errorMsg,
                confirmPassword: 'Confirm Password doesnot match'
            });
        } else {
            setErrors({
                ...errors,
                [e.target.name]: errorMsg,
                confirmPassword: ''
            });
        }
    };
    const inputHandler = (e: any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area">
                <form onSubmit={handelFormSubmit}>
                    <div style={{ maxHeight: "70vh" }} className="form-area my-lg auto-scroll">
                        <div className="form-group my-sm">
                            <label className={'bold text-primary'}>First Name</label>
                            <input
                                type="text"
                                name={'firstname'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Name'}
                            />
                            {errors.firstname !== '' ? (
                                <span className="error-text">{errors.firstname}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Last name</label>
                            <input
                                type="text"
                                name={'lastname'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Name'}
                            />
                            {errors.lastname !== '' ? (
                                <span className="error-text">{errors.lastname}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Email</label>
                            <input
                                type="email"
                                name={'email'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter email'}
                            />
                            {errors.email !== '' ? (
                                <span className="error-text">{errors.email}</span>
                            ) : (
                                    ''
                                )}
                        </div>

                        <div className="form-group mb-lg">
                            <label className={'bold text-primary'}>PhoneNo</label>
                            <input
                                type="number"
                                name={'phone'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Phone no'}
                            />
                            {errors.phone !== '' ? (
                                <span className="error-text">{errors.phone}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-lg">
                            <label className={'bold text-primary'}>Password</label>
                            <input
                                type="password"
                                name={'password'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter password'}
                            />
                            {errors.password !== '' ? (
                                <span className="error-text">{errors.password}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-lg">
                            <label className={'bold text-primary'}>Confirm password</label>
                            <input
                                type="password"
                                name={'confirmPassword'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Confirm password'}
                            />
                            {errors.confirmPassword !== '' ? (
                                <span className="error-text">{errors.confirmPassword}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                    </div>
                    <button className="btn primary full-width" type={'submit'}>Add New User</button>
                </form>
            </div>
        </div>
    );
};
export default AddUsers;
