import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../services/ExamAccessActions'
interface IAddExam {
    closeModal: Function,
    mode: string,
    examId: string
}
interface IAddAccess {
    institute: string
}

const initialState: IAddAccess = {
    institute: '',
}

const AddAccess = (props: IAddExam) => {
    const [access, setAccess] = useState(initialState)
    const [error, setError] = useState(initialState)
    const instituteList = useSelector((state: any) => {
        return state.adminReducer.institutes;
    });
    const dispatch = useDispatch();
    const inputHandler = (e: any) => {
        setError({
            ...error,
            [e.target.name]: ''
        })
        setAccess({
            ...access,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        instituteList.length > 0 && setAccess({
            ...access,
            institute: instituteList[0]._id
        })
        // eslint-disable-next-line
    }, [instituteList])

    const confirmAction = async () => {
        if (access.institute !== '') {
            await dispatch(action.addAccessToInstitute(props.examId, access));
            props.closeModal();
        } else {
            setError({
                ...error,
                institute: "Cannot be Empty"
            })
        }
    }

    const inputValidation = (e: any) => {
        // let errorMsg = ValidateInput(e.target.name, e.target.value, ExamRules);
        // setError({
        //     ...error,
        //     [e.target.name]: errorMsg
        // });
    }


    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex">
                <div className="form-area my-md full-width">
                    <div className="form-group my-md full-width">
                        <label>Institutes</label>
                        <select name="institute"
                            value={access.institute}
                            onChange={inputHandler}
                            onBlur={inputValidation}>
                            {instituteList && instituteList.map((institute: any) => <option key={institute._id} value={institute._id}>{institute.firstname} {institute.lastname}</option>)}
                        </select>
                        {error.institute !== '' ? <span className="error-text">{error.institute}</span> : ''}
                    </div>
                </div>
            </div>
            <div className="button-area flex justify-end mtyar-lg">
                <button className="btn primary" onClick={confirmAction}>Add Institute</button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default AddAccess
