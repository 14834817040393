import * as actionTypes from './ExamsTypes';
interface IExams {
  exams: [];
  loading: Boolean;
}
const initailState: IExams = {
  exams: [],
  loading: false,
};
// eslint-disable-next-line
export default (state: IExams = initailState, actions: any) => {
  switch (actions?.type) {
    case actionTypes.GET_EXAMS_LIST:
      return {
        ...state,
        exams: actions.payload,
      };
    case actionTypes.GET_EXAMS_LOADING:
      return {
        ...state,
        loading: actions.payload,
      };
    default:
      return state;
  }
};
