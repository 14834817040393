import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './services/ExamAccessActions';
import { useHistory, useParams } from "react-router-dom";
import Modal from '../../../../common/modal';
import AddAccess from './components/AddAccess';
import Confirmation from '../../../../common/modal/Confirmation';

const ExamAccess = () => {
    const examAccess = useSelector((state: any) => {
        return state.adminReducer.examAccess;
    });
    const history = useHistory();
    const { examId } = useParams<{ examId: string }>();
    const [modalMode, setModalMode] = useState('add');
    const [showModal, setShowModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const dispatch = useDispatch();
    const [institutes, setInstitutes] = useState([]);
    const [activeObj, setActiveObj] = useState({})

    useEffect(() => {
        dispatch(actions.getExamAccessAdminSuccess([]))
        examId && loadExamAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examId]);

    useEffect(() => {
        setInstitutes(examAccess);
    }, [examAccess])

    const loadExamAccess = async () => {
        await dispatch(actions.getAdminExamAccess(examId));
    }

    const addAccess = async () => {
        // await dispatch(actions.getAdminExamAccess(examId));
        setShowModal(true);
        setModalMode('add')
    }

    const deleteAction = async (payload: any) => {
        setActiveObj(payload)
        setShowConfirmation(true)
    }
    const deleteConfirmation = async () => {
        await dispatch(actions.removeInstituteFromAccess(examId, activeObj));
        setShowConfirmation(false)
    }
    const goBack = async () => {
        history.goBack();
    }

    const columns = [
        {
            name: 'name',
            align: 'left',
            label: 'Name',
            field: ['firstname', 'lastname'],
            flexVal: 3,
            multi: true,
            sortable: true,
            splice: 5,
        }
        , {
            name: 'email',
            align: 'center',
            label: 'Email',
            field: 'email',
            flexVal: 3,
            sortable: true,
            boxed: true
        }
    ]

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <div>
                <button className="btn primary" onClick={goBack}>Back</button>
            </div>
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">Who can see this exam</div>
                <button className="btn primary" onClick={addAccess}>Add Institute</button>
            </header>

            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={institutes}
                    paginate={10}
                    deleteAction={deleteAction}
                />
            </div>
            {
                modalMode === 'add' &&
                <Modal show={showModal} title={'Add Exam'}
                    closeModal={() => setShowModal(false)}>
                    <AddAccess closeModal={() => setShowModal(false)} mode={modalMode} examId={examId} />
                </Modal>
            }
            <Confirmation title="Confirm" content="Are you sure you want to remove this institute?" show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </section>
    )
}

export default ExamAccess;
