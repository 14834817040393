import React from 'react'
import { Box, Container, Paper, Stack } from '@mui/material'

const rules = [
    "Make sure to connect to stable internet",
    `If you want to enroll to institute. Then contact institutes or call +977 ${process.env.REACT_APP_CONTACT_NUMBER}`,
    `If you face problem while attending exam, contact institute or call +977 ${process.env.REACT_APP_CONTACT_NUMBER}`,
    `If you want access to exams which are private then contact institute or call +977 ${process.env.REACT_APP_CONTACT_NUMBER}`,
]

export default function Contact() {
    return (
        <Container >
            <Stack spacing={2} >

                <Paper elevation={3} variant="outlined">
                    <Box sx={{ padding: 3 }}>
                        <Stack justifyContent="center" alignItems="center" spacing={1} >
                            <div className="title-sm bold text-primary">Contact Us</div>
                            <span>If you face problem or have any confusion regarding Eps KoreanVasa Exam Website. Follow below instructions</span>
                        </Stack>
                    </Box>
                </Paper>
                <Paper elevation={3} variant="outlined">
                    <Box sx={{ padding: 2 }}>
                        <Stack spacing={1} >
                            <div>****NOTE****</div>
                            {rules.map((rule: string, i: number) =>
                                <li key={i} className="list-decoration">{rule}</li>
                            )}
                        </Stack>
                    </Box>
                </Paper>

            </Stack>
        </Container>
    )
}
