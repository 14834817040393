import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../store/ExamsActions'
import Modal from '../../../../../common/modal';
import Confirmation from '../../../../../common/modal/Confirmation';
import AddExam from './AddExam'
import EditExam from './EditExam'
import { Stack, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import ExamListType from './ExamListType';
interface IExamList {
  viewExam: Function
}

export default function ExamList(props: IExamList) {
  
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [editExamObj, setEditExamObj] = useState({ _id: "" });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalMode, setModalMode] = useState('add');

  const [tabValue, setTabValue] = useState('ubt')

  useEffect(() => {
    user._id && loadExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadExams = async () => {
    await dispatch(actions.getExams());
  }

  const deleteAction = (obj: any) => {
    setShowConfirmation(true);
    setEditExamObj(obj)
  }

  const deleteConfirmation = async () => {
    dispatch(actions.deleteExam(editExamObj._id));
    setShowConfirmation(false);
  }

  const addAction = () => {
    setModalMode('add');
    setShowModal(true);
  }

  const editExam = async (payload: any) => {
    await dispatch(actions.editExam(editExamObj._id, payload));
  }

  const editAction = (obj: any) => {
    setModalMode('edit');
    setEditExamObj(obj);
    setShowModal(true);
  }
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <header className={'flex justify-between items-center mb-sm'}>
        <div className="my-md title-sm bold text-primary">Exam List</div>
        <button className='btn primary' onClick={addAction}>
          Add Exams
        </button>
      </header>
      <Stack direction={'column'} spacing={1} >

        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label={'UBT EXAM'} value={'ubt'} />
          <Tab label={'CBT EXAM'} value={'cbt'} />
        </Tabs>

        <ExamListType
          deleteAction={deleteAction}
          editAction={editAction}
          type={tabValue}
          viewExam={props.viewExam}
        />
      </Stack>

      {modalMode === 'add' ? (
        <Modal
          show={showModal}
          title={'Add Exam'}
          closeModal={() => setShowModal(false)}
        >
          <AddExam user={user} closeModal={() => setShowModal(false)} mode={modalMode} />
        </Modal>
      ) : (
        <Modal
          show={showModal}
          title={'Edit User'}
          closeModal={() => setShowModal(false)}
        >
          <EditExam
            editObj={editExamObj}
            closeModal={() => setShowModal(false)}
            editExam={editExam}
            mode={modalMode}
          />
        </Modal>
      )}
      <Confirmation
        title='Delete Exam'
        content='Are you sure you want to delete this exam?'
        show={showConfirmation}
        confirm={deleteConfirmation}
        closeModal={() => setShowConfirmation(false)}
      />
    </Box>
  );
}
