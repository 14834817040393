import React, { useEffect, useState } from 'react';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import InstituteRules from './instituteRules';
import * as actions from '../../../store/AdminActions';
import { useDispatch } from 'react-redux';
interface IInstitute {
    name: string,
    address: string,
    phone: string,
    institute_role: string,
    email: string,
    password: string,
    confirm_password: string,

}
const initialValues: IInstitute = {
    name: '',
    address: '',
    phone: '',
    institute_role: '',
    email: '',
    password: '',
    confirm_password: ''
};

interface IAddUser {
    closeModal: Function,
    mode: string
}

const AddInstitute = (props: IAddUser) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(initialValues);
    const [institute, setInstitute] = useState(initialValues);

    useEffect(() => {
        setInstitute({
            ...institute,
            institute_role: '0'
        })
        // eslint-disable-next-line
    }, [])
    const handelFormSubmit = (e: any) => {
        e.preventDefault();
        let errorMsgs: any = ValidateForm(institute, InstituteRules);
        if (institute.password !== institute.confirm_password) {
            setErrors({
                ...errors,
                [e.target.name]: errorMsgs,
                confirm_password: 'Confirm Password doesnot match'
            });
        } else {
            setErrors({ ...errorMsgs });
        }
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            dispatch(actions.adminAddInstitute(institute));
            props.closeModal()
        }
    };
    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, InstituteRules);
        if (institute.password !== institute.confirm_password) {
            setErrors({
                ...errors,
                [e.target.name]: errorMsg,
                confirm_password: 'Confirm Password doesnot match'
            });
        } else {
            setErrors({
                ...errors,
                [e.target.name]: errorMsg,
                confirm_password: ''
            });
        }
    };
    const inputHandler = (e: any) => {
        setInstitute({
            ...institute,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area">
                <form onSubmit={handelFormSubmit}>
                    <div className="form-area my-lg">
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Institute Name</label>
                            <input
                                type="text"
                                name={'name'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Institute Name'}
                            />

                            {errors.name !== '' ? (
                                <span className="error-text">{errors.name}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Email</label>
                            <input
                                type="email"
                                name={'email'}
                                onBlur={inputValidation}
                                value={institute.email}
                                onChange={inputHandler}
                                placeholder={'Enter Institute Email'}
                            />
                            {errors.email !== '' ? (
                                <span className="error-text">{errors.email}</span>
                            ) : (
                                    ''
                                )}
                        </div>
                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Phone Number</label>
                            <input
                                type="number"
                                name={'phone'}
                                onBlur={inputValidation}
                                value={institute.phone}
                                onChange={inputHandler}
                                placeholder={'Enter Institute Phone'}
                            />
                            {errors.phone !== '' ? (
                                <span className="error-text">{errors.phone}</span>
                            ) : (
                                    ''
                                )}
                        </div>

                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Institute Address</label>

                            <input
                                type="text"
                                name={'address'}
                                onBlur={inputValidation}
                                value={institute.address}
                                onChange={inputHandler}
                                placeholder={'Enter Institute Address'}
                            />

                            {errors.address !== '' ? (
                                <span className="error-text">{errors.address}</span>
                            ) : (
                                    ''
                                )}
                        </div>

                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Password</label>

                            <input
                                type="password"
                                name={'password'}
                                onBlur={inputValidation}
                                value={institute.password}
                                onChange={inputHandler}
                                placeholder={'Enter Password'}
                            />

                            {errors.password !== '' ? (
                                <span className="error-text">{errors.password}</span>
                            ) : (
                                    ''
                                )}
                        </div>

                        <div className="form-group mb-sm">
                            <label className={'bold text-primary'}>Confirm Password</label>

                            <input
                                type="password"
                                name={'confirm_password'}
                                onBlur={inputValidation}
                                value={institute.confirm_password}
                                onChange={inputHandler}
                                placeholder={'Enter Password'}
                            />

                            {errors.confirm_password !== '' ? (
                                <span className="error-text">{errors.confirm_password}</span>
                            ) : (
                                    ''
                                )}
                        </div>

                        <div className="form-group mb-lg">
                            <label className={'bold text-primary'}>Role</label>
                            <select name="institute_role"
                                onBlur={inputValidation}
                                onChange={inputHandler}>
                                <option value={'0'}>Cannot Add Exam</option>
                                <option value={'1'}>Can Add Exam</option>
                            </select>

                            {errors.institute_role !== '' ? (
                                <span className="error-text">{errors.institute_role}</span>
                            ) : (
                                    ''
                                )}
                        </div>

                    </div>
                    <button className="btn primary full-width" type={'submit'}>Create Institute</button>
                </form>
            </div>
        </div>
    );
};
export default AddInstitute;
