import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import {
  GetRequest,
  PostRequest,
  PutRequest,
} from '../../../../../plugins/axios';
import { GET_FILTER, GET_RESULT_ID } from '../../../store/UserActionTypes';

const questionsUrl = process.env.REACT_APP_API_BASE_URL + 'questions';
const resultUrl = process.env.REACT_APP_API_BASE_URL + 'results';
const reportUrl = process.env.REACT_APP_API_BASE_URL + 'reports';
export const getQuestionsSuccess = (payload: any) => {
  return {
    type: 'GET_QUESTIONS',
    payload: payload,
  };
};

export const setScreenLandscape = (payload: boolean) => {
  return {
    type: 'GET_SCREEN_LANDSCAPE',
    payload: payload,
  };
};

export const setAnswers = (payload: any) => {
  return {
    type: 'SET_ANSWERS',
    payload: payload,
  };
};

export const setQuestionPosition = (payload: number) => {
  return {
    type: 'SET_QUESTION_POSITION',
    payload: payload,
  };
};
export const setQuestionType = (payload: string) => {
  return {
    type: 'SET_QUESTION_TYPE',
    payload: payload,
  };
};
export const setExamRunning = (payload: boolean) => {
  return {
    type: 'SET_EXAM_RUNNING',
    payload: payload,
  };
};

export const setListeningPlayId = (payload: string) => {
  return {
    type: 'GET_LISTENING_PLAY_ID',
    payload: payload,
  };
};
export const setStopAudio = (payload: boolean) => {
  return {
    type: 'SET_STOP_AUDIO',
    payload: payload,
  };
};
export const setListeningStatus = (payload: any) => {
  return {
    type: 'GET_LISTENING_STATUS',
    payload: payload,
  };
};

export const setReportSuccess = (payload: string) => {
  return {
    type: 'GET_REPORT_ID',
    payload: payload,
  };
};

export const setFilter = (payload: String) => {
  return {
    type: GET_FILTER,
    payload: payload,
  };
};

export const setResultId = (payload: String) => {
  return {
    type: GET_RESULT_ID,
    payload: payload,
  };
};

export const uploadAnswer =
  (examId: string, payload: any) => (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    if (userID) {
      PostRequest(`${resultUrl}/create/${examId}/${userID}`, payload, {})
        .then((res) => {
          console.log(res.data.resultId);
          dispatch(setResultId(res.data.resultId));
          dispatch(updateReportStatus(res.data.resultId));
          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Success',
              message: 'Result Saved Sucessfully',
            })
          );
        })
        .catch((err) => {
          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Fetch Error',
              message: 'Error cannot save result',
            })
          );
        });
    }
  };

export const updateReportStatus = (resultId: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  const reportID = store.getState().userReducer.report;

  if (userID && reportID) {
    PutRequest(
      `${reportUrl}/update/${reportID}/${userID}`,
      { result: resultId },
      {}
    )
      .then((res) => {
        dispatch(setReportSuccess(''));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};
export const getQuestions = (examId: string) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    GetRequest(`${questionsUrl}/${examId}/${userID}`, {}, {})
      .then((res) => {
        dispatch(getQuestionsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Questions',
          })
        );
      });
  }
};
