import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import UserRules from '../../../../common/rules/userRules';
import * as actions from '../../../../../store/action';
import Modal from '../../../../../common/modal';
import ChangePassword from './ChangePassword';
// import { User } from '../../../../common/model/User';

interface PUser {
    _id: '',
    email: '',
    name: '',
    role: '',
    auth_type: string,
}
const initialState: PUser = {
    _id: '',
    email: '',
    name: '',
    role: '',
    auth_type: '',
}

const ClientDetails = (props: any) => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(initialState);
    const [localUser, setLocalUser] = useState(initialState);
    const [edit, setEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errors, setErrors] = useState(initialState);

    const inputHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: ''
        })
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        setUser(props.user);
        setLocalUser(props.user);
    }, [props.user])

    const confirmAction = async () => {
        let errorMsgs: any = ValidateForm(user, UserRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            editUser(user);   
            toggleEditForm();
        }
    }

    const toggleEditForm = () => {
        setEdit((edit) => !edit);
    }

    const cancelAction = () => {
        setUser({ ...localUser });
        toggleEditForm();
        setErrors(initialState);
    }

    const editUser = async (payload: any) => {
        await dispatch(actions.editOwnDetails(payload));
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, UserRules);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg
        });
    }

    return (
        <div className="edit-client">
            <div className="dialog-content-area">
                <div className="form-group my-lg">
                    <label>Email</label>
                    <input
                        type="email"
                        name={'email'}
                        onBlur={inputValidation}
                        disabled={true}
                        value={user?.email || ''}
                    />
                    {errors.email !== '' ? <span className="error-text">{errors.email}</span> : ''}
                </div>
                {
                    user?.auth_type !== "Google" && <div className="my-sm">
                        <span className="link" onClick={() => setShowModal(true)}>Change Password</span>
                    </div>
                }

                <div className="form-group my-md">
                    <label>Name</label>
                    <input
                        type="text"
                        name={'name'}
                        onBlur={inputValidation}
                        disabled={!edit}
                        value={user?.name}
                        onChange={inputHandler}
                    />
                    {errors.name !== '' ? <span className="error-text">{errors.name}</span> : ''}
                </div>
                <div className="form-group my-md">
                    <label>User Role</label>
                    <select
                        name="role"
                        value={user?.role || ''}
                        onBlur={inputValidation}
                        disabled={true}
                    >
                        <option disabled>Select Option</option>
                        <option value='0'>User</option>
                        <option value='1'>Institute</option>
                        <option value='2'>Admin</option>
                    </select>
                    {errors.role !== '' ? <span className="error-text">{errors.role}</span> : ''}
                </div>
            </div>
            <div className="button-area flex justify-end mt-lg">
                {edit ? (<>
                    <button className="btn primary" onClick={confirmAction}>Confirm</button>
                    <button className="btn primary outlined ml-xs" onClick={cancelAction}>Cancel</button>
                </>) : <button className="btn primary" onClick={toggleEditForm}>Edit</button>}
            </div>

            <Modal show={showModal} title={'Change Password'}
                closeModal={() => setShowModal(false)}>
                <ChangePassword
                    email={user.email + ""}
                    closeModal={() => setShowModal(false)}
                />
            </Modal>
        </div>
    )
}

export default ClientDetails;