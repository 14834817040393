import { Reducer, combineReducers } from 'redux';
import modalReducer from '../common/modal/services/modalReducer';
import loadingReducer from '../common/loading/services/loadingReducer';
import toasterReducer from '../common/toaster/services/toasterReducer';
import AdminReducer from '../modules/admin/store/AdminReducers';
import AuthReducers from '../modules/auth/store/AuthReducers';
import UserReducer from '../modules/student/store/UserReducers';
import InstituteReducers from '../modules/Institute/store/InstituteReducers';
import ExamsReducers from '../modules/common/views/examlist/store/ExamsReducers';
import QuestionsReducers from '../modules/common/views/questions/store/QuestionsReducers';
import ResultsReducers from '../modules/common/views/Results/store/ResultsReducers';
import UsersReducers from '../modules/common/views/usermanagement/store/UsersReducers';
import CategoryReducers from '../modules/common/views/CategoryList/store/CategoryReducers';

const rootReducer: Reducer = combineReducers({
  modal: modalReducer,
  loading: loadingReducer,
  toaster: toasterReducer,
  auth: AuthReducers,
  exams: ExamsReducers,
  questions: QuestionsReducers,
  results: ResultsReducers,
  userReducer: UserReducer,
  users: UsersReducers,
  instituteReducer: InstituteReducers,
  adminReducer: AdminReducer,
  category: CategoryReducers,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
