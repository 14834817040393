import React, { useEffect, useState } from 'react';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import { Question } from '../../../../common/model/Question';
import * as action from '../store/QuestionsActions';
import PreviewQuestionAdd from './PreviewQuestionAdd';
import QuestionRulesReading from './questionRulesReading';
import QuestionRuleListening from './questionRuleListening';
import questionType from '../../../../common/enum/questiontype';
import optionType from '../../../../common/enum/optionType';
import { useDispatch, useSelector } from 'react-redux';
import { getActionsCompleted } from '../store/QuestionsActions'
import { Button, DialogActions, DialogContent, Divider, Stack } from '@mui/material';
import AddQuestionForm from './AddQuestionForm';
import { isJsonString } from '../../../../../utils/utils';
import { LoadingButton } from '@mui/lab';

interface IEditQuestion {
    editObj: any,
    mode: string,
    questionNumberList: number[],
    closeModal: Function,
    editQuestion: Function,
}

const initialState: Question = {
    main_heading: '',
    heading: '',
    description: '',
    description_boxed: false,
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    listening: '',
    answer: '',
    answer_details: '',
}

const EditQuestion = (props: IEditQuestion) => {
    const [question, setQuestion] = useState(initialState);
    const [errors, setErrors] = useState(initialState);

    const uploadFile = useSelector((state: any) => {
        return state.questions.uploadFile;
    });

    const [acceptedQnoList, setAcceptedQnoList] = useState<number[]>([])
    const [submit, setSubmit] = useState(false)

    const loading = useSelector((state: any) => state.questions.loading)
    const success = useSelector((state: any) => state.questions.success)
    const dispatch = useDispatch()

    useEffect(() => {
        if (Object.entries(uploadFile).length !== 0) {
            setQuestion(
                {
                    ...question,
                    [uploadFile.field_name]: uploadFile.url
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadFile])

    useEffect(() => {
        let acceptedList = []
        if (props.questionNumberList && props.editObj.type) {
            if (props.editObj.type === questionType.READING) {
                for (let start = 1; start <= 20; start++) {
                    if (!props.questionNumberList.includes(start) || props.editObj.question_number === start) {
                        acceptedList.push(start)
                    }
                }
            } else {
                for (let start = 21; start <= 40; start++) {
                    if (!props.questionNumberList.includes(start) || props.editObj.question_number === start) {
                        acceptedList.push(start)
                    }
                }
            }
            // acceptedList.push(props.editObj.question_number)
        }
        setAcceptedQnoList(acceptedList)
    }, [props.questionNumberList, props.editObj])

    useEffect(() => {
        dispatch(getActionsCompleted(false))
        if (props.mode === 'edit') {
            if (props.editObj.option_type === optionType.LISTENING_AND_TEXT) {
                const parsedoption1 = isJsonString(props.editObj.option1);
                const parsedoption2 = isJsonString(props.editObj.option2);
                const parsedoption3 = isJsonString(props.editObj.option3);
                const parsedoption4 = isJsonString(props.editObj.option4);
                setQuestion({
                    ...question,
                    ...props.editObj,
                    option1: parsedoption1?.text || '',
                    option2: parsedoption2?.text || '',
                    option3: parsedoption3?.text || '',
                    option4: parsedoption4?.text || '',
                    option1_listening: parsedoption1?.listening || '',
                    option2_listening: parsedoption2?.listening || '',
                    option3_listening: parsedoption3?.listening || '',
                    option4_listening: parsedoption4?.listening || ''
                })
            } else {
                setQuestion(props.editObj)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editObj]);

    const inputHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: '',
        });

        if (e.target.name === "option_type" && question.option_type === optionType.LISTENING_AND_TEXT && e.target.value === optionType.LISTENING) {
            setQuestion({
                ...question,
                [e.target.name]: e.target.value,
                option1: question.option1_listening,
                option2: question.option2_listening,
                option3: question.option3_listening,
                option4: question.option4_listening,
            });
        } else {
            setQuestion({
                ...question,
                [e.target.name]: e.target.value,
            });
        }
    };

    const confirmAction = async () => {
        let errorMsgs: any = ValidateForm(question, question.type === questionType.READING ? QuestionRulesReading : typeof question?.listening === "string" ? QuestionRulesReading : QuestionRuleListening);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await props.editQuestion(question);
            setSubmit(true)
        }
    }

    useEffect(() => {
        if (success && submit) {
            props.closeModal();
            dispatch(getActionsCompleted(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, submit])

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name,
            e.target.name === "listening" ? e.target.files[0] : e.target.value,
            QuestionRulesReading);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg,
        });
    };

    const inputFileHandler = (e: any) => {

        console.log(e.target.name);

        if (e.target.name.endsWith('listening')) {
            const names = e.target.name.split('_listening')
            console.log(names);
            const name = names[0]
            setErrors({
                ...errors,
                [name]: '',
            });
        } else {
            setErrors({
                ...errors,
                [e.target.name]: '',
            });
        }

        let name = e.target.name
        if (question.option_type === optionType.LISTENING) {
            const names = e.target.name.split('_listening')
            console.log(names);
            name = names[0]
        }

        const payload = {
            file: e.target.files[0],
            name: name
        }
        console.log(payload);

        dispatch(action.uploadFile(question?.exam || '', payload))
    };

    const toggleImage = (e: any) => {
        setQuestion({
            ...question,
            [e.target.name]: e.target.checked
        });
    }

    return (
        <>
            <DialogContent dividers>
                <Stack direction={"row"} spacing={2}>
                    <AddQuestionForm question={question}
                        errors={errors}
                        inputValidation={inputValidation}
                        inputHandler={inputHandler}
                        toggleImage={toggleImage}
                        acceptedQnoList={acceptedQnoList}
                        inputImageHandler={inputFileHandler}
                    />
                    <Divider orientation="vertical" flexItem />
                    <PreviewQuestionAdd
                        question={question} />
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => props.closeModal()}>
                    Cancel
                </Button>
                <LoadingButton
                    size='large'
                    type='submit'
                    variant='contained'
                    loading={loading}
                    onClick={confirmAction}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </>
    )
}

export default EditQuestion
