// eslint-disable-next-line
export default {
  firstname: {
    required: true,
    minValue: 3,
    label: 'First name',
  },
  lastname: {
    required: true,
    minValue: 3,
    label: 'Last name',
  },
  email: {
    required: true,
    isEmail: true,
    label: 'Email',
  },
  password: {
    required: true,
    minValue: 8,
    label: 'Password',
  },
  confirmPassword: {
    required: true,
    minValue: 8,
    label: 'Confirm password',
  },
};
