import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ValidateForm, ValidateInput } from '../../../utils/customHooks/validateForm';
import LoginRules from '../LoginRules';
import * as actions from '../store/AuthActions';
import icon from '../../../assets/korenvasalogo.png';
import { UserType } from '../../../common/enum/UserType.enum';
import Loading from '../../../common/loading';
import '../../../assets/scss/style.scss'
import '../../../assets/scss/layout.scss'
import '../../../assets/scss/vars.scss'

interface ILogin {
    email: string,
    password: string
}

const initialValues: ILogin = {
    email: '',
    password: '',
};
const Login = (props: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(initialValues);
    const [user, setUser] = useState(initialValues);
    const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
    const loading = useSelector((state: any) => state.auth.loading);
    const currentUser = useSelector((state: any) => state.auth.user);
    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, LoginRules);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg
        });
    }
    const inputHandler = (e: any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        if (isAuthenticated && currentUser) {
            if (currentUser.role === UserType.USER) {
                history.push('/student');
            } else if (currentUser.role === UserType.INSTITUTE) {
                history.push('/institute');
            } else if (currentUser.role === UserType.ADMIN) {
                history.push('/admin');
            }
        }
        // eslint-disable-next-line
    }, [isAuthenticated])
    const authenticateUser = async (e: FormEvent) => {
        e.preventDefault();
        let errorMsgs: any = ValidateForm(user, LoginRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(actions.authenticate({ ...user }))
        }
    }
    return (
        <section className="login full-vh full-width flex-centered bg-primary">
            <form className="flex card bg-light column justify-between ma-sm" onSubmit={(e) => authenticateUser(e)}>

                <div className="title-sm bold text-primary text-center mb-md">{process.env.REACT_APP_APP_NAME}</div>
                <div className="flex justify-center">
                    <div className="simplx-logo flex justify-center">
                        <img src={icon} alt={'logo'} />
                    </div>
                </div>
                <div className="form-area my-md">
                    <div className="form-group mb-sm">
                        <label className={'bold text-primary'}>Email</label>
                        <div className="input-area flex items-center">
                            <input type="email"
                                name={"email"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter email or username'} />
                        </div>
                        {errors.email !== '' ? <span className="error-text">{errors.email}</span> : ''}
                    </div>
                    <div className="form-group mb-md">
                        <label className={'bold text-primary'}>Password</label>
                        <div className="input-area flex items-center">
                            <input type="password"
                                name={"password"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter password'} />
                        </div>
                        {errors.password !== '' ? <span className="error-text">{errors.password}</span> : ''}
                    </div>
                </div>
                <button className="btn primary full-width mb-sm" type={'submit'} disabled={loading}>
                    {!loading ? <span>Login</span> : <Loading isLoading={true} />}
                </button>

                <div className="flex column justify-center items-center full-width">
                    <label className="contact-text text-primary bold pointer mb-sm text-center">Phone No: {process.env.REACT_APP_CONTACT_NUMBER}</label>
                    <label className="contact-text">
                        Facebook :
                        <a className="link bold"
                            href={process.env.REACT_APP_FB_URL} target="_blank" rel="noreferrer"> {process.env.REACT_APP_OWNER_NAME} </a>
                    </label>
                    <label className="contact-text text-center">पहिलो पटक परिक्षा दिन​ खोज्दै हुनुहुन्छ भने </label>
                    <label className="contact-text text-center">Username / password को लागि माथिको सम्पर्क  नम्बर </label>
                    <label className="contact-text">मा सम्पर्क​ गर्नुहोला ।</label>
                </div>

            </form>
        </section>
    )
}

export default Login;