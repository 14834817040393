import { useEffect, useState } from 'react';
import questionType from '../enum/questiontype';
import { Question } from '../model/Question';
interface IQuestionSummary {
    questions: [],
    closeModal: Function
}

const QuestionSummary = (props: IQuestionSummary) => {
    const [listenings, setListening] = useState<Question[]>([])
    const [readings, setReadings] = useState<Question[]>([])

    useEffect(() => {
        setListening(props.questions.filter((q: Question) => q.type === questionType.LISTENING))
        setReadings(props.questions.filter((q: Question) => q.type === questionType.READING))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.questions])


    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex">
                <div className="question-list">
                    <div className="questions-container">
                        Reading Questions
                <div>
                            {readings.map((q, key) => (
                                <div className={`text-light pointer bg-primary`}>
                                    {q.question_number}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="questions-container">
                        Listening Questions
                <div>
                            {listenings.map((q, key) => (
                                <div
                                    className={`text-light pointer bg-primary`}>
                                    {q.question_number}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionSummary;
