import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './store/CategoryActions';
import Modal from '../../../../common/modal';
import Confirmation from '../../../../common/modal/Confirmation';
import AddUsers from './components/AddCategory';
import { Box } from '@mui/system';

const CategoryList = () => {
    const categoryList = useSelector((state: any) => {
        return state.category.categories;
    });

    const user = useSelector((state: any) => {
        return state.auth.user;
    });

    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editObj, setEditObj] = useState({ _id: -1 });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');

    useEffect(() => {
        user._id && loadCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setCategories(categoryList);
    }, [categoryList])

    const loadCategories = async () => {
        await dispatch(actions.getAllCategories());
    }

    const deleteAction = (obj: any) => {
        setShowConfirmation(true);
        setEditObj(obj)
    }

    const deleteConfirmation = async () => {
        await dispatch(actions.deleteCategory(editObj._id));
        setShowConfirmation(false);
    }

    const addAction = () => {
        setModalMode('add');
        setShowModal(true);
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditObj(obj);
        setShowModal(true);

    }

    const columns = [
        {
            name: 'title',
            align: 'left',
            label: 'Title',
            field: 'title',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }
    ]

    return (
        <Box>
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">Category List</div>
                <button className="btn primary" onClick={addAction}>Add Category</button>
            </header>
            
            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={categories}
                    editAction={editAction}
                    deleteAction={deleteAction}
                    paginate={10}
                />
            </div>
            {
                modalMode === 'add' &&
                <Modal show={showModal} title={'Add Category'}
                    closeModal={() => setShowModal(false)}>
                    <AddUsers closeModal={() => setShowModal(false)} mode={modalMode} />
                </Modal>
            }
            <Confirmation title="Delete Category"
                content="Are you sure you want to delete this catgory?"
                show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </Box>
    )
}

export default CategoryList;
