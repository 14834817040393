import React from 'react'
import img from '../../../assets/svgs/notfound.svg'

export default function NotFound() {
    return (
        <div className="flex items-center justify-center column">
            <img src={img} alt='' height="200" width="200" />
            <a href="/" className="btn btn-primary">Goto Home</a>
        </div>
    )
}
