// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Icon
} from '@mui/material';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

export default function UserListToolbar({ filterName, onFilterName }: any) {
  return (
    <RootStyle
    >

      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Box component={Icon} sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        }
      />

    </RootStyle>
  );
}
