import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import ProfileDefaultImg from '../../../../assets/default.png';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../auth/store/AuthActions';
import { UserType } from '../../../../common/enum/UserType.enum';

import StyledMenu from '../../component/StyledMeny';
import { AppBar, Avatar, IconButton, ListItemText, MenuItem, Stack, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../../assets/korenvasalogo.png';

interface INavClient {
    drawerWidth: number,
    handleDrawerToggle: any,
    noSideBar: Function
}


const NavClient = ({ drawerWidth, handleDrawerToggle, noSideBar }: INavClient) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [role, setRole] = useState('');
    const [titleName, setTitleName] = useState('')

    const examRunning: boolean = useSelector((state: any) => {
        return state.userReducer.examRunning;
    });

    const logoutUser = async () => {
        await dispatch(actions.logout());
        history.push("/");
    }

    const gotoProfile = () => {
        history.push(`/${role.toLowerCase()}/profile`)
        handleClose()
    }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const goToHome = () => {
        !examRunning && history.push('/')
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const defaultTitle = 'EPS-TOPIK UBT TRAIL EXAM'
    const user: any = useSelector((state: any) => state.auth.user);
    const institute: any = useSelector((state: any) => state.auth.institute);


    useEffect(() => {
        if (user && user.role !== undefined) {
            if (user.role === UserType.USER) {
                setRole('Student');
                setTitleName(institute?.name || defaultTitle)
            } else if (user.role === UserType.INSTITUTE) {
                setTitleName(user.name || defaultTitle)
                setRole('Institute');
            } else if (user.role === UserType.ADMIN) {
                setTitleName(defaultTitle)
                setRole('Admin');
            }
        }
    }, [user, institute])
    return (
        < AppBar
            position="fixed"
            sx={{
                width: { sm: noSideBar() ? '100%' : `calc(100% - ${drawerWidth}px)` },
                ml: { sm: noSideBar() ? '0' : `${drawerWidth}px` },
            }
            }
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={goToHome}
                >
                    <Avatar
                        alt={'logo'}
                        src={logo}
                    />
                </IconButton>

                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    {titleName}
                </Typography>
                <Stack direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center">
                    <Stack direction="column" alignItems="flex-end">
                        <Typography noWrap component="div" sx={{ fontWeight: 'bold' }}>
                            {user.name}
                        </Typography>
                        <Typography noWrap component="div" >
                            {role}
                        </Typography>
                    </Stack>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleClick}
                    >
                        <Avatar
                            alt={user?.name}
                            src={user?.image ? `${process.env.REACT_APP_API_BASE_URL}${user.image}` : ProfileDefaultImg}
                        />
                    </IconButton>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >

                        <MenuItem onClick={gotoProfile}>
                            <ListItemText primary="Profile" />
                        </MenuItem>

                        <MenuItem onClick={logoutUser}>
                            <ListItemText primary="Logout" />
                        </MenuItem>
                    </StyledMenu>
                </Stack>
            </Toolbar>
        </AppBar >
    );
}

export default NavClient;
