import React, { useEffect, useState } from 'react';
import img from '../../../assets/default.png';
import listening from '../../../assets/svgs/listening.svg';
import listeningPause from '../../../assets/svgs/pause.svg';

interface IImageHelper {
    image: string
    className: string
    style: any
    defaultListening: boolean,
    onClick: Function
}

const ImageHelper = (props: IImageHelper) => {

    const [image, setImage] = useState('');
    const [listeningImage, setListeningImage] = useState('');
    useEffect(() => {
        setListeningImage(listening)
    }, [])

    useEffect(() => {
        if (props.image && props.image.startsWith('uploads')) {
            setImage(`${process.env.REACT_APP_API_BASE_URL}${props.image}`)
        } else {
            setImage(props.image)
        }
    }, [props.image])

    const handleOnClick = async () => {
        if (props.defaultListening) {
            const shouldChange = await props.onClick()
            if (shouldChange) {
                setListeningImage(listeningPause)
            }
        }
    }

    return (
        <img
            src={image || (props.defaultListening ? listeningImage : img)}
            style={props.style ? props.style : {}}
            onClick={handleOnClick}
            alt=""
            className={`${props.className} ${props.defaultListening ? 'small-image' : ''}`} />
    )
}

export default ImageHelper
