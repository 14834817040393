import { Question } from '../../../../common/model/Question';
import questionType from '../../../../common/enum/questiontype';
import ImageHelper from '../../../../common/component/ImageHelper';
import { CircularProgress, Stack } from '@mui/material';
import OptionPreview from './OptionPreview';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


interface IPreviewQuestionAdd {
    question: Question
}

const PreviewQuestionAdd = (props: IPreviewQuestionAdd) => {
    const [refresh, setRefresh] = useState(false)
    const uploadFileLoading = useSelector((state: any) =>
        state.questions.uploadFileLoading
    );

    useEffect(() => {
        setRefresh(true)
        setTimeout(() => {
            setRefresh(false)
        }, 500)
    }, [props.question.listening])

    return (
        <Stack>
            <h2>Preview</h2>
            <div className="question-container">
                {
                    props.question.main_heading_image ?
                        uploadFileLoading.main_heading ?
                            <CircularProgress />
                            : <ImageHelper
                                onClick={() => { }}
                                defaultListening={false}
                                style={{}}
                                className="full-width"
                                image={props.question.main_heading} /> :
                        <div className="main-heading my-md bold justify">{props.question.main_heading}</div>
                }

                <div className="question-heading my-md">{props.question.heading}</div>
                {
                    props.question.description_image ?
                        uploadFileLoading.main_heading ?
                            <CircularProgress />
                            :
                            <ImageHelper
                                onClick={() => { }}
                                defaultListening={false}
                                style={{}}
                                className="full-width"
                                image={props?.question?.description || ''} /> :
                        <div style={{ wordBreak: 'keep-all', whiteSpace: 'pre-line' }} className={`description my-md ${props.question.description_boxed ? 'description-boxed' : ''}`}>{props.question.description}</div>
                }
                {
                    props.question.type === questionType.LISTENING
                        ? props.question?.listening && !refresh ?
                            uploadFileLoading.main_heading ?
                                <CircularProgress /> :
                                <div>
                                    <audio id="exam-listening"
                                        src={`${process.env.REACT_APP_API_BASE_URL}${props.question.listening}`}
                                        preload="auto"
                                        controls={true}
                                    />
                                </div> :
                            <div>No Listening Uploaded</div> :
                        <></>
                }
                <Stack direction={'column'} spacing={2}>
                    <OptionPreview
                        question={props.question}
                        butttonNumber="1"
                        paramName="option1" />
                    <OptionPreview
                        question={props.question}
                        butttonNumber="2"
                        paramName="option2" />
                    <OptionPreview
                        question={props.question}
                        butttonNumber="3"
                        paramName="option3" />
                    <OptionPreview
                        question={props.question}
                        butttonNumber="4"
                        paramName="option4" />
                </Stack>

            </div>
        </Stack>

    )
}

export default PreviewQuestionAdd
