import { User } from '../../common/model/User';
import * as actionTypes from './AuthTypes';

interface IUserState {
  isAuthenticated: boolean;
  user: User;
  loading: boolean;
  failed: boolean;
  institute: {};
}

const initialState: IUserState = {
  isAuthenticated: false,
  loading: false,
  failed: false,
  user: new User(),
  institute: {},
};
// eslint-disable-next-line
export default (state: IUserState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      localStorage.setItem('role', action.payload.user.role);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload.user,
      };
    case actionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    case actionTypes.GET_USER:
      return {
        ...state,
        users: action.payload,
      };
    case actionTypes.GET_INSTITUTE_INFO:
      localStorage.setItem('institute', JSON.stringify(action.payload));
      return {
        ...state,
        institute: action.payload,
      };
    case actionTypes.CHANGE_USER:
      let necessaryPayload = {
        client: action.payload.client,
        deleted: action.payload.deleted,
        firstName: action.payload.firstName,
        id: action.payload.id,
        image: action.payload.image,
        jobTitle: action.payload.jobTitle,
        lastName: action.payload.lastName,
        status: action.payload.status,
        userRole: action.payload.userRole,
        userType: action.payload.userType,
        username: action.payload.username,
      };
      localStorage.setItem('user', JSON.stringify(necessaryPayload));
      return {
        ...state,
        user: action.payload,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        failed: true,
      };
    default:
      return state;
  }
};
