import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { Box } from '@mui/system';
import { GetRequest } from '../../../../../plugins/axios';
import ListTable from '../../../../../common/Table';
import Confirmation from '../../../../../common/modal/Confirmation';
import Modal from '../../../../../common/modal';
import ChangePasswordAdmin from '../../UserProfile/components/ChangePasswordAdmin';
import EditUser from '../../usermanagement/components/EditUser';
import * as actions from '../../usermanagement/store/UsersActions';
import { useHistory } from 'react-router';


const Users = ({ userId }: any) => {


    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editUserObj, setEditUserObj] = useState({ _id: -1 });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');
    const history = useHistory()

    const user = useSelector((state: any) => {
        return state.auth.user;
    });

    useEffect(() => {
        userId && loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const loadUsers = async () => {
        const url = process.env.REACT_APP_API_BASE_URL + 'users'
        GetRequest(`${url}/institute/${userId}`, {}, {}).then(res => {
            setUsers(res.data)

        })
    }

    const deleteAction = (obj: any) => {
        setShowConfirmation(true);
        setEditUserObj(obj)
    }
    const viewAction = (obj: any) => {
        let role=""
        if(user.role===1){
            role ="institute"
        }else if(user.role===2){
            role = "admin"
        }
        history.push(`/${role}/users/${obj._id}`)
    }

    const deleteConfirmation = async () => {
        await dispatch(actions.deleteUser(editUserObj._id));
        setShowConfirmation(false);
    }


    const editUser = async (payload: any) => {
        await dispatch(actions.editUser(payload));
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditUserObj(obj);
        setShowModal(true);

    }

    const changePassword = async (payload: any) => {
        payload = {
            ...payload,
            user: editUserObj._id
        };
        await dispatch(actions.changeUserPassword(payload));
    }

    const changePasswordActions = (obj: any) => {
        setModalMode('changePassword');
        setEditUserObj(obj);
        setShowModal(true);
    }

    const columns = [
        {
            name: 'email',
            align: 'left',
            label: 'Email',
            field: 'email',
            flexVal: 2,
            sortable: true,
            splice: 5,
        }, {
            name: 'firstname',
            align: 'left',
            label: 'First Name',
            field: 'firstname',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }, {
            name: 'firstname',
            align: 'left',
            label: 'Last Name',
            field: 'lastname',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }, {
            name: 'status',
            align: 'center',
            label: 'Status',
            field: 'status',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
    ]

    return (
        <Box>
            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={users}
                    editAction={editAction}
                    deleteAction={deleteAction}
                    viewAction={viewAction}
                    paginate={10}
                />
            </div>
            {
                modalMode === 'edit' &&
                <Modal show={showModal} title={'Edit User'}
                    closeModal={() => setShowModal(false)}>
                    <EditUser
                        changePassword={changePasswordActions}
                        editUser={editUser}
                        editObj={editUserObj}
                        closeModal={() => setShowModal(false)}
                        mode={modalMode}
                    />
                </Modal>
            }
            {
                modalMode === 'changePassword' &&
                <Modal show={showModal} title={'Change Password'}
                    closeModal={() => setShowModal(false)}>
                    <ChangePasswordAdmin
                        closeModal={() => setShowModal(false)}
                        changePassword={changePassword}
                        user={editUserObj} />
                </Modal>
            }
            <Confirmation title="Delete User"
                content="Are you sure you want to delete this user?"
                show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </Box>
    )
}

export default Users;
