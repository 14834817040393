import React, { useEffect, useState } from 'react';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import rules from './profileRules';
import * as actions from '../../../../../store/action';
import { useDispatch } from 'react-redux';


interface IChangePassword {
    email: string
    password: string,
    newPassword: string,
    confirmPassword: string
}

interface IChangePasswordDialog {
    email: string,
    closeModal: Function,
}

const initialState: IChangePassword = {
    email: '',
    password: '',
    newPassword: '',
    confirmPassword: ''
}

const ChangePassword = (props: IChangePasswordDialog) => {
    const [password, setPassword] = useState(initialState);
    const [errors, setErrors] = useState(initialState);
    const dispatch = useDispatch()

    useEffect(() => {
        setPassword({
            ...initialState,
            email: props.email
        })
    }, [props.email])

    const inputHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: ''
        })
        setPassword({
            ...password,
            [e.target.name]: e.target.value
        });
    }

    const confirmAction = async () => {
        let errorMsgs: any = ValidateForm(password, rules);
        if (password.newPassword !== password.confirmPassword) {
            errorMsgs.confirmPassword = "Confirm Password doesnot match"
        }
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(actions.changeOwnPassword(password));
            props.closeModal();
        }
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, rules);

        if (password.newPassword !== password.confirmPassword) {
            setErrors({
                ...errors,
                [e.target.name]: errorMsg,
                confirmPassword: 'Confirm Password doesnot match'
            });
        } else {
            setErrors({
                ...errors,
                [e.target.name]: errorMsg,
                confirmPassword: ''
            });
        }
    }

    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area">
                <div className="form-group my-lg">
                    <label>Current Password</label>
                    <input
                        type="password"
                        name={'password'}
                        onBlur={inputValidation}
                        value={password.password}
                        onChange={inputHandler}
                    />
                    {errors.password !== '' ? <span className="error-text">{errors.password}</span> : ''}
                </div>
                <div className="form-group my-md">
                    <label>New Password</label>
                    <input
                        type="password"
                        name={'newPassword'}
                        onBlur={inputValidation}
                        value={password.newPassword}
                        onChange={inputHandler}
                    />
                    {errors.newPassword !== '' ? <span className="error-text">{errors.newPassword}</span> : ''}
                </div>
                <div className="form-group my-md">
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        name={'confirmPassword'}
                        onBlur={inputValidation}
                        value={password.confirmPassword}
                        onChange={inputHandler}
                    />
                    {errors.confirmPassword !== '' ? <span className="error-text">{errors.confirmPassword}</span> : ''}
                </div>
            </div>
            <div className="button-area flex justify-end mt-lg">
                <button className="btn primary mr-xs" onClick={confirmAction}>Confirm</button>
                <button type="reset" className="btn primary outlined" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default ChangePassword;