// eslint-disable-next-line
export default {
  question_number: {
    required: true,
    isNumber: true,
    label: 'Question Number',
  },
  option1: {
    required: true,
    label: 'Option 1',
  },
  option2: {
    required: true,
    label: 'Option 2',
  },
  option3: {
    required: true,
    label: 'Option 3',
  },
  option4: {
    required: true,
    label: 'Option 4',
  },
  answer: {
    required: true,
    label: 'Answer',
  },
  listening: {
    required: true,
    label: 'Listening',
  },
};
