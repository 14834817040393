import React from 'react';
import { Redirect } from 'react-router-dom';

const AdminHome = () => {
    return (
        <div className='row wrap'>
            <div className="card-admin">
                <div className="card-title">Total User</div>
                <div className="card-body">10</div>
            </div>

            <div className="card-admin">
                <div className="card-title">Total institute</div>
                <div className="card-body">2</div>
            </div>
            <div className="card-admin">
                <div className="card-title">Total Exam</div>
                <div className="card-body">5</div>
            </div>
            <Redirect to="admin/exams" />
        </div>
    );
};
export default AdminHome;
