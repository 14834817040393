import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import React from 'react';
import "./modal.scss";
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = (props: any) => {
    return (
        <>
            <Dialog
                fullScreen={props.fullScreen || false}
                open={props.show}
                scroll={'paper'}
                fullWidth={true}
                maxWidth={props.maxWidth || 'md'}
                onClose={props.closeModal}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.closeModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {props.title}
                        </Typography>

                    </Toolbar>
                </AppBar>
                {props.content ? <>{props.children}</> : <DialogContent>
                    {props.children}
                </DialogContent>}

            </Dialog>
        </>
    )
};

export default Modal;
