export class User {
  public _id?: string;
  public email?: string;
  public first_name?: string;
  public last_name?: string;
  public phone?: string;
  public image?: string;
  public address?: string;
  public status?: string;
  public info?: string;
  public role?: Number;
  public institute_role?: Number;
  public created_by?: string;

  constructor(
    _id?: string,
    email?: string,
    first_name?: string,
    last_name?: string,
    status?: string,
    info?: string,
    role?: Number,
    institute_role?: Number,
    image?: string,
    address?: string,
    phone?: string,
    created_by?: string
  ) {
    this._id = _id ? _id : '';
    this.email = email ? email : '';
    this.first_name = first_name ? first_name : '';
    this.last_name = last_name ? last_name : '';
    this.status = status ? status : '';
    this.info = info ? info : '';
    this.role = role ? role : 0;
    this.institute_role = institute_role ? institute_role : 0;
    this.image = image ? image : '';
    this.address = address ? address : '';
    this.phone = phone ? phone : '';
    this.created_by = created_by ? created_by : '';
  }
}
