import { ExamAccessType } from '../../../../../common/enum/ExamAccessType.enum';

export const accessTypes = [
  { label: 'Specific Exams', value: ExamAccessType.SELECTED_EXAM },
  {
    label: 'All from Institute and Public Exam',
    value: ExamAccessType.CREATOR_AND_PUBLIC_ALL,
  },
  { label: 'Public All - No Limit', value: ExamAccessType.PUBLIC_ALL },
  { label: 'Access All - Time Frame', value: ExamAccessType.TIME_FRAME },
  { label: 'Access All - Limited Number', value: ExamAccessType.ALL_X_TIMES },
  { label: 'Access All - No Limit', value: ExamAccessType.ALL },
  { label: 'Free Exams Only - No Limit', value: ExamAccessType.FREE_ALL },
];

export const getLabelFromAccesstype = (value: string) => {
  let accessType = accessTypes.find(
    (accessType: any) => accessType.value === value
  );
  return accessType?.label;
};
