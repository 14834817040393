import React, { useEffect, useState } from 'react'
import optionType from '../../../enum/optionType'

export default function AudioPlayer({ question, localImage }: any) {
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        setRefresh(true)
        setTimeout(() => {
            setRefresh(false)
        }, 500)
    }, [localImage])
    return (
        <>
            {(question.option_type === optionType.LISTENING || question.option_type === optionType.LISTENING_AND_TEXT) && !refresh &&
                < audio id="exam-listening"
                    src={localImage}
                    preload="auto"
                    controls={true}
                    controlsList="nodownload"
                />}
        </>
    )
}
