import React from 'react'
import './switch.scss'
interface IToggleSwitch {
    checked: boolean,
    name: string,
    onChange: Function
}
const ToggleSwitch = (props: IToggleSwitch) => {
    return (
        <label className="toggle-control">
            <input
                type="checkbox"
                checked={props.checked}
                name={props.name}
                onChange={(e) => { props.onChange(e) }} />
            <span className="control" />
        </label>
    )
}

export default ToggleSwitch;
