// eslint-disable-next-line
export default {
    username: {
        required: true,
        isEmail: true,
        label: 'Username'
    },
    firstName: {
        required: true,
        label: 'First Name'
    },
    lastName: {
        required: true,
        label: 'Last Name'
    },
    status: {
        required: true,
        label: 'Status'
    },
    userType: {
        required: true,
        label: "User Type"
    },
    jobTitle: {
        required: true,
        label: 'Job Title'
    },
    userRole: {
        required: true,
        label: 'User Role'
    },
}

