import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './services/ReportActions';
import moment from 'moment'
import { Box } from '@mui/system';
import { Button, Grid, Stack } from '@mui/material';
import { useHistory } from 'react-router';
interface IDate {
    startDate: string,
    endDate: string
}
const initialDateState: IDate = {
    startDate: '',
    endDate: '',
}

const ReportList = () => {
    const reportObj = useSelector((state: any) => state.adminReducer.reports);
    const user = useSelector((state: any) => state.auth.user);
    const [date, setDate] = useState(initialDateState);

    const dispatch = useDispatch();
    const [report, setReport] = useState([]);
    const history = useHistory()

    useEffect(() => {
        user._id && loadReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        user._id && loadReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setReport(reportObj.reports);
        moment(reportObj.startDate).format('YYYY-MM-DD')
        setDate({
            ...date,
            startDate: moment(reportObj?.startDate).format('YYYY-MM-DD'),
            endDate: moment(reportObj?.endDate).format('YYYY-MM-DD'),
        })
        // eslint-disable-next-line
    }, [reportObj])

    const loadReport = async () => {
        await dispatch(actions.getReportOfInstitute(date));
    }

    const columns = [
        {
            name: 'title',
            align: 'left',
            label: 'Name of Institute',
            field: 'name',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }
        , {
            name: 'Exam Attempted',
            align: 'center',
            label: 'Exam Attempted',
            field: 'total',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
    ]
    const inputDateHandler = (e: any) => {
        setDate({
            ...date,
            [e.target.name]: e.target.value
        })
    };


    const viewAction = (data: any) => {
        history.push('institutes/' + data._id)
    };

    return (
        <Box>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <div className="title-sm bold text-primary">Report</div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                        <div className="form-group my-sm mr-sm">
                            <div className="flex items-center">
                                <label className="pr-sm">Start Date</label>
                            </div>
                            <input
                                type="date"
                                name="startDate"
                                id="startDate"
                                value={date.startDate}
                                onChange={inputDateHandler} />
                        </div>
                        <div className="form-group my-sm mr-sm">
                            <div className="flex items-center">
                                <label className="pr-sm">End Date</label>
                            </div>
                            <input
                                type="date"
                                name="endDate"
                                id="endDate"
                                value={date.endDate}
                                onChange={inputDateHandler} />
                        </div>
                        <Button variant="contained" onClick={loadReport}>Generate Report</Button>

                    </Stack>

                </Grid>
            </Grid>
            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={report}
                    paginate={10}
                    viewAction={viewAction}
                />
            </div>
        </Box>
    )
}

export default ReportList;
