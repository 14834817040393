import React from 'react';
import ROUTELIST from "./routeList"
import { Switch } from 'react-router-dom';
import ClientRoutes from './UserRoute';
import NotFound from '../../auth/NotFound';

const ClientRoute = () => {
    return (
        <>
            <React.Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {
                        ROUTELIST.map((route, key) => (
                            <ClientRoutes path={`/student${route.path}`}
                                name={route.name}
                                component={route.component}
                                exact={route.exact}
                                key={key} />
                        ))
                    }
                    <ClientRoutes
                        component={NotFound}
                    />
                </Switch>
            </React.Suspense>
        </>
    )
}

export default ClientRoute;