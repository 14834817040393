import {
  GetRequest,
  PostRequest,
  PutRequest,
} from '../../../../../plugins/axios';
import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GET_SELF_USERS_LIST, GET_USERS_LIST } from './UsersTypes';

const userUrl = process.env.REACT_APP_API_BASE_URL + 'users';

export const getUsersSuccess = (payload: any) => {
  return {
    type: GET_USERS_LIST,
    payload: payload,
  };
};

export const getSelfCreatedUsersSuccess = (payload: any) => {
  return {
    type: GET_SELF_USERS_LIST,
    payload: payload,
  };
};

export const getAllUsers = () => (dispatch: any) => {
  const user = store.getState().auth.user;
  if (user) {
    let path = '';
    if (user.role === 1) {
      path = 'institute';
    } else if (user.role === 2) {
      path = 'getAllUsers';
    }
    GetRequest(`${userUrl}/${path}/${user._id}`, {}, {})
      .then((res) => {
        dispatch(getUsersSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Users',
          })
        );
      });
  }
};

export const getAllSelfCreatedUsers = () => (dispatch: any) => {
  const user = store.getState().auth.user;
  if (user) {
    GetRequest(`${userUrl}/getSelfCreatedUsers/${user._id}`, {}, {})
      .then((res) => {
        dispatch(getSelfCreatedUsersSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Users',
          })
        );
      });
  }
};

export const addUser =
  (payload: any = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    if (userID) {
      PostRequest(`${userUrl}/createUser/${userID}`, payload, {})
        .then((res) => {
          dispatch(getAllUsers());
          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Adding Success',
              message: 'Sucessfully Added User',
            })
          );
        })
        .catch((err) => {
          console.log(err);

          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Adding Error',
              message: err.response?.data?.error || 'Error Adding User',
            })
          );
        });
    }
  };

export const editUser =
  (payload: any = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    PutRequest(`${userUrl}/update/${userID}`, payload, {})
      .then((res) => {
        dispatch(getAllUsers());
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Successfully Updated',
            message: 'Sucessfully Updated User',
          })
        );
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Updating Error',
            message: err.response?.data?.error || 'Error Updating User Data',
          })
        );
      });
  };

export const deleteUser = (_id: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  PutRequest(`${userUrl}/delete/${userID}`, { _id }, {})
    .then((res) => {
      dispatch(getAllUsers());
      dispatch(
        setToasterState({
          appear: true,
          title: 'success',
          name: 'Successfully Deleted',
          message: 'Sucessfully Deleted User',
        })
      );
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Deletion Error',
          message: err.response?.data?.error || 'Error Deleting User',
        })
      );
    });
};

export const addUserToInstitute = (_id: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  PutRequest(`${userUrl}/addUserToInstitute/${userID}`, { user: _id }, {})
    .then((res) => {
      dispatch(getAllUsers());
      dispatch(getAllSelfCreatedUsers());
      dispatch(
        setToasterState({
          appear: true,
          title: 'success',
          name: 'Successfully Deleted',
          message: 'Sucessfully Deleted User',
        })
      );
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Deletion Error',
          message: err.response?.data?.error || 'Error Deleting User',
        })
      );
    });
};

export const changeUserPassword =
  (payload: any = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    if (userID) {
      PutRequest(`${userUrl}/updateUserPassword/${userID}`, payload, {})
        .then((res) => {
          dispatch(getAllUsers());
          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Success',
              message: 'Changed Password Sucessfull',
            })
          );
        })
        .catch((err) => {
          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Error',
              message: 'Error Changing Password',
            })
          );
        });
    }
  };
