import { Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RadioButton from '../../../../../common/Radio'
import { isJsonString } from '../../../../../utils/utils'
import optionType from '../../../../common/enum/optionType'

export default function PreviewQuestionOptionItem({
    question,
    selectedAnswer,
    butttonNumber,
    value,
    paramName
}: any) {
    const [radioText, setRadioText] = useState('')
    const [audioSrc, setAudioSrc] = useState('')
    const [className, setClassName] = useState('')
    useEffect(() => {
        if (question?.option_type === optionType.LISTENING || question?.option_type === optionType.SAME_LISTENING) {
            setAudioSrc(`${process.env.REACT_APP_API_BASE_URL}${question[paramName]}`)
        } else if (question.option_type === optionType.LISTENING_AND_TEXT) {
            const parsedData = isJsonString(question[paramName])
            setAudioSrc(`${process.env.REACT_APP_API_BASE_URL}${parsedData.listening}`)
            setRadioText(parsedData.text)
        } else {
            setRadioText(question[paramName])
        }
        let classname = ''

        if (selectedAnswer === value) {
            if (question.answer === value) {
                classname = 'bg-success'
            } else {
                classname = 'bg-danger'
            }
        }else{
            if (question.answer === value) {
                classname = 'bg-success'
            } else {
                classname = ''
            }
        }

        setClassName(classname)

        // eslint-disable-next-line
    }, [question])

    return (
        <div className={className}>
            <RadioButton
                onImageClick={() => { }}
                optionType={question.option_type || ''}
                onBlur={() => { }}
                question={question}
                listeningStatus={{ status: { loption1: 0, loption2: 0, loption3: 0, loption4: 0 } }}
                butttonNumber={butttonNumber}
                paramName={paramName}
                audioSrc={audioSrc}
                listeningStatusAddOne={() => { }}
                checked={selectedAnswer === value}
                name={"answer"}
                value={value}
                onChange={(e: any) => { }}
                text={radioText}
            />
            <Divider style={{ marginTop: 5 }} />
        </div>
    )
}
