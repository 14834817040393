import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import { useHistory } from "react-router-dom";
import ExamCard from '../../../../common/ExamCard';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Link from '@mui/material/Link';


const ExamsList = () => {
    const examsList = useSelector((state: any) => {
        return state.userReducer.exams;
    });
    const examsListAll = useSelector((state: any) => {
        return state.userReducer.allExams;
    });

    const history = useHistory();
    const user = useSelector((state: any) => state.auth.user);

    const dispatch = useDispatch();
    const [exams, setExams] = useState([]);

    useEffect(() => {
        user._id && loadExams();
        user._id && loadExamsAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setExams(examsList);
    }, [examsList])

    const loadExams = async () => {
        await dispatch(actions.getExams());
    }

    const loadExamsAll = async () => {
        await dispatch(actions.getExamsAll());
    }

    const viewExam = (exam: any) => {
        localStorage.setItem('startExam', JSON.stringify(exam))
        history.push(`exams/start/${exam._id}`)
    }

    return (
        <Box>
            {user && !user?.created_by && (
                <Box
                    sx={{ backgroundColor: 'blanchedalmond', padding: '10px', marginBottom: '10px', borderRadius: '5px' }}>
                    <Typography variant="subtitle1" component="span" >
                        You are not verified because any institutes haven't enroll you.
                        And you cannot attend exam created by admin or institutes.
                    </Typography>
                    <Link href="/student/contact">See How to Contact?</Link>
                </Box>
            )}

            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">Available Exams</div>
            </header>
            <Grid container spacing={1}>
                {exams.map((e: any) => (
                    <Grid item lg={3} md={4} sm={12}>
                        <ExamCard
                            key={e._id}
                            exam={e}
                            title={e.title}
                            startAction={viewExam}
                        />
                    </Grid>

                ))}
            </Grid>

            <header className={'flex justify-between items-center mb-md mt-md'}>
                <div className="title-sm bold text-primary">Other Exams List</div>
            </header>

            <Grid container spacing={1}>
                {examsListAll.map((e: any) => (
                    <Grid item lg={3} md={4} sm={12}>
                        <ExamCard
                            key={e._id}
                            exam={e}
                            title={e.title}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default ExamsList;
