import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ExamCard from '../../../../../common/ExamCard';
import { Divider, Grid } from '@mui/material';
import { Box } from '@mui/system';
interface IExamList {
  type: string,
  viewExam: Function,
  editAction: Function,
  deleteAction: Function
}

export default function ExamListType(props: IExamList) {
  const examsList = useSelector((state: any) => {
    return state.exams.exams;
  });
  const user = useSelector((state: any) => state.auth.user);
  const [exams, setExams] = useState([]);
  const [instituteAccess, setInstituteAccess] = useState([])

  useEffect(() => {

    if (user.role === 1) {
      user._id && setExams(examsList.filter((e: any) => e.user._id === user._id));
      user._id && setInstituteAccess(examsList.filter((e: any) => (e.user._id !== user._id || e.type === 'public')));
    } else {
      setExams(examsList.filter((e: any) => e.is_cbt === (props.type === "cbt")));
    }

    // eslint-disable-next-line
  }, [examsList, user, props.type])

  return (
    <Box>
      <Grid container spacing={1}>
        {exams.length ? exams.map((e: any) => (
          <Grid item lg={3} md={4} sm={12}>
            <ExamCard
              key={e._id}
              exam={e}
              editAction={props.editAction}
              viewAction={props.viewExam}
              deleteAction={props.deleteAction}
            />
          </Grid>

        )) : (
          <div>Empty Exam List</div>
        )}
      </Grid>

      {user.role === 1 && (
        <>
          <Divider />
          <div className="my-md title-sm bold text-primary">Exams You have Access To</div>
          <Grid container spacing={1}>
            {instituteAccess.length > 0 ? instituteAccess.map((e: any) => (
              <Grid item lg={3} md={4} sm={12}>
                <ExamCard
                  key={e._id}
                  exam={e}
                  viewAction={props.viewExam}
                />
              </Grid>
            )) :
              <div className="text-center">No Exams Found, You can add one if you have access to add it.</div>
            }

          </Grid>
        </>
      )}

    </Box>
  );
}
