import {
  DeleteRequest,
  GetRequest,
  PostRequest,
  PutRequest,
} from '../../../../../plugins/axios';
import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import optionType from '../../../../common/enum/optionType';
import { Question } from '../../../../common/model/Question';
import {
  GET_SUCCESS,
  GET_QUESTIONS_ERROR,
  GET_QUESTIONS_LIST,
  GET_QUESTIONS_LOADING,
  GET_UPLOAD_FILE,
  GET_UPLOAD_FILE_LOADING,
} from './QuestionsTypes';

const questionUrl = process.env.REACT_APP_API_BASE_URL + 'questions';
const uploadFileUrl = process.env.REACT_APP_API_BASE_URL + 'upload-file';

export const getQuestionsAdminSuccess = (payload: any) => {
  return {
    type: GET_QUESTIONS_LIST,
    payload: payload,
  };
};

export const setUploadFileSuccess = (payload: any) => {
  return {
    type: GET_UPLOAD_FILE,
    payload: payload,
  };
};

export const setUploadFileLoading = (payload: any) => {
  return {
    type: GET_UPLOAD_FILE_LOADING,
    payload: payload,
  };
};

export const setLoading = (payload: Boolean) => {
  return {
    type: GET_QUESTIONS_LOADING,
    payload: payload,
  };
};

export const getError = (payload: string) => {
  return {
    type: GET_QUESTIONS_ERROR,
    payload: payload,
  };
};

export const getActionsCompleted = (payload: Boolean) => {
  return {
    type: GET_SUCCESS,
    payload: payload,
  };
};

export const getQuestionsFromExam = (examId: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    GetRequest(`${questionUrl}/${examId}/${userID}`, {}, {})
      .then((res) => {
        dispatch(getQuestionsAdminSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Questions',
          })
        );
      });
  }
};

export const uploadFile =
  (examId: string, payload: any = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    const formData: any = new FormData();
    formData.append('file', payload.file);
    formData.append('name', payload.name);

    let loadingName = payload.name;
    if (
      payload.name &&
      !payload.name.startsWith('listening') &&
      payload.name.endsWith('listening')
    ) {
      loadingName = payload.name.split('_')[0];
    }
    console.log(loadingName);

    dispatch(setUploadFileLoading({ name: loadingName, loading: true }));
    if (userID) {
      PostRequest(`${uploadFileUrl}/${examId}/${userID}`, formData, {})
        .then((res) => {
          dispatch(setUploadFileLoading({ name: loadingName, loading: false }));
          dispatch(setUploadFileSuccess(res.data));

          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Success',
              message: 'Successfully uploaded Image',
            })
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(setUploadFileLoading({ name: loadingName, loading: false }));
          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Error Upload File',
              message: 'Error Upload File',
            })
          );
        });
    } else {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'User Not Found',
          message: 'Error adding question',
        })
      );
    }
  };

export const addQuestion =
  (examId: string, payload: Question = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;

    if (payload.option_type === optionType.LISTENING_AND_TEXT) {
      payload = {
        ...payload,
        option1: JSON.stringify({
          text: payload.option1,
          listening: payload.option1_listening,
        }),
        option2: JSON.stringify({
          text: payload.option2,
          listening: payload.option2_listening,
        }),
        option3: JSON.stringify({
          text: payload.option3,
          listening: payload.option3_listening,
        }),
        option4: JSON.stringify({
          text: payload.option4,
          listening: payload.option4_listening,
        }),
      };
    }

    // Display the key/value pairs
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    dispatch(getActionsCompleted(false));
    dispatch(setLoading(true));
    if (userID) {
      PostRequest(`${questionUrl}/create/new/${examId}/${userID}`, payload, {})
        .then((res) => {
          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Success',
              message: 'Successfully added Question',
            })
          );
          dispatch(setLoading(false));
          dispatch(getQuestionsFromExam(examId));
          dispatch(getActionsCompleted(true));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Error Adding',
              message: 'Error adding question',
            })
          );
        });
    } else {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'User Not Found',
          message: 'Error adding question',
        })
      );
    }
  };

export const editQuestion =
  (questionId: string = '', payload: Question = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;

    if (payload.option_type === optionType.LISTENING_AND_TEXT) {
      payload = {
        ...payload,
        option1: JSON.stringify({
          text: payload.option1,
          listening: payload.option1_listening,
        }),
        option2: JSON.stringify({
          text: payload.option2,
          listening: payload.option2_listening,
        }),
        option3: JSON.stringify({
          text: payload.option3,
          listening: payload.option3_listening,
        }),
        option4: JSON.stringify({
          text: payload.option4,
          listening: payload.option4_listening,
        }),
      };
    }

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    dispatch(getActionsCompleted(false));
    dispatch(setLoading(true));
    if (userID) {
      PutRequest(
        `${questionUrl}/new/${payload.exam}/${questionId}/${userID}`,
        payload,
        {}
      )
        .then((res) => {
          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Success',
              message: 'Successfully updated Question',
            })
          );
          dispatch(setLoading(false));
          dispatch(getQuestionsFromExam(payload.exam));
          dispatch(getActionsCompleted(true));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Error Adding',
              message: 'Error adding question',
            })
          );
        });
    } else {
      dispatch(setLoading(false));
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'User Not Found',
          message: 'Error adding question',
        })
      );
    }
  };

export const deleteQuestion =
  (questionId: string = '', examId: string = '') =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;

    if (userID) {
      dispatch(getActionsCompleted(false));
      dispatch(setLoading(true));
      DeleteRequest(`${questionUrl}/${questionId}/${userID}`, {})
        .then((res) => {
          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Success',
              message: 'Successfully deleted Exam',
            })
          );
          dispatch(setLoading(false));
          dispatch(getQuestionsFromExam(examId));
          dispatch(getActionsCompleted(true));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Error Deleting',
              message: 'Error deleting question',
            })
          );
        });
    } else {
      dispatch(setLoading(false));
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Error Deleting',
          message: 'Error deleting question',
        })
      );
    }
  };
