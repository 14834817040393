import React, { useEffect, useState } from 'react';
import ROUTELIST from "./routerList"
import { Switch } from 'react-router-dom';
import AddminRouter from './AddminRouter';
import NotFound from '../../auth/NotFound'

const ClientRoute = () => {
    const [routes, setRoutes] = useState<any>([])
    useEffect(() => {
        const route_list = ROUTELIST.filter((route, key) => {
            if (route.path?.startsWith('/institutes')) {
                if (process.env.REACT_APP_MANAGE_INSTITUTE === '1') {
                    return true;
                }
                return false
            }

            if (route.path?.startsWith('/transferexam')) {
                if (process.env.REACT_APP_TRANSFER_EXAMS === '1') {
                    return true;
                }
                return false
            }
            return true;
        })
        setRoutes(route_list)
        // eslint-disable-next-line
    }, [ROUTELIST])

    return (
        <>
            <React.Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {
                        routes.map((route: any, key: any) => (
                            process.env.REACT_APP_MANAGE_INSTITUTE === '1' ?
                                <AddminRouter path={`/admin${route.path}`}
                                    name={route.name}
                                    component={route.component}
                                    exact={route.exact}
                                    key={key} />
                                :
                                !route.path?.startsWith('/institutes') &&
                                <AddminRouter path={`/admin${route.path}`}
                                    name={route.name}
                                    component={route.component}
                                    exact={route.exact}
                                    key={key} />
                        ))
                    }
                    <AddminRouter
                        component={NotFound}
                    />
                </Switch>
            </React.Suspense >
        </>
    )
}

export default ClientRoute;