import * as actionTypes from './QuestionsTypes';
interface IQuestions {
  questions: [];
  uploadFileLoading: {};
  uploadFile: {};
  loading: Boolean;
  error: string;
  success: Boolean;
}
const initailState: IQuestions = {
  questions: [],
  uploadFile: {},
  uploadFileLoading: {
    main_heading: false,
    description: false,
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  },
  loading: false,
  error: '',
  success: false,
};
// eslint-disable-next-line
export default (state: IQuestions = initailState, actions: any) => {
  switch (actions?.type) {
    case actionTypes.GET_QUESTIONS_LIST:
      return {
        ...state,
        questions: actions.payload,
      };
    case actionTypes.GET_QUESTIONS_LOADING:
      return {
        ...state,
        loading: actions.payload,
      };
    case actionTypes.GET_QUESTIONS_ERROR:
      return {
        ...state,
        error: actions.payload,
      };
    case actionTypes.GET_SUCCESS:
      return {
        ...state,
        success: actions.payload,
      };
    case actionTypes.GET_UPLOAD_FILE:
      return {
        ...state,
        uploadFile: actions.payload,
      };
    case actionTypes.GET_UPLOAD_FILE_LOADING:
      return {
        ...state,
        uploadFileLoading: {
          ...state.uploadFileLoading,
          [actions.payload.name]: actions.payload.loading,
        },
      };
    default:
      return state;
  }
};
