import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import ExamHeading from './components/ExamHeading';
import DisplayQuestionResult from './components/DisplayQuestionResult';
import ResultNumberLists from './components/ResultNumberList';
import { Box, Container, Paper } from '@mui/material';

const PreviewResult = () => {
    const { resultId } = useParams<{ resultId: string }>();
    const [userAnswers, setUserAnswers] = useState<any>([])
    const [position, setPosition] = useState(0)
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: any) => state.auth.user);

    const resultObj = useSelector((state: any) => state.adminReducer.result)

    const [examMode, setExamMode] = useState('all')

    useEffect(() => {
        user._id && dispatch(actions.getAdminResultFromResultId(resultId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    useEffect(() => {
        if (resultObj) {
            setUserAnswers(resultObj.answers)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultObj])


    const decreasePosition = async () => {
        position > 0 && setPosition(position - 1);
    }
    const increasePosition = async () => {
        position < userAnswers.length - 1 && setPosition(position + 1);
    }

    const goBack = () => {
        history.goBack();
    }
    const switchToAllMode = async () => {
        setExamMode("all");
        // await dispatch(actions.setListeningPlaying(false))
    };

    const switchToSingleMode = (position: number) => {
        setExamMode("single");
        changePosition(position);
    };

    const changePosition = (position: number) => {
        setPosition(position)
    };
    const increaseDescresePosition = (num: number) => {
        if (num === 1) {
            increasePosition()
        } else {
            decreasePosition()
        }
    };



    return (
        <Container maxWidth="lg">
            <div className="my-sm">
                <button className="btn primary" onClick={goBack}>Back</button>
            </div>
            <Paper>
                <Box sx={{ p: { xs: 0, md: 2 } }}>
                    <ExamHeading totalClick={switchToAllMode} answers={userAnswers} />
                    {examMode === "single" && userAnswers.length > 0 && (
                        <DisplayQuestionResult
                            selectedAnswer={userAnswers[position].answer}
                            question={userAnswers[position].question}
                            changePosition={increaseDescresePosition}
                            playAudio={() => { }}
                            position={position}
                        />
                    )}
                    {examMode === "all" && (
                        <ResultNumberLists
                            answers={userAnswers}
                            setListeningStatus={() => { }}
                            changeMode={switchToSingleMode}
                        />
                    )}

                </Box>

            </Paper>

        </Container>
    )
}

export default PreviewResult;
