import * as actionTypes from './CategoryTypes';
interface ICategory {
  categories: [];
  loading: Boolean;
}
const initailState: ICategory = {
  categories: [],
  loading: false,
};
// eslint-disable-next-line
export default (state: ICategory = initailState, actions: any) => {
  switch (actions?.type) {
    case actionTypes.GET_CATEGORY_LIST:
      return {
        ...state,
        categories: actions.payload,
      };
    case actionTypes.GET_CATEGORY_LOADING:
      return {
        ...state,
        loading: actions.payload,
      };
    default:
      return state;
  }
};
