import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GetRequest, PostRequest, PutRequest } from '../../../../../plugins/axios';

const examUrl = process.env.REACT_APP_API_BASE_URL + 'exams';
const reportUrl = process.env.REACT_APP_API_BASE_URL + 'reports';
const examAccessUrl = process.env.REACT_APP_API_BASE_URL + 'examaccess';

export const getExamsSuccess = (payload: any) => {
  return {
    type: 'GET_EXAMS',
    payload: payload,
  };
};
export const getExamsAllSuccess = (payload: any) => {
  return {
    type: 'GET_EXAMS_ALL',
    payload: payload,
  };
};

export const getReportSuccess = (payload: string) => {
  return {
    type: 'GET_REPORT_ID',
    payload: payload,
  };
};

export const getExams = () => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    GetRequest(`${examUrl}/published/users/${userID}`, {}, {})
      .then((res) => {
        console.log(res.data);
        
        dispatch(getExamsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};
export const getExamsAll = () => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    GetRequest(`${examUrl}/users/getAll/${userID}`, {}, {})
      .then((res) => {
        dispatch(getExamsAllSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};

export const storeDataToReport = (payload: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    PostRequest(`${reportUrl}/create/${userID}`, payload, {})
      .then((res) => {
        dispatch(getReportSuccess(res.data._id));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};

export const updateExamAccess = (payload: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    PutRequest(
      `${examAccessUrl}/increaseCount/${payload.exam}/${userID}`,
      {},
      {}
    )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot update Exam Access',
          })
        );
      });
  }
};
