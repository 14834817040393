import React, { useEffect } from 'react';
import MainRoute from '../routes';
import Toaster from '../common/toaster';
import { useDispatch } from 'react-redux';
import * as actions from '../store/action';

const MainLayout = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        reauthenticate().then(r => true);
        // eslint-disable-next-line
    }, [])

    const reauthenticate = async () => {
        await dispatch(actions.reauthenticate())
    }
    return (
        <>
            <MainRoute />
            <Toaster />
        </>
    )
}

export default MainLayout;
