import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Box } from '@mui/system';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { useHistory, useLocation } from "react-router";

import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import PersonIcon from '@mui/icons-material/Person';
import Category from '@mui/icons-material/Category';


const sideBarList = [
    {
        path: '/student/exams',
        label: 'Exam List',
        icon: <DashboardIcon />,
        role: 0
    },
    {
        path: '/student/results',
        label: 'Results',
        icon: <DonutSmallIcon />,
        role: 0
    },
    {
        path: '/student/profile',
        label: 'Profile',
        icon: <PersonIcon />,
        role: 0
    },
    {
        path: '/institute/exams',
        label: 'Exam List',
        icon: <DashboardIcon />,
        role: 1
    },
    {
        path: '/institute/users',
        label: 'Manage User',
        icon: <PeopleIcon />,
        role: 1
    },
    {
        path: '/institute/results',
        label: 'Todays Results',
        icon: <DonutSmallIcon />,
        role: 1
    },
    {
        path: '/institute/profile',
        label: 'Profile',
        icon: <PersonIcon />,
        role: 1
    },
    {
        path: '/admin/exams',
        label: 'Exam List',
        icon: <DashboardIcon />,
        role: 2
    },
    {
        path: '/admin/users',
        label: 'Manage User',
        icon: <PeopleIcon />,
        role: 2
    },
    {
        path: '/admin/institutes',
        label: 'Manage Institute',
        icon: <HolidayVillageIcon />,
        role: 2
    },
    {
        path: '/admin/categories',
        label: 'Category',
        icon: <Category />,
        role: 2
    },
    {
        path: '/admin/reports',
        label: 'View Report',
        icon: <AssignmentIcon />,
        role: 2
    },
    {
        path: '/admin/results',
        label: 'Todays Results',
        icon: <DonutSmallIcon />,
        role: 2
    },
    {
        path: '/admin/transferexam',
        label: 'Transfer Exam',
        icon: <Category />,
        role: 2
    },
    {
        path: '/admin/profile',
        label: 'Profile',
        icon: <PersonIcon />,
        role: 2
    },
];

const ClientSidebar = ({ mobileOpen, handleDrawerToggle, drawerWidth }: any) => {
    const role = useSelector((state: any) => state.auth.user.role);
    const [sideList, setSideList] = useState<any>([])
    const history = useHistory();

    const location = useLocation();

    useEffect(() => {
        console.log(process.env.REACT_APP_MANAGE_INSTITUTE);

        setSideList(sideBarList.filter((s) => {
            if (s.path === '/admin/institutes') {
                if (process.env.REACT_APP_MANAGE_INSTITUTE === '0') {
                    return false
                }
            }
            if (s.path === '/admin/transferexam') {
                if (process.env.REACT_APP_TRANSFER_EXAMS === '0') {
                    return false;
                }
            }
            return s.role === role
        }))
    }, [role])

    const container = window !== undefined ? () => window.document.body : undefined;

    const gotoPage = (goto: string) => {
        history.push(goto)
    }


    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {sideList.map((list: any, index: number) => (
                    <ListItem
                        selected={location.pathname === list.path}
                        button
                        key={list.path}
                        onClick={() => gotoPage(list.path)}
                    >
                        <ListItemIcon>
                            {list.icon}
                        </ListItemIcon>
                        <ListItemText primary={list.label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

export default ClientSidebar;
