import React, { useEffect, useState } from 'react';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import UserRules from './userRules';

interface UserDialog {
    editObj: any,
    mode: string,
    closeModal: Function,
    editUser: Function,
    changePassword: Function,
}

interface IUser {
    firstname: string;
    lastname: string;
    user: string;
    email: string;
    phone: string;
    status: string;
}

const initialState: IUser = {
    firstname: '',
    lastname: '',
    user: '',
    email: '',
    phone: '',
    status: '',
}

const EditUser = (props: UserDialog) => {
    const [user, setUser] = useState(initialState);

    const [errors, setErrors] = useState(initialState);

    useEffect(() => {
        if (props.mode === 'edit') {
            setUser(props.editObj)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inputHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: ''
        })
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const confirmAction = async () => {
        let errorMsgs: any = ValidateForm(user, UserRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await setUser({
                ...user,
                user: props.editObj._id
            })            
            await props.editUser(user);
            props.closeModal();
        }
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, UserRules);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg
        });
    }

    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area">
                <div style={{ maxHeight: "70vh" }} className="form-area my-lg auto-scroll">
                    <div className="form-group mb-sm">
                        <label className={'bold text-primary'}>First Name</label>

                        <input
                            type="text"
                            name={'firstname'}
                            onBlur={inputValidation}
                            onChange={inputHandler}
                            value={user.firstname}
                            placeholder={'Enter Firstname'}
                        />

                        {errors.firstname !== '' ? (
                            <span className="error-text">{errors.firstname}</span>
                        ) : (
                                ''
                            )}
                    </div>
                    <div className="form-group mb-sm">
                        <label className={'bold text-primary'}>Last name</label>

                        <input
                            type="text"
                            name={'lastname'}
                            onBlur={inputValidation}
                            onChange={inputHandler}
                            value={user.lastname}
                            placeholder={'Enter Lastname'}
                        />

                        {errors.lastname !== '' ? (
                            <span className="error-text">{errors.lastname}</span>
                        ) : (
                                ''
                            )}
                    </div>
                    <div className="form-group mb-sm">
                        <label className={'bold text-primary'}>Email</label>

                        <input
                            type="email"
                            name={'email'}
                            onBlur={inputValidation}
                            onChange={inputHandler}
                            value={user.email}
                            placeholder={'Enter email'}
                            disabled={false}
                        />

                        {errors.email !== '' ? (
                            <span className="error-text">{errors.email}</span>
                        ) : (
                                ''
                            )}
                    </div>
                    <div className="form-group mb-sm">
                        <span className="link" onClick={() => props.changePassword(props.editObj)}>Change Password</span>
                    </div>

                    <div className="form-group mb-lg">
                        <label className={'bold text-primary'}>PhoneNo</label>

                        <input
                            type="number"
                            name={'phone'}
                            onBlur={inputValidation}
                            onChange={inputHandler}
                            value={user.phone}
                            placeholder={'Enter Phone no'}
                        />

                        {errors.phone !== '' ? (
                            <span className="error-text">{errors.phone}</span>
                        ) : (
                                ''
                            )}
                    </div>

                    <div className="form-group mb-lg">
                        <label className={'bold text-primary'}>Status</label>

                        <select
                            name={"status"}
                            value={user.status}
                            onBlur={inputValidation}
                            onChange={inputHandler}>
                            <option value='1'>Active</option>
                            <option value='0'>Disabled</option>
                        </select>

                        {errors.status !== '' ? (
                            <span className="error-text">{errors.status}</span>
                        ) : (
                                ''
                            )}
                    </div>
                </div>

            </div>
            <div className="button-area flex justify-end mt-lg">
                <button className="btn primary" onClick={confirmAction}>Confirm</button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default EditUser
