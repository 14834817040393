interface IExamPublishRule {
    closeModal: Function,
}

const ExamPublishRule = (props: IExamPublishRule) => {

    const rules = [
        "Total of 40 questions must be Added",
        "20 to Reading and 20 to Listening"
    ]
    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex column">
                <div>Here are some rules that should be fulfilled to publish the exam.</div>
                <ul>
                    {rules.map(rule =>
                        <li>{rule}</li>
                    )}
                </ul>
            </div>
            <div className="button-area flex justify-end mtyar-lg">
                <button className="btn primary" onClick={() => props.closeModal()}>Okay</button>
            </div>
        </div>
    )
}

export default ExamPublishRule
