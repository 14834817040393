import { useEffect, useState } from 'react';
// import {useDispatch} from 'react-redux';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import * as actions from '../../../../../store/action';
import ProfileDefaultImg from '../../../../../assets/default.png';
import profileRules from './profileRules';
import { useDispatch } from 'react-redux';

interface IUser {
    id: number,
    username: string,
    firstName: string,
    lastName: string,
    userRole: string,
    jobTitle: string,
    image: string,
    image_file: any
}

interface UserProfileDialog {
    user: IUser,
    closeModal: Function,
}

const initialState: IUser = {
    id: 0,
    username: '',
    firstName: '',
    lastName: '',
    userRole: '',
    jobTitle: '',
    image: '',
    image_file: null
}

const ProfilePictureForm = (props: UserProfileDialog) => {
    const [localUser, setLocalUser] = useState(initialState);
    const { user } = props;
    const [errors, setErrors] = useState(initialState);
    const dispatch = useDispatch();

    useEffect(() => {
        setLocalUser({
            ...localUser,
            ...user,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {


        if (localUser.image_file && !errors.image_file) {
            getBase64(localUser.image_file, (imageString: string) => {
                setLocalUser({
                    ...localUser,
                    image: imageString
                })
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localUser.image_file])

    const getBase64 = (file: any, cb: Function) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            setErrors({
                ...errors,
                image_file: 'Cannot load image'
            });
        };
    }

    const confirmAction = () => {
        let errorMsgs: any = ValidateForm(localUser, profileRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            dispatch(actions.changeUserPicture(localUser.image_file));
            props.closeModal();
        }
    }

    const inputHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: ''
        })

        setLocalUser({
            ...localUser,
            [e.target.name]: e.target.name === "image_file" ? e.target.files[0] : e.target.value
        });
    }

    const inputValidation = (e: any) => {
        let errorMsg = e.target.name === 'image_file' ?
            ValidateInput(e.target.name, e.target.files[0], profileRules) :
            ValidateInput(e.target.name, e.target.value, profileRules);

        setErrors({
            ...errors,
            [e.target.name]: errorMsg
        });
    }

    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area">
                <div className="form-group my-md">
                    <label>Profile Image(max size = 700KB)</label>
                    <input
                        type="file"
                        name={'image_file'}
                        onBlur={inputValidation}
                        onChange={inputHandler}
                        accept={'image/x-png,image/jpg,image/jpeg'}
                    />
                    {errors.image_file !== '' ?
                        <span className="error-text">{errors.image_file}</span> : ''}
                    <div>
                        <img height={100} src={localUser.image ? localUser.image?.startsWith('uploads') ? `${process.env.REACT_APP_API_BASE_URL}${localUser.image}` : localUser.image : ProfileDefaultImg} alt="Profile Img" />
                    </div>
                </div>
            </div>
            <div className="button-area flex justify-end mt-lg">
                <button className="btn primary mr-xs" onClick={confirmAction}>Confirm</button>
                <button type="reset" className="btn primary outlined" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default ProfilePictureForm;