import { Grid, Typography } from '@mui/material';
import ImageHelper from '../../modules/common/component/ImageHelper';
import optionType from '../../modules/common/enum/optionType';
import StudentAudioplayer from '../../modules/student/views/Exam/components/StudentAudioplayer';
import './radio.css'
// interface IRadioButton {
//     name: string,
//     text: string,
//     value: string,
//     checked: boolean,
//     onChange: Function,
//     onImageClick: Function,
//     onBlur: Function,
//     butttonNumber: string,
//     optionType: string,
// }
const RadioButton = (props: any) => {
    return (
        <label className={`radio-container`}>
            {(props.optionType === optionType.IMAGE) &&
                <ImageHelper
                    onClick={props.onImageClick}
                    defaultListening={props.optionType?.includes(optionType.LISTENING)}
                    style={{ maxWidth: '80%', maxHeight: '12rem', objectFit: 'contain' }}
                    className=""
                    image={props.text || ''}
                />
            }
            <Grid container
                justifyContent="center"
                alignItems="center" >

                {(props.question?.option_type === optionType.LISTENING
                    || props.question?.option_type === optionType.LISTENING_AND_TEXT
                    || props.question?.option_type === optionType.SAME_LISTENING) ?
                    <>
                        <Grid item xs={3}>
                            <StudentAudioplayer
                                listeningStatus={props.listeningStatus}
                                audioSrc={props.audioSrc}
                                listeningStatusAddOne={props.listeningStatusAddOne}
                                id={`l${props.paramName}`} />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography style={{ wordBreak: 'keep-all' }} variant="subtitle2">{(props.optionType === optionType.TEXT || props.optionType === optionType.LISTENING_AND_TEXT) &&
                                props.text}
                            </Typography>
                        </Grid>
                    </> : <Grid item xs={12}>
                        <Typography style={{ wordBreak: 'keep-all' }} variant="subtitle1">{(props.optionType === optionType.TEXT || props.optionType === optionType.LISTENING_AND_TEXT) &&
                            props.text}
                        </Typography>
                    </Grid>
                }




            </Grid>

            <input type="radio"
                checked={props.checked}
                value={props.value}
                name={props.name}
                onBlur={(e) => props.onBlur(e)}
                onChange={(e) => props.onChange(e)} />
            <span className="checkmark">{props.butttonNumber}</span>
        </label>
    )
};

export default RadioButton