import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ValidateForm,
  ValidateInput,
} from '../../../../../utils/customHooks/validateForm';
import * as action from '../store/QuestionsActions';
import QuestionRulesReading from './questionRulesReading';
import QuestionRuleListening from './questionRuleListening';
import { Question } from '../../../../common/model/Question';
import questionType from '../../../../common/enum/questiontype';
import optionType from '../../../../common/enum/optionType';
import PreviewQuestionAdd from './PreviewQuestionAdd';
import AddQuestionForm from './AddQuestionForm';
import { Button, DialogActions, DialogContent, Divider, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface IAddQuestion {
  closeModal: Function;
  mode: string;
  examId: string;
  questionType: string;
  questionNumberList: number[],
}

const initialState: Question = {
  question_number: 0,
  main_heading: '',
  main_heading_image: false,
  heading: '',
  description: '',
  description_image: false,
  description_boxed: false,
  option1: '',
  option2: '',
  option3: '',
  option4: '',
  listening: '',
  answer: '',
  answer_details: '',
  type: '',
};

const AddQuestion = (props: IAddQuestion) => {
  const [errors, setErrors] = useState(initialState);

  const uploadFile = useSelector((state: any) => {
    return state.questions.uploadFile;
  });

  // const [image, setImage] = useState(imageState);
  const [question, setQuestion] = useState(initialState);
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false)
  const [acceptedQnoList, setAcceptedQnoList] = useState<number[]>([])
  const loading = useSelector((state: any) => state.questions.loading)
  const success = useSelector((state: any) => state.questions.success)
  // const loading = useSelector((state: any) => state.questions.loading)

  useEffect(() => {
    let acceptedList = []
    if (props.questionNumberList && questionType) {
      if (props.questionType === questionType.READING) {
        for (let start = 1; start <= 20; start++) {
          if (!props.questionNumberList.includes(start)) {
            acceptedList.push(start)
          }
        }
      } else {
        for (let start = 21; start <= 40; start++) {
          if (!props.questionNumberList.includes(start)) {
            acceptedList.push(start)
          }
        }
      }
    }
    setAcceptedQnoList(acceptedList)
  }, [props.questionNumberList, props.questionType])

  useEffect(() => {
    if (Object.entries(uploadFile).length !== 0) {
      setQuestion(
        {
          ...question,
          [uploadFile.field_name]: uploadFile.url
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile])

  useEffect(() => {
    console.log(question);

  }, [question])


  useEffect(() => {
    setQuestion({
      ...question,
      type: props.questionType,
      option_type: optionType.TEXT,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionType]);

  const inputHandler = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });

    if (e.target.name === "option_type" && question.option_type === optionType.LISTENING_AND_TEXT && e.target.value === optionType.LISTENING) {
      setQuestion({
        ...question,
        [e.target.name]: e.target.value,
        option1: question.option1_listening,
        option2: question.option2_listening,
        option3: question.option3_listening,
        option4: question.option4_listening,
      });
    } else {
      setQuestion({
        ...question,
        [e.target.name]: e.target.value,
      });
    }
  };
  const inputFileHandler = (e: any) => {
    console.log(e.target.name);

    if (e.target.name.endsWith('listening')) {
      const names=e.target.name.split('_listening')
      console.log(names);
      const name=names[0]
      setErrors({
        ...errors,
        [name]: '',
      });
    }else{
      setErrors({
        ...errors,
        [e.target.name]: '',
      });
    }

    let name = e.target.name
    if (question.option_type===optionType.LISTENING) {
      const names = e.target.name.split('_listening')
      console.log(names);
      name = names[0]
    }
    
    const payload = {
      file: e.target.files[0],
      name: name
    }
    console.log(payload);

    


    dispatch(action.uploadFile(props.examId, payload))

    // setImage({
    //   ...image,
    //   [e.target.name]: e.target.files[0],
    // });
    // if (question.option_type !== optionType.LISTENING_AND_TEXT) {
    //   setQuestion({
    //     ...question,
    //     [e.target.name]: null,
    //   });
    // }
  };

  const confirmAction = async () => {
    console.log({ question });

    let errorMsgs: any = ValidateForm(question, question.type === questionType.READING ? QuestionRulesReading : QuestionRuleListening);
    setErrors({ ...errorMsgs });
    if (question.option_type === optionType.LISTENING_AND_TEXT) {
      let obj = {}
      if (!question.option1 || !question.option1_listening) {
        obj = { ...obj, option1: "Must have both listening  and text" }
      }
      if (!question.option2 || !question.option2_listening) {
        obj = { ...obj, option2: "Must have both listening  and text" }
      }
      if (!question.option3 || !question.option3_listening) {
        obj = { ...obj, option3: "Must have both listening  and text" }
      }
      if (!question.option4 || !question.option4_listening) {
        obj = { ...obj, option4: "Must have both listening  and text" }
      }
      errorMsgs = { ...errorMsgs, ...obj }
      setErrors({ ...errorMsgs });
    }
    let validated = Object.values(errorMsgs).join('').length === 0;

    if (validated) {
      await dispatch(action.addQuestion(props.examId, question));
      setSubmit(true)
      // props.closeModal();
    }
  };

  useEffect(() => {
    if (success && submit) {
      props.closeModal();
      dispatch(action.getActionsCompleted(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, submit])

  const inputValidation = (e: any) => {
    let errorMsg = ValidateInput(e.target.name,
      e.target.name === "listening" ? e.target.files[0] : e.target.value,
      question.type === questionType.READING ? QuestionRulesReading : QuestionRuleListening);
    setErrors({
      ...errors,
      [e.target.name]: errorMsg,
    });
  };

  const toggleImage = (e: any) => {
    setQuestion({
      ...question,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <DialogContent dividers>
        <Stack direction={"row"} spacing={2}>
          <AddQuestionForm
            question={question}
            errors={errors}
            inputValidation={inputValidation}
            inputHandler={inputHandler}
            toggleImage={toggleImage}
            inputImageHandler={inputFileHandler}
            acceptedQnoList={acceptedQnoList}
            questionNumberList={props.questionNumberList}
          />
          <Divider orientation="vertical" flexItem />
          <PreviewQuestionAdd
            question={question} />
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => props.closeModal()}>
          Cancel
        </Button>
        <LoadingButton
          size='large'
          type='submit'
          variant='contained'
          loading={loading}
          onClick={confirmAction}
        >
          Create
        </LoadingButton>
      </DialogActions>

    </>
  );
};

export default AddQuestion;
