import { Redirect } from 'react-router-dom'

const StudentHome = () => {
    return (
        <div className='row wrap'>
            <div className="card-admin">
                <div className="card-title">Exam List</div>
                <div className="card-body">2</div>
            </div>
            <div className="card-admin">
                <div className="card-title">Results</div>
                <div className="card-body">10</div>
            </div>
            <Redirect to="/student/exams" />
        </div>
    );
};
export default StudentHome;
