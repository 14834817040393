import ExamsList from '../views/ExamsList';
import Exam from '../views/Exam';
import UserProfile from '../../common/views/UserProfile';
import StudentHome from '../views/StudentHome';
import ResultList from '../views/ResultList';
import ExamStart from '../views/ExamStart';
import PreviewResult from '../../common/views/PreviewResult';
import Contact from '../views/Contact';
// eslint-disable-next-line
export default [
  {
    path: '/profile',
    name: 'user.profile',
    component: UserProfile,
    exact: true,
  },
  {
    path: '/contact',
    name: 'user.contact',
    component: Contact,
    exact: true,
  },

  {
    path: '',
    name: 'user',
    component: StudentHome,
    exact: true,
  },

  {
    path: '/exams',
    name: 'user.exams',
    component: ExamsList,
    exact: true,
  },
  {
    path: '/results',
    name: 'user.results',
    component: ResultList,
    exact: true,
  },
  {
    path: '/results/:resultId',
    name: 'admin.exams.results.result',
    exact: true,
    component: PreviewResult,
  },

  {
    path: '/exams/exam/:examId',
    name: 'user.exams.exam',
    component: Exam,
    exact: true,
  },

  {
    path: '/exams/start/:examId',
    name: 'user.exam.start',
    component: ExamStart,
    exact: true,
  },
];
