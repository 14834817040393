import { Avatar, Button, Card, CardContent, Stack, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import img from "../../../../assets/default.png"
import { useParams, useHistory } from 'react-router';
import { GetRequest } from '../../../../plugins/axios';
import Users from './components/Users';
import Exams from './components/Exams';
import Results from './components/Results';
import UserAccess from './components/UserAccess';

const tabList = [
    {
        name: "Result List",
        value: 'resultlist',
        role: 1
    },
    {
        name: 'Users',
        value: 'users',
        role: 1
    },
    {
        name: "Exam Access",
        value: 'examaccess',
        role: 1
    },
    {
        name: "Exam List",
        value: 'examlist',
        role: 1
    },
    {
        name: "Results",
        value: 'results',
        role: 0
    },
    {
        name: "User Access",
        value: 'useraccess',
        role: 0
    }
]

export default function UserDetail() {
    const { userId } = useParams<{ userId: string }>();

    const [filterTabs, setFilterTabs] = useState<any>([]);

    const [value, setValue] = React.useState('');
    const [userDetail, setUserDetail] = useState<any>({})

    const history = useHistory()


    useEffect(() => {
        if (filterTabs.length > 0) {
            setValue(filterTabs[0].value)
        }
    }, [filterTabs])

    useEffect(() => {
        if (userDetail && userDetail.role >= 0) {
            setFilterTabs(tabList.filter((tab: any) => tab.role === userDetail.role))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetail?.role])


    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
    };

    const goBack = () => {
        history.goBack()
    }

    useEffect(() => {
        const getUserDetails = () => {
            const url = process.env.REACT_APP_API_BASE_URL + 'users'
            GetRequest(`${url}/getUserInfo/${userId}`, {}, {}).then(res => {
                setUserDetail(res.data)
            }).catch(err => {
                console.log(err);

            })
        }
        getUserDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <Box>
            <Stack spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Button variant="outlined" onClick={goBack} startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Stack>
                <Card variant="outlined">
                    <CardContent>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar
                                alt=""
                                src={userDetail.image ? `${process.env.REACT_APP_API_BASE_URL}${userDetail.image}` : img}
                                sx={{ width: 56, height: 56 }}
                            />
                            <Stack direction={'column'} spacing={1}>
                                <div className="title-sm bold text-primary">{userDetail?.name}</div>
                                <div>{userDetail?.role === 1 ? "Institute" : "Student"}</div>
                                <div>{userDetail?.user_uid || 'Unique id of user not found'}</div>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <Tabs value={value} onChange={handleChange}>
                    {filterTabs.map((tab: any) => <Tab label={tab.name} value={tab.value} />)}
                </Tabs>
                {value === "users" && <Users userId={userId} />}
                {value === "examlist" && <Exams userId={userId} />}
                {value === "results" && <Results userId={userId} />}
                {value === "resultlist" && <Results userId={userId} />}
                {value === "useraccess" && <UserAccess userId={userId} />}
            </Stack>
        </Box>
    )
}
