import {
  DeleteRequest,
  GetRequest,
  PostRequest,
  PutRequest,
} from '../../../../../plugins/axios';
import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GET_EXAMS_LIST } from './ExamsTypes';

const examUrl = process.env.REACT_APP_API_BASE_URL + 'exams';

export const getExamListSuccess = (payload: any) => {
  return {
    type: GET_EXAMS_LIST,
    payload: payload,
  };
};

export const getExams = () => (dispatch: any) => {
  const user = store.getState().auth.user;
  if (user._id) {
    let url = `${examUrl}/admin/${user._id}`;
    if (user.role === 1) {
      url = `${examUrl}/institute/${user._id}`;
    }
    GetRequest(url, {}, {})
      .then((res) => {
        dispatch(getExamListSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};

export const addExam =
  (payload: any = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    PostRequest(`${examUrl}/create/${userID}`, payload, {})
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Successfully added Exam',
          })
        );
        dispatch(getExams());
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Error Adding',
            message: 'Error adding exam',
          })
        );
      });
  };

export const publishExam = (examId: string) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  PutRequest(`${examUrl}/publish/${examId}/${userID}`, {}, {})
    .then((res) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'success',
          name: 'Success',
          message: 'Successfully Updated Exam',
        })
      );
      dispatch(getExams());
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Error Adding',
          message: 'Error adding exam',
        })
      );
    });
};
export const editExam =
  (examId: string, payload: any = {}) =>
  (dispatch: any) => {
    const userID = store.getState().auth.user._id;
    PutRequest(`${examUrl}/${examId}/${userID}`, payload, {})
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Successfully Updated Exam',
          })
        );
        dispatch(getExams());
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Error Adding',
            message: 'Error adding exam',
          })
        );
      });
  };

export const deleteExam = (examId: string) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  DeleteRequest(`${examUrl}/${examId}/${userID}`, {})
    .then((res) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'success',
          name: 'Success',
          message: 'Successfully Deleted Exam',
        })
      );
      dispatch(getExams());
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Error Adding',
          message: 'Error adding exam',
        })
      );
    });
};
