import { CircularProgress, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RadioButton from '../../../../../common/Radio'
import optionType from '../../../enum/optionType'
import AudioPlayer from './AudioPlayer'

export default function OptionPreview({ question, butttonNumber, paramName }: any) {
    const [radioText, setRadioText] = useState('');
    const [audioSrc, setAudioSrc] = useState('')
    const [loading, setLoading] = useState(false);
    const uploadFileLoading = useSelector((state: any) =>
        state.questions.uploadFileLoading
    );

    useEffect(() => {
        if (paramName) {
            setLoading(uploadFileLoading[paramName])
        }
        console.log(uploadFileLoading);
        
    }, [uploadFileLoading, paramName]);

    useEffect(() => {
        if (question.option_type === optionType.LISTENING) {
            setRadioText('')
            setAudioSrc(`${process.env.REACT_APP_API_BASE_URL}${question[`${paramName}`]}`)
        } else if (question.option_type === optionType.TEXT) {
            setRadioText(question[paramName])
        } else if (question.option_type === optionType.LISTENING_AND_TEXT) {
            // const data = JSON.parse(question[paramName]);
            setRadioText(question[paramName])
            setAudioSrc(`${process.env.REACT_APP_API_BASE_URL}${question[`${paramName}_listening`]}`)
        } else {
            setRadioText(question[paramName])
        }
        // console.log(paramName, question, localImage);
        // console.log(question[paramName]);

        if (question.option_type === optionType.LISTENING) {
            if (question[`${paramName}_listening`]?.startsWith('uploads')) {
                setAudioSrc(`${process.env.REACT_APP_API_BASE_URL}${question[`${paramName}_listening`]}`)
            }
        }

        // eslint-disable-next-line
    }, [question])

    return (
        <Stack direction={'row'} spacing={2}>
            {loading ?
                <CircularProgress />
                : <>
                    <RadioButton
                        onImageClick={() => { }}
                        optionType={question.option_type || ''}
                        checked={question.answer === butttonNumber}
                        butttonNumber={butttonNumber}
                        name={'option'}
                        onChange={() => { }}
                        onBlur={() => { }}
                        text={radioText}
                        value={'1'} />
                    <AudioPlayer
                        question={question}
                        localImage={audioSrc}
                    /></>
            }


        </Stack>
    )
}
