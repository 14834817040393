import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import { useSelector } from 'react-redux';




const MyCredentialInfo = () => {
    const user = useSelector((state: any) => state.auth.user);
    const accessToken = localStorage.getItem("token");

    return (
        <List>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="UserId" secondary={user._id} />
            </ListItem>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary="Token"
                    secondary={accessToken}
                />
            </ListItem>
        </List>
    )
}

export default MyCredentialInfo;