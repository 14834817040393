import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GetRequest } from '../../../../../plugins/axios';

const resultUrl = process.env.REACT_APP_API_BASE_URL + 'results';

export const getResultsSuccess = (payload: any) => {
  return {
    type: 'GET_RESULTS',
    payload: payload,
  };
};

export const getUserResults = () => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    GetRequest(`${resultUrl}/user/${userID}`, {}, {})
      .then((res) => {
        dispatch(getResultsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};