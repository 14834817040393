import { GetRequest } from '../../../../../plugins/axios';
import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import {
  GET_RESULTS_ERROR,
  GET_RESULTS_LIST,
  GET_RESULTS_LOADING,
} from './ResultsTypes';

const resultUrl = process.env.REACT_APP_API_BASE_URL + 'results';

export const getResultSuccess = (payload: any) => {
  return {
    type: GET_RESULTS_LIST,
    payload: payload,
  };
};

export const getLoading = (payload: Boolean) => {
  return {
    type: GET_RESULTS_LOADING,
    payload: payload,
  };
};

export const getError = (payload: string) => {
  return {
    type: GET_RESULTS_ERROR,
    payload: payload,
  };
};

export const getResultsFromExam = (examId: string) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    GetRequest(`${resultUrl}/exam/${examId}/${userID}`, {}, {})
      .then((res) => {
        console.log(res);
        
        dispatch(getResultSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Results',
          })
        );
      });
  }
};
