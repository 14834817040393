import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import ListTable from '../../../../../common/Table';
import { GetRequest } from '../../../../../plugins/axios';
import { setToasterState } from '../../../../../store/action';
import { Button, Card, CardActions, CardContent, Stack } from '@mui/material';
import moment from 'moment';
import Modal from '../../../../../common/modal/index';
import AddUserAccess from './AddUserAccess';
import { addOrUpdateUserAccess } from '../store/UserDetailActions';
import { ExamAccessType } from '../../../../../common/enum/ExamAccessType.enum';
import { getLabelFromAccesstype } from './data';

const UserAccess = ({ userId }: any) => {
    const dispatch = useDispatch();
    const [userAccess, setUserAccess] = useState<any>({});
    const [modalMode, setModalMode] = useState('');
    const [open, setOpen] = useState(false)
    const user = useSelector((state: any) => {
        return state.auth.user;
    });
    const isSuccess = useSelector((state: any) => {
        return state.loading.isSuccess;
    });
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        user._id && userId && loadUserAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, user]);

    const loadUserAccess = async () => {
        const url = process.env.REACT_APP_API_BASE_URL + 'examaccess'
        setLoading(true)
        GetRequest(`${url}/${user._id}`, { id: userId }, {}).then(res => {
            setUserAccess(res.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            dispatch(
                setToasterState({
                    appear: true,
                    title: 'error',
                    name: 'Load Error',
                    message: err.response?.data?.error || 'Error loading UserAccess',
                })
            );
        })
    }
    const editUserAccess = () => {
        setModalMode('editUserAccess')
        setOpen(true)
    }
    const addUserAccess = () => {
        setModalMode('editUserAccess')
        setOpen(true)
    }
    const confirmAddUserAccess = (data: any) => {
        data.user = userId
        console.log(data);
        dispatch(addOrUpdateUserAccess(data));
        setSubmit(true)
    }

    useEffect(() => {
        if (submit && isSuccess) {
            loadUserAccess()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit, isSuccess])



    const columns = [
        {
            name: 'Exam',
            align: 'left',
            label: 'Exam',
            field: 'exam.title',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }
        , {
            name: 'Total Times',
            align: 'center',
            label: 'Total Times',
            field: 'total_times',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
        , {
            name: 'Completed Times',
            align: 'center',
            label: 'Completed Times',
            field: 'completed_times',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
    ]

    return (
        <>
            <Box>
                <Stack>
                    {userAccess.type && <Card variant="outlined">
                        <CardContent>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Stack direction={'column'} spacing={1}>
                                    <div className="title-sm bold text-primary">{getLabelFromAccesstype(userAccess.type)}</div>
                                    {userAccess.type === ExamAccessType.TIME_FRAME && userAccess.startTime &&
                                        <Stack>
                                            <div>Access Period</div>
                                            <div>{moment(new Date(userAccess.startTime)).format('YYYY-MM-DD')} TO {moment(new Date(userAccess.endTime)).format('YYYY-MM-DD')}</div>
                                        </Stack>

                                    }
                                    {userAccess.type === ExamAccessType.ALL_X_TIMES && userAccess.total_times &&
                                        <Stack>
                                            <div>Total Times Vs Completed</div>
                                            <div>{userAccess.completed_times}/{userAccess.total_times}</div>
                                        </Stack>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button onClick={editUserAccess}>Edit</Button>
                            <Button onClick={editUserAccess}>Delete</Button>
                        </CardActions>
                    </Card>}
                    {!loading && !userAccess.type &&
                        < Button variant="contained" onClick={addUserAccess}>Add User Access</Button>
                    }
                </Stack>
                {userAccess.type === ExamAccessType.SELECTED_EXAM && userAccess.exams && <div className="table-area pt-md flex-1">
                    <ListTable
                        columns={columns}
                        rows={userAccess.exams}
                        paginate={20}
                    />
                </div>
                }
            </Box>
            {
                modalMode === 'editUserAccess' &&
                <Modal show={open} title={'Edit User Access'}
                    closeModal={() => setOpen(false)} content={true}>
                    <AddUserAccess
                        closeModal={() => setOpen(false)}
                        confirmAction={confirmAddUserAccess}
                        userAccess={userAccess}
                        user={user}
                    />
                </Modal>
            }
            {
                modalMode === 'addUserAccess' &&
                <Modal show={open} title={'Add User Access'}
                    closeModal={() => setOpen(false)} content={true}>
                    <AddUserAccess
                        closeModal={() => setOpen(false)}
                        confirmAction={confirmAddUserAccess}
                        user={user} />
                </Modal>
            }
            {
                modalMode === 'editSelectedExams' &&
                <Modal show={open} title={'editSelectedExams'}
                    closeModal={() => setOpen(false)} content={true}>
                    <div></div>
                </Modal>
            }

        </>
    )
}

export default UserAccess;
