import React from 'react';
import AdminRoute from "./router";
import { useLocation } from "react-router";
import NavClient from '../common/containers/Nav';
import ClientSidebar from '../common/containers/Sidebar';
import '../../assets/scss/style.scss'
import '../../assets/scss/layout.scss'
import '../../assets/scss/vars.scss'
import { Box } from '@mui/system';
import { Toolbar } from '@mui/material';
import { drawerWidth } from '../../utils/customHooks/constant';


const AdminLayout: React.FC = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const location = useLocation();

    const noSideBar = () => {
        let loc = location.pathname.split('/').filter(v => v !== "");
        const isUserHome = loc.length === 1 && loc[0] === 'User';
        const isMenu = loc[2] === 'menu';
        return isUserHome || isMenu;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <NavClient drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} noSideBar={noSideBar} />
            {!noSideBar() && <ClientSidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <AdminRoute />
            </Box>
        </Box>

    );
};

export default AdminLayout;
