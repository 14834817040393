import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import ListTable from '../../../../../common/Table';
import { GetRequest } from '../../../../../plugins/axios';
import { setToasterState } from '../../../../../store/action';
import moment from 'moment';
import { Stack, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';

const Results = ({ userId }: any) => {

    const history = useHistory()

    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))

    const user = useSelector((state: any) => {
        return state.auth.user;
    });

    useEffect(() => {
        userId ? loadResults() : date && loadTodaysResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, date]);

    const loadResults = async () => {
        const url = process.env.REACT_APP_API_BASE_URL + 'reports'
        GetRequest(`${url}/${userId}`, {}, {}).then(res => {
            setResults(res.data)
        }).catch(err => {
            dispatch(
                setToasterState({
                    appear: true,
                    title: 'error',
                    name: 'Load Error',
                    message: err.response?.data?.error || 'Error loading Results',
                })
            );
        })
    }

    const loadTodaysResults = async () => {
        const url = process.env.REACT_APP_API_BASE_URL + 'reports';
        let toDate = new Date(date);
        toDate.setDate(toDate.getDate() + 1)
        const data = {
            date,
            to: moment(toDate).format('YYYY-MM-DD')
        }
        GetRequest(`${url}/date/${user._id}`, data, {}).then((res: any) => {
            setResults(res.data)

        }).catch(err => {
            dispatch(
                setToasterState({
                    appear: true,
                    title: 'error',
                    name: 'Load Error',
                    message: err.response?.data?.error || 'Error loading Results',
                })
            );

        })
    }

    // const viewDetailsResult = async (res: any) => {
    //     history.push(`results/${res._id}`)
    // }
    // const goBack = async () => {
    //     history.goBack();
    // }

    const columns = [
        {
            name: 'title',
            align: 'left',
            label: 'Title',
            field: 'exam.title',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }
        , {
            name: 'User Name',
            align: 'center',
            label: 'UserName',
            field: ['user.firstname', 'user.lastname'],
            flexVal: 1,
            multi: true,
            boxed: true
        }
        , {
            name: 'Score',
            align: 'center',
            label: 'Score',
            field: 'result.score',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
        , {
            name: 'Status',
            align: 'center',
            label: 'Status',
            field: 'status',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
        , {
            name: 'Exam Date',
            align: 'center',
            label: 'Date',
            field: 'createdAt',
            flexVal: 1,
            sortable: true,
            date: true,
            boxed: true
        }
    ]
    const viewActions = (res: any) => {
        console.log(res);
        if (res?.result?._id) {
            history.push(`results/${res.result._id}`)
        } else {
            dispatch(
                setToasterState({
                    appear: true,
                    title: 'error',
                    name: 'No Result Found',
                    message: 'User haven\'t completed exam',
                })
            );
        }
    }

    return (
        <Box>
            {!userId &&
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <div className="title-sm bold text-primary">Today's Results</div>
                    <TextField
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        id="outlined-name"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        label="Date"
                    />
                </Stack>
            }

            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={results}
                    paginate={50}
                    viewAction={viewActions}
                />
            </div>
        </Box>
    )
}

export default Results;
