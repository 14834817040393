import React from 'react'
import { Button, Grid, Stack } from '@mui/material'
import QuestionNumberLists from './QuestionNumberList'

const DisplayAllMode = ({ setListeningStatus, findPositionFromQuestionId, questionsList, submitExamDialog }: any) => {
    return (
        <Stack spacing={1} alignItems={'flex-end'}>
            <Grid container spacing={2} >
                {/* //question list */}
                <Grid item xs={12}>
                    <QuestionNumberLists
                        setListeningStatus={setListeningStatus}
                        changeMode={findPositionFromQuestionId}
                    />
                </Grid>
            </Grid>
            <Button variant="contained" onClick={submitExamDialog}>Submit And Finish Exam</Button>
        </Stack>
    )
}


export default DisplayAllMode

