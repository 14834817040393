import React, { useEffect, useState } from 'react';
import ListTable from '../../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../../common/modal';
import Confirmation from '../../../../../common/modal/Confirmation';
import EditQuestion from './EditQuestion';
import AddQuestion from './AddQuestion';
import { useHistory } from "react-router-dom";
import { Exam } from '../../../../common/model/Exam'
import { useParams } from "react-router";
import { Question } from '../../../../common/model/Question';
import ExamPublishRule from '../../../../common/component/ExamPublishRule';
import QuestionSummary from '../../../../common/component/QuestionSummary';
import * as actions from '../store/QuestionsActions'
import * as examActions from '../../examlist/store/ExamsActions'
import { setToasterState } from '../../../../../store/action';

import StyledMenu from '../../../component/StyledMeny';
import { IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';

interface IQuestions {
    goBack: string
}

const QuestionList = (props: IQuestions) => {
    const questionList = useSelector((state: any) => {
        return state.questions.questions;
    });
    const examList: Exam[] = useSelector((state: any) => {
        return state.exams.exams;
    });

    const history = useHistory();
    const { examId } = useParams<{ examId: string }>();
    const user = useSelector((state: any) => state.auth.user);

    const dispatch = useDispatch();
    const [questionType, setQuestionType] = useState("Reading")
    const [questions, setQuestions] = useState<Question[]>([]);
    const [questionNumberList, setQuestionNumberList] = useState<[]>([])
    const [showModal, setShowModal] = useState(false);
    const [editUserObj, setEditUserObj] = useState<any>({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');
    const [currentExam, setCurrentExam] = useState<Exam>({})

    useEffect(() => {
        dispatch(actions.getQuestionsAdminSuccess([]));
        user._id && loadQuestions();
        // user._id && loadListening();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (examId) {
            let exam: any = examList.find((exam) => exam._id === examId)
            if (exam) {
                setCurrentExam(exam)
            } else {
                history.push(props.goBack)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examList])

    useEffect(() => {
        const qList = questionList.filter((q: Question) => q.type === questionType)
        setQuestions(qList);
        const qnoList = questionList.map(((q: Question) => q.question_number))
        setQuestionNumberList(qnoList)
    }, [questionList, questionType])

    const loadQuestions = async () => {
        await dispatch(actions.getQuestionsFromExam(examId));
    }

    const deleteAction = (obj: any) => {
        setShowConfirmation(true);
        setEditUserObj(obj)
    }

    const deleteConfirmation = async () => {
        console.log(editUserObj);

        await dispatch(actions.deleteQuestion(editUserObj._id, editUserObj.exam));
        setShowConfirmation(false);
    }

    const addAction = () => {
        if (questions.length < 20) {
            setModalMode('add');
            setShowModal(true);
        } else {
            dispatch(
                setToasterState({
                    appear: true,
                    title: 'error',
                    name: 'Change Question Type',
                    message: 'Switch to Reading or Listening Mode',
                })
            );
        }
    }

    const viewQuestionSummary = () => {
        setModalMode('QuestionSummary');
        setShowModal(true);
        setAnchorEl(null)
    }

    const editQuestion = async (payload: Question) => {        
        await dispatch(actions.editQuestion(payload._id, payload));
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditUserObj(obj);
        setShowModal(true);
    }
    const viewResult = () => {
        history.push(`${history.location.pathname}/results`)
    }
    const publishExam = async () => {
        await dispatch(examActions.publishExam(examId));
    }

    const changeQuestionType = (value: string) => {
        setQuestionType(value);
    }
    const showRuleToPublish = () => {
        setModalMode('publishRule');
        setShowModal(true);
    }

    const showWhoCanSee = () => {
        history.push(`${examId}/institutes`)
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        {
            name: 'question_number',
            align: 'center',
            label: 'Q.NO',
            field: 'question_number',
            flexVal: 1,
            sortable: true,
            boxed: true
        },
        {
            name: 'Main Heading',
            align: 'left',
            label: 'Main Heading',
            field: 'main_heading',
            flexVal: 1,
            sortable: true,
            splice: 5,
        },
        {
            name: 'Question Heading',
            align: 'left',
            label: 'Question Heading',
            field: 'heading',
            flexVal: 1,
            sortable: true,
            splice: 5,
        },
        {
            name: 'Description',
            align: 'left',
            label: 'Description',
            field: 'description',
            flexVal: 1,
            sortable: true,
            splice: 5,
        },
        {
            name: 'Options',
            align: 'left',
            label: 'Options',
            field: ['option1', 'option2', 'option3', 'option4'],
            optionsArray: true,
            flexVal: 1,
            sortable: true,
            splice: 5,
        },
        {
            name: 'Answer',
            align: 'center',
            label: 'Answer',
            field: 'answer',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }
    ]

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <header className={'flex justify-between items-center mb-md'}>
                <span className="title-sm bold text-primary">List of Question</span>
                <div>
                    {currentExam.status === 1 ? <span className="mr-sm">Published</span> :
                        questionList.length === 40 ?
                            <button className="btn primary mr-sm" onClick={publishExam}>Ready To Publish</button> :
                            <span className="mr-sm">
                                <span>
                                    Not Ready To Publish
                                </span>
                                <span className={`text-primary underline pointer ml-xs`} onClick={showRuleToPublish}>Why?</span>
                            </span>}
                    <button className="btn primary mr-sm" onClick={addAction}>Add Questions</button>
                    <IconButton aria-label="delete" size="small" onClick={handleClick}>
                        <MoreVertIcon fontSize="inherit" />
                    </IconButton>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={viewResult}>
                            <ListItemIcon >
                                <SendIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="View Results" />
                        </MenuItem>
                        {
                            user.role === 2 && (
                                currentExam.type === 'private' ? <MenuItem onClick={showWhoCanSee}>
                                    <ListItemIcon>
                                        <VisibilityIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Who can see this exam?" />
                                </MenuItem> :
                                    <MenuItem>
                                        <ListItemIcon>
                                            <VisibilityIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Everybody have access to this exam" />
                                    </MenuItem>
                            )
                        }

                        <MenuItem onClick={viewQuestionSummary}>
                            <ListItemIcon>
                                <DonutSmallIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Question Summary" />
                        </MenuItem>
                    </StyledMenu>

                </div>
            </header>
            <div className="justify-between flex">
                <div className="flex togle-nav pointer">
                    <div onClick={() => changeQuestionType("Reading")} className={`${questionType === "Reading" ? 'active' : ''}`}>Reading</div>
                    <div onClick={() => changeQuestionType("Listening")} className={`${questionType === "Listening" ? 'active' : ''}`}>Listening</div>
                </div>
            </div>

            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={questions}
                    editAction={editAction}
                    deleteAction={deleteAction}
                    paginate={20}
                />
            </div>
            {
                modalMode === 'add' &&
                <Modal show={showModal} content={true} title={'Add Question'}
                    closeModal={() => setShowModal(false)}>
                    <AddQuestion
                        examId={examId}
                        closeModal={() => setShowModal(false)}
                        mode={modalMode}
                        questionType={questionType}
                        questionNumberList={questionNumberList} />
                </Modal>}
            {
                modalMode === 'edit' &&
                <Modal show={showModal}
                    content={true}
                    title={'Edit Question'}
                    closeModal={() => setShowModal(false)}>
                    <EditQuestion
                        editQuestion={editQuestion}
                        editObj={editUserObj}
                        closeModal={() => setShowModal(false)}
                        mode={modalMode}
                        questionNumberList={questionNumberList}
                    />
                </Modal>
            }
            {
                modalMode === 'publishRule' &&
                <Modal show={showModal} title={'Rules to Publish Exam'}
                    closeModal={() => setShowModal(false)}>
                    <ExamPublishRule
                        closeModal={() => setShowModal(false)} />
                </Modal>
            }
            {
                modalMode === 'QuestionSummary' &&
                <Modal show={showModal} title={'Question Number Summary'}
                    closeModal={() => setShowModal(false)}>
                    <QuestionSummary
                        questions={questionList}
                        closeModal={() => setShowModal(false)} />
                </Modal>
            }
            <Confirmation title="Confirm" content="Are you sure you want to delete this item?" show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </section>
    )
}

export default QuestionList;
