import { Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Typography } from '@mui/material';
import img from '../../assets/images/exam.png'
const ExamCard = (props: any) => {
    return (
        <Card>
            <CardMedia
                component="img"
                height="80"
                image={img}
                alt="green iguana"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {props.exam.title}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item>
                        <Chip size='small' variant='outlined' label={props.exam.is_cbt ? 'CBT' : 'UBT'} />
                    </Grid>
                    <Grid item spacing={1}>
                        <Chip size='small' variant='outlined' label={props.exam.type} />
                    </Grid>
                    <Grid item spacing={1}>
                        <Chip size='small' variant='outlined' label={props.exam.status === 1 ? 'Available' : 'Not Available'} color={props.exam.status === 1 ? 'success' : 'error'} />
                    </Grid>
                    {props.exam.is_cbt && props.exam.category &&
                        <Grid item spacing={1}>
                            <Chip size='small' variant='outlined' label={props.exam.category.title} color={'success'} />
                        </Grid>}

                </Grid>
            </CardContent>
            <CardActions>
                <Grid container spacing={1}>
                    <Grid item>
                        {props.transferAction && <Button size="small" variant='outlined' onClick={() => props.transferAction(props.exam)}>Transfer Exam</Button>}
                    </Grid>
                    <Grid item>
                        {props.startAction && <Button size="small" variant='contained' onClick={() => props.startAction(props.exam)}>Start Exam</Button>}
                    </Grid>
                    <Grid item>
                        {props.viewAction && <Button size="small" variant='outlined' onClick={() => props.viewAction(props.exam)}>View</Button>}
                    </Grid>
                    <Grid item>
                        {props.editAction && <Button size="small" variant='outlined' onClick={() => props.editAction(props.exam)}>Edit</Button>}
                    </Grid>
                    <Grid item>
                        {props.deleteAction && <Button size="small" variant='outlined' color='error' onClick={() => props.deleteAction(props.exam)}>Delete</Button>}
                    </Grid>
                    

                </Grid>
            </CardActions>
        </Card>
    );
};

export default ExamCard;
