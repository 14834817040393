export const isJsonString = (str: string) => {
  try {
    const parsed = JSON.parse(str);
    return parsed;
  } catch (e) {
    return false;
  }
};

export const DEFAULT_TIMES_LISTENING = process.env.REACT_APP_DEFAULT_LISTENING_TIMES || 1;
