// eslint-disable-next-line
export default {
  email: {
    required: true,
    isEmail: true,
    label: 'Email',
  },
  userName: {
    required: true,
    minValue: 6,
    label: 'User Name',
  },
  password: {
    required: true,
    minValue: 8,
    label: 'Password',
  },
  conformPassword: {
    required: true,
    minValue: 8,
    label: 'Confirm password',
  },
  phone: {
    required: true,
    minValue: 10,
    maxValue: 10,
    label: 'Phone no',
  },
};
