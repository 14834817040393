import React, { Dispatch } from 'react';
import './toaster.scss'
import { useDispatch, useSelector } from "react-redux";

import { ToasterPropsInterface, ToasterStateInterface } from "./interface/toasterInterface";
import { RootState } from "../../store/interface/storeInterface";

import * as actions from './services/toasterAction'
import { Alert, Snackbar, Typography } from '@mui/material';

const Toaster: React.FC<ToasterPropsInterface> = () => {
    const dispatch = useDispatch<Dispatch<any>>()

    const {
        title,
        name,
        message,
        appear
    } = useSelector((state: RootState) => state.toaster)
    const showTime = 5000;
    const handleClose = () => {
        const toasterData: ToasterStateInterface = {
            appear: false,
            title: '',
            name: '',
            message: '',
        }
        dispatch(actions.setToasterState(toasterData));
    };


    return (
        <Snackbar open={appear} autoHideDuration={showTime} onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <Alert onClose={handleClose} severity={title}>
                <Typography variant='h6' color={title}>
                    {name}
                </Typography>
                <div> {message}</div>
            </Alert>
        </Snackbar>

    );
};

export default Toaster;
