import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteProps, Route, Redirect } from "react-router";
import { ToasterStateInterface } from "../../../interfaces";
import * as actions from '../../../common/toaster/services/toasterAction';
import { UserType } from "../../../common/enum/UserType.enum";

interface ClientRouteProps extends RouteProps {
    component: React.FunctionComponent<RouteProps>;
    name?: string
}

const AdminRoutes: React.FC<ClientRouteProps> = (props) => {
    const { component: Component, ...rest } = props;
    const dispatch = useDispatch<Dispatch<any>>()

    const user: string = localStorage.getItem('user') || '';
    const role: number = JSON.parse(user).role;

    const validity = role === UserType.ADMIN

    const openToaster = () => {
        const toasterState: ToasterStateInterface = {
            appear: true,
            title: "error",
            name: "Authentication Error",
            message: "You have to authenticate before proceedings "
        }
        dispatch(actions.setToasterState(toasterState))
    }
    useEffect(() => {
        !validity && openToaster();
        // eslint-disable-next-line
    }, [validity])

    return (
        <Route
            {...rest}
            render={props =>
                validity ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={'/login'}
                        />
                    )
            }
        />
    );
};

export default AdminRoutes;