import React from 'react';
import UserRoute from "./routes";
import { useLocation } from "react-router";
import NavClient from '../common/containers/Nav';
import ClientSidebar from '../common/containers/Sidebar';
import { Box } from '@mui/system';
import { drawerWidth } from '../../utils/customHooks/constant';
import { Toolbar } from '@mui/material';
import { useSelector } from 'react-redux';
import ExamNav from '../common/containers/Nav/ExamNav';

const UserLayout: React.FC = () => {
    const location = useLocation();
    const screenLandscape = useSelector((state: any) => {
        return state.userReducer.screen_landscape;
    });

    const noSideBar = () => {
        let loc = location.pathname.split('/').filter(v => v !== "");
        const isUserHome = loc.length === 1 && loc[0] === 'User';
        const isMenu = loc[2] === 'menu';
        const isExam = loc[0] === 'student' && loc[1] === 'exams' && (loc[2] === 'exam' || loc[2] === 'start');
        return isUserHome || isMenu || isExam;
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            {screenLandscape ?
                <main className="admin full-vh full-width" id="container">
                    <section className="container-dashboard full-height full-width flex column">
                        <ExamNav />
                        <div className="main-content flex-1 flex full-width">
                            {!noSideBar() ? <ClientSidebar /> : ''}
                            <div className="content-area flex-1 overflow-y-auto">
                                <UserRoute />
                            </div>
                        </div>
                    </section>
                </main>
                :
                <Box component="main" sx={{
                    display: 'flex',
                }}>
                    <NavClient drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} noSideBar={noSideBar} />
                    {!noSideBar() && <ClientSidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}

                    {/* <NavClient drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} noSideBar={noSideBar} />
                {!noSideBar() && <ClientSidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />} */}
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${drawerWidth}px)` }, height: '100%' }}
                    >
                        <Toolbar />
                        <UserRoute />
                    </Box>
                </Box>}

        </>
    )
};

export default UserLayout;
