import React, { useEffect, useState } from 'react';
import moment from "moment";
import listeningImg from '../../assets/svgs/listening.svg';
import { isJsonString } from '../../utils/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { filter } from 'lodash';


import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead, TableSortLabel, Stack, IconButton
} from '@mui/material';
import SearchNotFound from './SearchNotFound';
import UserListToolbar from './UserListToolbar';


const ListTable = (props: any) => {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [order] = useState('asc');

    useEffect(() => {
        setColumns(props.columns);
        setRows(props.rows);
        setRowsPerPage(parseInt(props.paginate, 10));
    }, [props]);




    const sliced = (st: any, val: any, key: string) => {
        const keyValues = key.split('.');
        try {
            if (keyValues && keyValues.length === 2) {
                if (st) {
                    return val[keyValues[0]][keyValues[1]].slice(0, st)
                } else {
                    return val[keyValues[0]][keyValues[1]]
                }
            }
            if (val[key].endsWith('.png')
                || val[key].endsWith('.jpg')
                || val[key].endsWith('.jpeg')) {
                return loadImage(val[key])
            } else {
                if (st) {
                    return val[key].slice(0, st)
                } else {
                    return val[key]
                }
            }

        } catch (error) {
            return val[key]
        }
    }
    const loadImage = (val: string) => {
        return <img src={val ? `${process.env.REACT_APP_API_BASE_URL}${val}` : listeningImg} height="100%" width="100%" alt='' />
    }
    const multi = (st: any, val: any, key: []) => {

        try {
            let str = "";
            key.forEach((k: any) => {
                const keyValues = k.split('.');
                if (keyValues && keyValues.length === 2) {
                    if (st) {
                        str += val[keyValues[0]][keyValues[1]].slice(0, st) + " "
                    } else {
                        str += val[keyValues[0]][keyValues[1]] + " "
                    }
                } else {
                    str += val[k] + " "
                }
            })
            return str
        } catch (error) {
            return ''
        }
    }

    const arrayValue = (st: any, val: any, key: string) => {
        const keyValues = key.split('.');
        try {
            if (keyValues && keyValues.length === 2) {
                if (st) {
                    return val[keyValues[0]][0][keyValues[1]].slice(0, st)
                } else {
                    return val[keyValues[0]][0][keyValues[1]]
                }
            }
            return ''
        } catch (error) {
            return ''
        }
    }
    const optionValue = (st: any, val: any, key: any) => {
        try {
            if (key && key.length === 4) {
                return <ol>{
                    key.map((element: any, i: number) =>
                        <li key={i} className="list-decoration" >
                            {
                                (val[element].toLowerCase().endsWith('.png') || val[element].toLowerCase().endsWith('.jpg') || val[element].toLowerCase().endsWith('.jpeg')) ?
                                    loadImage(val[element])
                                    : val[element].endsWith('.mp3') ?
                                        loadImage("")
                                        : val[element].startsWith("{") ?
                                            isJsonString(val[element])?.text :
                                            val[element]}</li>
                    )}</ol>
            }
            return ''
        } catch (error) {
            return ''
        }
    }

    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterName, setFilterName] = useState('');

    const handleChangePage = (event: any, newPage: any) => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageNumber(0);
    };


    const emptyRows =
        pageNumber > 0 ? Math.max(0, (1 + pageNumber) * rowsPerPage - rows.length) : 0;


    const hasActions = () => {
        return props.downloadAction ||
            props.addAction ||
            props.editAction ||
            props.viewAction ||
            props.deleteAction;
    }

    const handleFilterByName = (event: any) => {
        setFilterName(event.target.value);
    };

    const filteredRows = applySortFilter(
        rows?.length > 0 ? rows : [],
        getComparator(order, columns[0]),
        filterName
    );


    const hasColums = filteredRows?.length === 0;

    function getComparator(order: any, orderBy: any) {
        return order === 'desc'
            ? (a: any, b: any) => descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a: any, b: any, orderBy: any) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function applySortFilter(array: any, comparator: any, query: any) {
        const stabilizedThis = array.map((el: any, index: any) => [el, index]);
        stabilizedThis.sort((a: any, b: any) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(
                array,
                (_user: any) =>
                    _user[props.search]?.toLowerCase().indexOf(query.toLowerCase()) !== -1
            );
        }
        return stabilizedThis.map((el: any) => el[0]);
    }

    return (
        <Card>
            {
                props.search &&
                <UserListToolbar
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                />
            }


            <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((header: any, key: any) => (
                                <TableCell
                                    key={key}
                                >
                                    <TableSortLabel
                                        hideSortIcon
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            {
                                hasActions() &&
                                <TableCell
                                >
                                    <TableSortLabel
                                        hideSortIcon
                                    >
                                        Actions
                                    </TableSortLabel>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            filteredRows && filteredRows
                                .slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
                                .map((row: any, key: any) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={key}
                                            role='checkbox'
                                            tabIndex={-1}
                                        >
                                            {columns.map((columnHeader: any, key: any) => (
                                                <TableCell padding='checkbox'>
                                                    <span
                                                        className={`${columnHeader.boxed ? row[columnHeader.field] : ''} pa-xs`}>
                                                        {columnHeader.date ?
                                                            moment(new Date(row[columnHeader.field])).format('YYYY-MM-DD') :
                                                            columnHeader.array ?
                                                                arrayValue(columnHeader.sliced, row, columnHeader.field) :
                                                                columnHeader.optionsArray ? optionValue(columnHeader.sliced, row, columnHeader.field) :
                                                                    columnHeader.multi ? multi(columnHeader.sliced, row, columnHeader.field)
                                                                        : sliced(columnHeader.sliced, row, columnHeader.field)}
                                                    </span>
                                                </TableCell>
                                            ))}
                                            {
                                                hasActions() &&
                                                (
                                                    <TableCell padding='checkbox'>
                                                        <Stack direction={'row'} spacing={1}>
                                                            {
                                                                props.viewAction && <IconButton aria-label="view" color="info" onClick={() => props.viewAction(row)}>
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            }
                                                            {
                                                                props.addAction && <IconButton aria-label="add" color="error" onClick={() => props.addAction(row)}>
                                                                    <AddIcon />
                                                                </IconButton>
                                                            }
                                                            {
                                                                props.editAction && <IconButton aria-label="edit" color="info" onClick={() => props.editAction(row)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            }
                                                            {
                                                                props.deleteAction && <IconButton aria-label="delete" color="error" onClick={() => props.deleteAction(row)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }
                                                            


                                                        </Stack>
                                                    </TableCell>


                                                )
                                            }

                                        </TableRow>
                                    )
                                })
                        }

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    {hasColums && (
                        <TableBody>
                            <TableRow>
                                <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                                    <SearchNotFound searchQuery={filterName} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}

                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component='div'
                count={rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>

    )
}

export default ListTable;
