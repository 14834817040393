import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import moment from 'moment'

const ResultList = () => {
    const resultsList = useSelector((state: any) => {
        return state.userReducer.results;
    });
    const user = useSelector((state: any) => state.auth.user);
    const dispatch = useDispatch();
    const [results, setResults] = useState([])

    useEffect(() => {
        user._id && loadResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setResults(resultsList?.results);
    }, [resultsList])

    const loadResults = async () => {
        await dispatch(actions.getUserResults());
    }

    // const viewResult = async (obj: Result) => {
    //     history.push(`results/view/${obj._id}`)
    // }

    const columns = [
        {
            name: 'title',
            align: 'left',
            label: 'Title',
            field: 'exam.title',
            flexVal: 2,
            sortable: true,
            splice: 5,
        }, {
            name: 'Score',
            align: 'center',
            label: 'Score',
            field: 'score',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
        , {
            name: 'Exam Date',
            align: 'center',
            label: 'Date',
            field: 'createdAt',
            flexVal: 1,
            sortable: true,
            date: true,
            boxed: true
        }
    ]

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">List of Results</div>
                <div>Showing Results From
                    <span className='ma-sm bg-primary text-white pa-sm br-sm'>
                        {moment(new Date(resultsList.startDate)).format('YYYY-MM-DD')}
                    </span>
                    To
                    <span className='ma-sm bg-primary text-white pa-sm br-sm'>
                        {moment(new Date(resultsList.endDate)).format('YYYY-MM-DD')}
                    </span>
                </div>
            </header>
            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={results}
                    paginate={20}
                />
            </div>
        </section>
    )
}

export default ResultList;
