import { Answer } from '../../../../common/model/Answer';
import { Question } from '../../../../common/model/Question';
import ImageHelper from '../../../../common/component/ImageHelper';
import questionType from '../../../../common/enum/questiontype';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import QuestionOptionItem from './QuestionOptionItem';
import StudentAudioplayer from './StudentAudioplayer';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DashboardIcon from '@mui/icons-material/Dashboard';

interface IDisplayQuestion {
    question: Question,
    answer: Answer,
    changePosition: Function,
    listeningStatusAddOne: Function,
    answerChange: Function,
    switchToAllMode: Function,
    position: number
}

const DisplayQuestion = (props: IDisplayQuestion) => {

    const listeningStatusList = useSelector((state: any) => {
        return state.userReducer.listening_status;
    });
    const [listeningStatus, setListeningStatus] = useState('')

    const listeningPlayId = useSelector((state: any) =>
        state.userReducer.listeningPlayId
    );
    // const [audioPlaying, setAudioPlaying] = useState(false);

    useEffect(() => {
        if (props.question?.type === questionType.LISTENING) {
            const listeningStatus = listeningStatusList.find((status: any) => status.question === props.question._id)
            if (listeningStatus) {
                setListeningStatus(listeningStatus)
            } else {
                setListeningStatus('')
            }
        } else {
            setListeningStatus('')
        }
    }, [props.question, listeningStatusList])

    return (
        <Stack direction={'column'}>
            <Grid container spacing={1}>
                <Grid item xs={7}>
                    <Box sx={{ border: '1px solid grey', padding: 1, overflow: "auto", height: { xs: "55vw", md: "54vh" } }}>
                        <Stack direction="column">
                            <div className="my-xs">
                                {props.question?.main_heading_image ?
                                    <ImageHelper
                                        onClick={() => { }}
                                        defaultListening={false}
                                        style={{ maxWidth: '90%', maxHeight: '20rem', objectFit: 'contain' }}
                                        className=""
                                        image={props.question?.main_heading || ''}
                                    /> :
                                    <Typography style={{ wordBreak: 'keep-all' }} variant="h6">{props.question?.main_heading}</Typography>
                                }
                            </div>
                            <Typography variant="h6">
                                {`${props.position + 1}. `}  {props.question?.heading && props.question?.heading}
                            </Typography>
                            <div className="my-sm">
                                {props.question?.description_image ?
                                    <ImageHelper
                                        onClick={() => { }}
                                        defaultListening={false}
                                        style={{ maxWidth: '80%', maxHeight: '15rem', minWidth: '40%', minHeight: '12rem', objectFit: 'contain' }}
                                        className=""
                                        image={props.question?.description || ''}
                                    /> :
                                    <Box sx={{ padding: 2, border: props.question.description_boxed ? "1px solid grey" : "" }}>
                                        <Typography style={{ wordBreak: 'keep-all', whiteSpace: 'pre-line' }} variant="subtitle1">
                                            {props.question?.description}
                                        </Typography>
                                    </Box>
                                }
                            </div>
                            {
                                props.question?.type === questionType.LISTENING &&
                                <StudentAudioplayer
                                    listeningStatus={listeningStatus}
                                    listeningStatusAddOne={props.listeningStatusAddOne}
                                    audioSrc={`${process.env.REACT_APP_API_BASE_URL}${props.question?.listening}`}
                                    id={`listening`} />
                            }
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={5}>
                    <Stack direction="column">
                        <Box sx={{ border: '1px solid grey', padding: 1, overflow: "auto", height: { xs: "55vw", md: "54vh" } }}>
                            <Stack direction="column" spacing={1}>
                                <QuestionOptionItem
                                    listeningStatusAddOne={props.listeningStatusAddOne}
                                    butttonNumber={'1'}
                                    question={props.question}
                                    answer={props.answer}
                                    value={'1'}
                                    answerChange={props.answerChange}
                                    paramName={'option1'}
                                    listeningStatus={listeningStatus}
                                />
                                <QuestionOptionItem
                                    listeningStatusAddOne={props.listeningStatusAddOne}
                                    butttonNumber={'2'}
                                    question={props.question}
                                    answer={props.answer}
                                    value={'2'}
                                    answerChange={props.answerChange}
                                    paramName={'option2'}
                                    listeningStatus={listeningStatus}
                                />
                                <QuestionOptionItem
                                    listeningStatusAddOne={props.listeningStatusAddOne}
                                    butttonNumber={'3'}
                                    question={props.question}
                                    answer={props.answer}
                                    value={'3'}
                                    answerChange={props.answerChange}
                                    paramName={'option3'}
                                    listeningStatus={listeningStatus}
                                />
                                <QuestionOptionItem
                                    listeningStatusAddOne={props.listeningStatusAddOne}
                                    butttonNumber={'4'}
                                    question={props.question}
                                    answer={props.answer}
                                    value={'4'}
                                    answerChange={props.answerChange}
                                    paramName={'option4'}
                                    listeningStatus={listeningStatus}
                                />

                            </Stack>

                        </Box>
                    </Stack>

                </Grid>

            </Grid>
            <div className={`flex justify-between ma-xs`}>
                <Button variant="contained" disabled={listeningPlayId !== ""} startIcon={<NavigateBeforeIcon />} onClick={() => props.changePosition(-1)}>
                    Previous
                </Button>
                <Button variant="contained" disabled={listeningPlayId !== ""} startIcon={<DashboardIcon />} onClick={() => props.switchToAllMode()}>
                    Total Question
                </Button>
                <Button variant="contained" disabled={listeningPlayId !== ""} endIcon={<NavigateNextIcon />} onClick={() => props.changePosition(1)}>
                    Next
                </Button>
            </div>
        </Stack>



    )
}

export default DisplayQuestion
