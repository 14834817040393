import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExamCard from '../../../../common/ExamCard';
import Confirmation from '../../../../common/modal/Confirmation';
import { PostRequest } from '../../../../plugins/axios';
import { setToasterState } from '../../../../store/action';

const TransferExam = () => {
    const [exams, setExams] = useState([])
    const [examPayload, setExamPayload] = useState({})
    const [values, setValues] = useState({
        url: '',
        token: '',
        userId: ''
    })
    const { url, token, userId } = values;
    const [showConfirmation, setShowConfirmation] = useState(false)

    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.auth.user);


    const examUrl = process.env.REACT_APP_API_BASE_URL + 'exams';

    const getExams = () => {
        if (url && token && userId) {
            axios.get(`${url}api/exams/admin/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }).then(res => {
                setExams(res.data)
            }).catch(err => {
                dispatch(
                    setToasterState({
                        appear: true,
                        title: 'error',
                        name: 'Error Getting Exam',
                        message: 'Error Getting Exam',
                    })
                );
            })
        }

    }

    const confirmTransferExam = (exam: any) => {
        setExamPayload(exam)
        setShowConfirmation(true)
    }

    const transferExam = (exam: any) => {
        setShowConfirmation(false)

        if (user._id) {
            PostRequest(`${examUrl}/transferexam/${user._id}`, { exam: examPayload, url, token, userId }, {}).then(res => {
                dispatch(setToasterState({
                    appear: true,
                    title: 'success',
                    name: 'Success',
                    message: 'Successfully Transfered Exam',
                }))

            }).catch(err => {
                console.log(err);
                dispatch(
                    setToasterState({
                        appear: true,
                        title: 'error',
                        name: 'Error Transfering Exam',
                        message: 'Error Transfering Exam',
                    })
                );
            })
        }

    }

    const textChangeListener = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }


    return (
        <Box>
            <header className={'flex justify-between items-center mb-sm'}>
                <div className="my-md title-sm bold text-primary">Transfer Exam From Other</div>
            </header>
            <Stack direction={'column'} spacing={1} >

                <Stack direction={'row'} spacing={1}>
                    <TextField value={url} name='url' fullWidth label="Website Name" variant="outlined" onChange={textChangeListener} />
                    <TextField value={userId} name='userId' fullWidth label="UserId" variant="outlined" onChange={textChangeListener} />
                    <TextField value={token} name='token' fullWidth label="Token" variant="outlined" onChange={textChangeListener} />
                    <Button variant="contained" onClick={getExams}>Get Exams</Button>
                </Stack>
                <header className={'flex justify-between items-center mb-sm'}>
                    <div className="my-md title-sm bold text-primary">Exams</div>
                </header>
                <Stack direction={'column'} spacing={1} >
                    {exams.length > 0 ?
                        <Grid container spacing={1}>
                            {exams.map((exam: any, index: number) =>
                                <Grid item lg={3} md={4} sm={12}>
                                    <ExamCard
                                        key={exam._id}
                                        exam={exam}
                                        transferAction={confirmTransferExam}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        : <div>No Exams Found</div>}
                </Stack>
            </Stack>
            <Confirmation
                title='Transfer Exam'
                content='Are you sure you want to save this exam?'
                show={showConfirmation}
                confirm={transferExam}
                closeModal={() => setShowConfirmation(false)}
            />
        </Box>
    )
}

export default TransferExam;
