import * as actionTypes from './UsersTypes';
interface IUsers {
  users: [];
  self_users: [];
  loading: Boolean;
}
const initailState: IUsers = {
  users: [],
  self_users: [],
  loading: false,
};
// eslint-disable-next-line
export default (state: IUsers = initailState, actions: any) => {
  switch (actions?.type) {
    case actionTypes.GET_USERS_LIST:
      return {
        ...state,
        users: actions.payload,
      };
    case actionTypes.GET_SELF_USERS_LIST:
      return {
        ...state,
        self_users: actions.payload,
      };
    case actionTypes.GET_USERS_LOADING:
      return {
        ...state,
        loading: actions.payload,
      };
    default:
      return state;
  }
};
