import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './store/UsersActions';
import Modal from '../../../../common/modal';
import Confirmation from '../../../../common/modal/Confirmation';
import EditUser from './components/EditUser';
import AddUsers from './components/AddUser';
import ChangePasswordAdmin from '../../../common/views/UserProfile/components/ChangePasswordAdmin';
import { Box } from '@mui/system';
import { useHistory } from 'react-router';
import { Tab, Tabs } from '@mui/material';

const UserManagement = () => {
    const usersList = useSelector((state: any) => {
        return state.users.users;
    });
    const selfUsersList = useSelector((state: any) => {
        return state.users.self_users;
    });

    const user = useSelector((state: any) => {
        return state.auth.user;
    });
    const history = useHistory();

    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editUserObj, setEditUserObj] = useState({ _id: -1 });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showAddConfirmation, setShowAddConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');

    const [value, setValue] = React.useState('yours');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        user._id && loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (value === 'self_users') {
            user._id && loadSelfUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        setUsers(usersList);
    }, [usersList])

    const loadUsers = async () => {
        await dispatch(actions.getAllUsers());
    }
    const loadSelfUsers = async () => {
        await dispatch(actions.getAllSelfCreatedUsers());
    }

    const deleteAction = (obj: any) => {
        setShowConfirmation(true);
        setEditUserObj(obj)
    }

    const addUserInstituteAction = (obj: any) => {
        setShowAddConfirmation(true);
        setEditUserObj(obj)
    }

    const deleteConfirmation = async () => {
        await dispatch(actions.deleteUser(editUserObj._id));
        setShowConfirmation(false);
    }

    const addUserToInstitute = async () => {
        await dispatch(actions.addUserToInstitute(editUserObj._id));
        setShowAddConfirmation(false);
    }

    const addAction = () => {
        setModalMode('add');
        setShowModal(true);
    }

    const editUser = async (payload: any) => {
        await dispatch(actions.editUser(payload));
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditUserObj(obj);
        setShowModal(true);

    }
    const viewAction = (obj: any) => {
        history.push(`users/${obj._id}`)

    }

    const changePassword = async (payload: any) => {
        payload = {
            ...payload,
            user: editUserObj._id
        };
        await dispatch(actions.changeUserPassword(payload));
    }

    const changePasswordActions = (obj: any) => {
        setModalMode('changePassword');
        setEditUserObj(obj);
        setShowModal(true);
    }

    // useEffect(() => {
    //     const newUserList = usersList.filter((user: any) => (user?.email.includes(search.toLowerCase()) ||
    //         user?.firstname.toLowerCase().includes(search.toLowerCase()) ||
    //         user?.lastname.toLowerCase().includes(search.toLowerCase())))
    //     setUsers(newUserList)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [search])

    const columns = [
        {
            name: 'email',
            align: 'left',
            label: 'Email',
            field: 'email',
            flexVal: 2,
            sortable: true,
            splice: 5,
        }, {
            name: 'firstname',
            align: 'left',
            label: 'First Name',
            field: 'firstname',
            flexVal: 1,
            sortable: true,
            splice: 5,
        },
        {
            name: 'lastname',
            align: 'left',
            label: 'Last Name',
            field: 'lastname',
            flexVal: 1,
            sortable: true,
            splice: 5,
        },
        {
            name: 'status',
            align: 'center',
            label: 'Status',
            field: 'status',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
    ]

    return (
        <Box>
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">User Management</div>
                <button className="btn primary" onClick={addAction}>Add User</button>
            </header>

            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab value="yours" label="Yours Users" />
                <Tab value="self_users" label="Users With No Institute" />
            </Tabs>


            {value === "yours" &&
                <ListTable
                    columns={columns}
                    rows={users}
                    viewAction={viewAction}
                    editAction={editAction}
                    deleteAction={deleteAction}
                    paginate={10}
                    search={'firstname'}
                />
            }
            {value === "self_users" &&
                <ListTable
                    columns={columns}
                    rows={selfUsersList}
                    paginate={10}
                    addAction={addUserInstituteAction}
                    search={'firstname'}
                />
            }

            {
                modalMode === 'add' ?
                    <Modal show={showModal} title={'Add User'}
                        closeModal={() => setShowModal(false)}>
                        <AddUsers closeModal={() => setShowModal(false)} mode={modalMode} />
                    </Modal> :
                    <Modal show={showModal} title={'Edit User'}
                        closeModal={() => setShowModal(false)}>
                        <EditUser
                            changePassword={changePasswordActions}
                            editUser={editUser}
                            editObj={editUserObj}
                            closeModal={() => setShowModal(false)}
                            mode={modalMode}
                        />
                    </Modal>
            }
            {
                modalMode === 'changePassword' &&
                <Modal show={showModal} title={'Change Password'}
                    closeModal={() => setShowModal(false)}>
                    <ChangePasswordAdmin
                        closeModal={() => setShowModal(false)}
                        changePassword={changePassword}
                        user={editUserObj} />
                </Modal>
            }
            <Confirmation title="Delete User"
                content="Are you sure you want to delete this user?"
                show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />

            <Confirmation title="Add User To Institute"
                content="Are you sure you want to add this user?"
                show={showAddConfirmation}
                confirm={addUserToInstitute}
                closeModal={() => setShowAddConfirmation(false)} />
        </Box>
    )
}

export default UserManagement;
