import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GetRequest, PutRequest } from '../../../../../plugins/axios';

const examUrl = process.env.REACT_APP_API_BASE_URL + 'exams';
export const getExamAccessAdminSuccess = (payload: any) => {
  return {
    type: 'GET_ADMIN_EXAM_ACCESS',
    payload: payload,
  };
};

export const getAdminExamAccess = (examId: string) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    GetRequest(`${examUrl}/exam/institutes/${examId}/${userID}`, {}, {})
      .then((res) => {
        dispatch(getExamAccessAdminSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Results',
          })
        );
      });
  }
};
export const addAccessToInstitute = (examId: string, payload: any) => (
  dispatch: any
) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    PutRequest(
      `${examUrl}/exam/addInstituteAccess/${examId}/${userID}`,
      payload,
      {}
    )
      .then((res) => {
        dispatch(getAdminExamAccess(examId));
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Adding Success',
            message: 'Institute Given Access to Exam',
          })
        );
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Results',
          })
        );
      });
  }
};
export const removeInstituteFromAccess = (examId: string, payload: any) => (
  dispatch: any
) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    PutRequest(
      `${examUrl}/exam/removeInstituteAccess/${examId}/${userID}`,
      payload,
      {}
    )
      .then((res) => {
        dispatch(getAdminExamAccess(examId));
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Adding Success',
            message: 'Institute Given Access to Exam',
          })
        );
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Results',
          })
        );
      });
  }
};
