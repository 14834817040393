
import QuestionList from '../../../common/views/questions/views/QuestionList';

const Questions = () => {


    return (
        <QuestionList goBack={'/institute/exams'} />
    )
}
export default Questions;
