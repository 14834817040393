import React from 'react';
import { Redirect } from 'react-router-dom';

const InstituteHome = () => {
    return (
        <div className='flex column wrap'>
            <h1>Institute</h1>
            <div className="card-admin pa-md">
                <div className="card-title">Total User</div>
                <div className="card-body">2</div>
            </div>
            <div className="card-admin pa-md">
                <div className="card-title">Total Exam Added</div>
                <div className="card-body">2</div>
            </div>
            <Redirect to="institute/exams" />
        </div>
    );
};
export default InstituteHome;
