import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import { Exam } from '../../../model/Exam';
import { User } from '../../../model/User';
import { getAllCategories } from '../../CategoryList/store/CategoryActions';
import * as action from '../store/ExamsActions';
import ExamRules from './examRules'
interface IAddExam {
    closeModal: Function,
    mode: string
    user: User
}

const initialState: Exam = {
    title: '',
    type: '',
}

const AddExam = (props: IAddExam) => {
    const [exam, setExam] = useState(initialState)
    const [error, setError] = useState(initialState)
    const dispatch = useDispatch();
    const examsList = useSelector((state: any) => {
        return state.exams.exams;
    });

    const categoryList = useSelector((state: any) => {
        return state.category.categories;
    });

    useEffect(() => {
        dispatch(getAllCategories());
        dispatch(action.getExams());
        setExam({
            ...exam,
            type: 'private',
            is_cbt: false
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    const inputHandler = (e: any) => {
        setError({
            ...error,
            [e.target.name]: ''
        })

        if (e.target.name === "is_cbt") {
            setExam({
                ...exam,
                [e.target.name]: e.target.value === "cbt"
            });
        } else {
            setExam({
                ...exam,
                [e.target.name]: e.target.value
            });
        }

    }

    const confirmAction = async () => {
        let errorMsgs: any = ValidateForm(exam, ExamRules);
        if (exam.is_cbt && !exam.category) {
            setError({
                ...error,
                category: 'Category is required'
            });
        } else {
            setError({ ...errorMsgs });
        }
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(action.addExam(exam));
            props.closeModal();
        }
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, ExamRules);
        setError({
            ...error,
            [e.target.name]: errorMsg
        });
    }


    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex">
                <div className="form-area my-md full-width">
                    <div className="form-group my-md full-width">
                        <label>Title</label>
                        <input
                            name="title"
                            type="text"
                            onChange={inputHandler}
                            onBlur={inputValidation} />
                        {error.title !== '' ? <span className="error-text">{error.title}</span> : ''}
                    </div>
                    {props.user.role === 2 && <div className="form-group my-md full-width">
                        <label>Exam Type</label>
                        <select name="type" value={exam.type} onChange={inputHandler} onBlur={inputValidation}>
                            <option value="private">Private</option>
                            <option value="public">Public</option>
                            <option value="free_for_all">Free For All</option>
                        </select>
                        {error.type !== '' ? <span className="error-text">{error.type}</span> : ''}
                    </div>}

                    <div className="form-group my-md full-width">
                        <label>CBT OR UBT</label>
                        <select name="is_cbt" value={exam.is_cbt ? 'cbt' : 'ubt'} onChange={inputHandler} onBlur={inputValidation}>
                            <option value={'cbt'}>CBT</option>
                            <option value={'ubt'}>UBT</option>
                        </select>
                        {error.is_cbt ? <span className="error-text">{error.is_cbt}</span> : ''}
                    </div>
                    {exam.is_cbt && (
                        <div className="form-group my-md full-width">
                            <label>Reference Exam</label>
                            <select name="exam" value={exam.exam} onChange={inputHandler} onBlur={inputValidation}>
                                <option value={''}>{''}</option>
                                {examsList.map((exam: Exam) => (
                                    <option value={exam._id}>{exam.title}</option>
                                ))}
                            </select>
                            {error.exam !== '' ? <span className="error-text">{error.exam}</span> : ''}
                        </div>
                    )}

                    {exam.is_cbt && (
                        <div className="form-group my-md full-width">
                            <label>CBT Category*</label>
                            <select name="category" value={exam.category} onChange={inputHandler} onBlur={inputValidation}>
                                <option value={''}>{''}</option>
                                {categoryList.map((category: any) => (
                                    <option value={category._id}>{category.title}</option>
                                ))}
                            </select>
                            {error.category !== '' ? <span className="error-text">{error.category}</span> : ''}
                        </div>
                    )}



                </div>
            </div>
            <div className="button-area flex justify-end mtyar-lg">
                <button className="btn primary" onClick={confirmAction}>Add Exam</button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default AddExam
